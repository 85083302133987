import React, { FC } from 'react'
import { Box, Button, FormControl, FormLabel, Text } from '@chakra-ui/react'
// import { ComplexSlider, FormikTextareaControl, RadioBlock, RoundedBox } from '@deepwork/ui-components'
// import { IUser } from '@deepwork/interfaces'
import {
  ComplexSlider,
  FormikTextareaControl,
  RadioBlock,
  RoundedBox,
} from 'libs/ui-components/src'
import { IUser } from 'libs/interfaces/src'
import { Form, Formik, FormikValues } from 'formik'
import { useProjectFeedbackDispatch } from '../../ProjectFeedbackProvider'
import { PerformanceTypeEnum } from '../../enums'

interface PeopleToReview extends Pick<IUser, 'id' | 'username'> {
  extendForm?: boolean
}
interface Props {
  onBack: () => void
  onNext: () => void
  peopleToReview: PeopleToReview[]
  possiblePerformanceTypes?: {
    label: string
    description: string
    value: PerformanceTypeEnum
  }[]
}
export const FeedbackStep: FC<Props> = ({
  peopleToReview,
  onNext,
  onBack,
  possiblePerformanceTypes,
}) => {
  const { onUpdateFeedbackStep } = useProjectFeedbackDispatch()
  const initialValues = {
    experts: peopleToReview.map((item) => ({
      username: item.username,
      id: item.id,
      split: 50,
      didWell: '',
      needsImprovement: '',
      personalNote: '',
      performanceType: null,
      extendForm: item.extendForm,
    })),
  }

  const handleNext = () => {
    onNext()
  }

  const handleSubmit = (values: FormikValues) => {
    onUpdateFeedbackStep({ ...values })
    handleNext()
  }

  return (
    <Box>
      {peopleToReview.length ? (
        <Formik enableReinitialize onSubmit={handleSubmit} initialValues={initialValues}>
          {({ values, setFieldValue }) => (
            <Form id="feedback-form" noValidate>
              {peopleToReview.map((item, index) => (
                <Box key={index} __css={{ ':not(:last-child)': { mb: '67px' } }}>
                  <Box mb="25px">
                    <Text variant="h2" color="white" mb="14px">
                      Feedback {item.username}
                    </Text>
                    <Text color="gray.79">
                      This will be public and help other applicants understand why they have not
                      been selected.
                    </Text>
                  </Box>
                  {item.extendForm && (
                    <RoundedBox mb="25px">
                      <FormControl>
                        <FormLabel mb="37px">
                          <Text color="white" variant="h4">
                            How did you end up splitting the work with {item.username}?
                          </Text>
                          <Text mt="8px" color="gray.79">
                            This will be sent to the project owner to help resolve conflicts if
                            necessary.
                          </Text>
                        </FormLabel>
                        <ComplexSlider
                          leftSideLabel="You"
                          rightSideLabel={item.username}
                          valueLabel="%"
                          value={[100 - values.experts[index].split, values.experts[index].split]}
                          onChange={(value: number[]) => {
                            setFieldValue(`experts[${index}].split`, value[1])
                          }}
                        />
                      </FormControl>
                    </RoundedBox>
                  )}
                  <RoundedBox mb="25px">
                    <FormikTextareaControl
                      label={`What did ${item.username} do well?`}
                      helperText="This feedback will be public."
                      name={`experts[${index}].didWell`}
                    />
                  </RoundedBox>
                  <RoundedBox mb="25px">
                    <FormikTextareaControl
                      label={`What can ${item.username} improve upon?`}
                      helperText="This feedback will be public."
                      name={`experts[${index}].needsImprovement`}
                    />
                  </RoundedBox>
                  <RoundedBox mb="25px">
                    <FormikTextareaControl
                      label="Would you like to leave them a private note?"
                      name={`experts[${index}].personalNote`}
                    />
                  </RoundedBox>
                  {item.extendForm && possiblePerformanceTypes && (
                    <RoundedBox>
                      <Text variant="h4" color="white" mb="16px">
                        How would you describe {item.username}’s ability to work on their tasks?
                      </Text>
                      <Box display="flex" flexWrap="wrap" justifyContent="space-around">
                        {possiblePerformanceTypes.map((type, typeIndex: number) => (
                          <RadioBlock
                            key={typeIndex}
                            label={type.label}
                            description={type.description}
                            radioProps={{
                              isChecked: values.experts[index].performanceType === type.value,
                              value: type.value,
                              onChange: (event) =>
                                setFieldValue(
                                  `experts[${index}].performanceType`,
                                  +event.target.value,
                                ),
                            }}
                          />
                        ))}
                      </Box>
                    </RoundedBox>
                  )}
                </Box>
              ))}
              <Box mt="67px">
                <Button variant="link" mr="40px" onClick={onBack}>
                  Back
                </Button>
                <Button type="submit" form="feedback-form">
                  Next
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <Box>
          <Text>You worked alone</Text>
          <Box mt="67px">
            <Button variant="link" mr="40px" onClick={onBack}>
              Back
            </Button>
            <Button onClick={onNext} form="feedback-form">
              Next
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
