import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
// import { ProjectFeedback } from '@deepwork/interfaces'
import { ProjectFeedback } from 'libs/interfaces/src'
import { useToast } from '@chakra-ui/react'
import axios from '../api/setup'

export const useProjectFeedbackSubmitMutation = (
  projectId: string,
  type: 'contributor' | 'expert' | 'owner',
  options?: UseMutationOptions<void, string, ProjectFeedback>,
): UseMutationResult<void, string, ProjectFeedback> => {
  const toast = useToast()
  return useMutation(
    async (data) => {
      return axios.post(`/project/${projectId}/${type}/review`, data)
    },
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}
