import React, { FC } from 'react'
import { Box, BoxProps, Text } from '@chakra-ui/react'
import { Tag } from '../../data-display/tag'
import { AvatarList } from '../../data-display/avatar-list/avatar-list'
import { ProfileBox } from '../profile-box/profile-box'

export interface PrimaryCardProps {
  /**
   * Click handler for the primary card
   */
  onCardClick: () => void
  /**
   * Organization name
   */
  organizationName: string
  /**
   * Organization avatar
   */
  organizationImage?: string
  /**
   * Organization helper text and tooltip if existent
   */
  organizationHelperText?: {
    text: string
    tooltipText?: string
  }
  /**
   * Project title
   */
  projectName?: string
  /**
   * Project description
   */
  projectDescription?: string
  /**
   * Project date range
   */
  dateRange?: string
  /**
   * Project tag
   */
  tag?: string
  /**
   * Project status
   */
  status?: string
  /**
   * Project member avatars
   */
  users?: string[]
  boxProps?: BoxProps
}

export const PrimaryCard: FC<PrimaryCardProps> = ({
  onCardClick,
  organizationHelperText,
  organizationImage,
  organizationName,
  projectName,
  projectDescription,
  tag,
  status,
  dateRange,
  users,
  children,
  boxProps,
}) => (
  <Box
    bg="gray.22"
    borderRadius="12px"
    w={{ base: '100%', sm: '48.2%', md: '290px' }}
    onClick={onCardClick}
    cursor={'pointer'}
    {...boxProps}
  >
    <Box p="13px 25px 11px" borderBottom="1px solid" borderColor="gray.18">
      <ProfileBox
        color="gray.96"
        name={organizationName}
        avatarUrl={organizationImage ?? ''}
        jobTitle={organizationHelperText?.text}
        tooltipText={organizationHelperText?.tooltipText}
        size="sm"
      />
    </Box>
    <Box p="14px 25px 18px" display="flex" flexDirection="column" justifyContent="space-between">
      <Box mb="24px">
        {!!projectName && (
          <Box mb="10px">
            <Text color="white" variant="h3">
              {projectName}
            </Text>
          </Box>
        )}
        {!!projectDescription && (
          <Box mb="10px">
            <Text color="white" variant="paragraph">
              {projectDescription}
            </Text>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection={{ base: 'row', md: 'column' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
          justifyContent={{ base: 'space-between', md: 'center' }}
        >
          {!!dateRange && (
            <Box mb={{ base: 'unset', md: '10px' }} order={{ base: 2, md: 1 }}>
              <Text color="gray.79" variant="h6">
                {dateRange}
              </Text>
            </Box>
          )}
          {!!tag && (
            <Box d="flex" order={{ base: 1, md: 2 }}>
              <Tag value={tag} selected />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={{ base: 'row', md: 'column' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        justifyContent={{ base: 'space-between', md: 'center' }}
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
      >
        {!!status && (
          <Box mb={{ base: '24px', md: '5px' }} width={{ base: '100%', md: 'auto' }}>
            <Text color="fluorescent" variant="h8">
              {status}
            </Text>
          </Box>
        )}
        {!!children && <Box mb={{ base: 'unset', md: '24px' }}>{children}</Box>}
        {!!users?.length && <AvatarList size="sm" avatars={users} />}
      </Box>
    </Box>
  </Box>
)
