import React, { FC, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Form, Formik, FormikProps, FormikValues } from 'formik'
import * as Yup from 'yup'
// import { IProjectRole, IUser, OrganisationRolesDTO } from '@deepwork/interfaces'
// import {
//   Autocomplete,
//   ConfirmationAlert,
//   CreateTaskSet,
//   FormikInputControl,
//   ProfileSelection,
//   RoundedBox
// } from '@deepwork/ui-components'
import { IProjectRole, IUser, OrganisationRolesDTO } from 'libs/interfaces/src'
import {
  Autocomplete,
  ConfirmationAlert,
  CreateTaskSet,
  FormikInputControl,
  ProfileSelection,
  RoundedBox,
} from 'libs/ui-components/src'
import { useExpertsForOrgRole } from '../../../shared/queries/organisation'
import { v4 as uuidv4 } from 'uuid'

const INITIAL_PROJECT_ROLE_VALUES: IProjectRole = {
  uId: '',
  id: '',
  title: '',
  description: '',
  assignee: null,
  tasks: [],
  workBeginDate: '',
  workEndDate: '',
  payment: 0,
  mentoringBonus: 0,
  isProjectOwner: false,
}

const defaultValidationSchema = Yup.object({
  // workBeginDate: Yup.string().required('Work begin date is required'),
  // workEndDate: Yup.string().required('Work end date is required'),
  payment: Yup.number()
    .required('Payment is required')
    .test('payment is a number', 'Payment must be a number', (value = 0) =>
      new RegExp(/^\d+$/).test(String(value)),
    )
    .test('payment', 'Payment must be positive', (value = 0) => value >= 0),
  mentoringBonus: Yup.number().test(
    'mentoringBonus is a number',
    'Recruitment bonus must be a number',
    (value = 0) => new RegExp(/^\d+$/).test(String(value)),
  ),
})

/**
 * Is being used when a user wants to create a new project role.
 */
export interface AddEditRoleModalProps {
  isOpen: boolean
  roleNumber: number
  isReadOnly: boolean
  projectOwnerAssignee: Pick<IUser, 'id' | 'username' | 'avatarUrl'> | null
  onClose: () => void
  onSave: (role: IProjectRole) => void
  role?: IProjectRole
  presetRoles: OrganisationRolesDTO[]
}

export const ChooseRoleModal: FC<AddEditRoleModalProps> = ({
  roleNumber = 1,
  projectOwnerAssignee,
  isReadOnly,
  isOpen,
  onClose,
  onSave,
  role,
  presetRoles = [],
}) => {
  const formRef = useRef<FormikProps<IProjectRole>>(null)
  const [formValues, setFormValues] = React.useState<IProjectRole>(INITIAL_PROJECT_ROLE_VALUES)
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
  const [selectedPresetRole, setSelectedPresetRole] = React.useState<{
    value?: string
    label?: string
  }>()
  const mappedPresetRoles = presetRoles.map((r) => ({
    label: r.title,
    value: r.id,
  }))
  const validationSchema = !!projectOwnerAssignee
    ? defaultValidationSchema.concat(
        Yup.object({ assignee: Yup.mixed().required('Assignees is required') }),
      )
    : defaultValidationSchema

  const { data: availableUsers } = useExpertsForOrgRole(selectedPresetRole?.value ?? '')

  useEffect(() => {
    if (projectOwnerAssignee) {
      setFormValues((prevState) => ({
        ...prevState,
        assignee: projectOwnerAssignee,
      }))
    }
  }, [projectOwnerAssignee])

  const handleSelectPresetRole = (data: any) => {
    const roleId = data.value
    const selectedRole = presetRoles.find((r) => r.id === roleId)
    setSelectedPresetRole(data)
    setFormValues(
      selectedRole
        ? {
            ...INITIAL_PROJECT_ROLE_VALUES,
            ...selectedRole,
            assignee: projectOwnerAssignee,
          }
        : INITIAL_PROJECT_ROLE_VALUES,
    )
    formRef.current?.resetForm()
  }

  useEffect(() => {
    if (role && !formValues.id) {
      const selectedRole = presetRoles.find(
        (presetRole) => presetRole.id === role.id || presetRole.id === role.uId,
      )
      setSelectedPresetRole({
        value: selectedRole?.id,
        label: selectedRole?.title,
      })
      setFormValues({ ...selectedRole, ...role })
    }
  }, [formValues, presetRoles, role])

  const handleDiscard = () => {
    onCloseAlert()
    onClose()
  }

  const handleClose = () => {
    if (formRef.current?.dirty) {
      onOpenAlert()
      return
    }
    onClose()
  }

  const handleSubmit = (values: FormikValues) => {
    onSave({
      uId: role?.uId ?? uuidv4(),
      id: role?.id ?? selectedPresetRole?.value ?? '',
      title: values.title,
      description: values.description,
      assignee: values.assignee,
      tasks: values.tasks,
      workBeginDate: values.workBeginDate,
      workEndDate: values.workEndDate,
      payment: values.payment,
      mentoringBonus: values.mentoringBonus ?? 0,
      isProjectOwner: !!projectOwnerAssignee,
    })
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="4xl"
        closeOnOverlayClick={false}
        returnFocusOnClose={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{role ? 'Update role' : 'Add a role'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody borderRadius="12px" p="25px 25px 32px 25px">
            <FormControl mb="32px">
              <FormLabel>{`Role ${roleNumber}`}</FormLabel>
              <Autocomplete
                data-testid={'role-selection'}
                value={selectedPresetRole}
                onChange={handleSelectPresetRole}
                options={mappedPresetRoles}
                placeholder="Choose a role"
                isDisabled={!!role}
              />
            </FormControl>
            <Formik
              innerRef={formRef}
              enableReinitialize
              validateOnMount
              initialValues={formValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ setFieldValue, values, isValid, errors }) => ( // eslint-disable-line
                <Form noValidate autoComplete={'off'}>
                  <Box>
                    <FormikInputControl
                      label="Description"
                      name="description"
                      styleProps={{ mb: '32px' }}
                      placeholder="Taking user interviews and making presentations"
                      disabled={true}
                    />
                    <Box mb="32px">
                      <ProfileSelection
                        label="Assignee"
                        disabled={!!isReadOnly}
                        possibleProfiles={availableUsers ?? []}
                        selectedProfile={values.assignee}
                        onChange={(value) => setFieldValue('assignee', value)}
                      />
                    </Box>
                    <Box mb="32px">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        mb="32px"
                        flexDirection={{ base: 'column', md: 'row' }}
                      >
                        <FormikInputControl
                          label="When should the work begin?"
                          name="workBeginDate"
                          type="date"
                          styleProps={{
                            mr: { base: '0', md: '30px' },
                            mb: { base: '32px', md: 0 },
                          }}
                          placeholder="Aug 5, 2021"
                        />
                        <FormikInputControl
                          label="When should the work be completed?"
                          name="workEndDate"
                          type="date"
                          placeholder="Aug 13, 2021"
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={{ base: 'column', md: 'row' }}
                      >
                        <FormikInputControl
                          label="Payment"
                          name="payment"
                          placeholder="6000"
                          type="number"
                          styleProps={{
                            mr: { base: '0', md: '30px' },
                            mb: { base: '32px', md: 0 },
                          }}
                          InputRightAddon={<Text ml="16px">USD</Text>}
                        />
                        <FormikInputControl
                          label="Recruitment bonus"
                          name="mentoringBonus"
                          type="number"
                          tooltipLabel={
                            'Recruitment Bonus is associated with the Lead-Contributor collaboration. If the Lead mentors a contributor during the project span, they get an extra compensation for their efforts.'
                          }
                          placeholder="600"
                          InputRightAddon={<Text ml="16px">USD</Text>}
                        />
                      </Box>
                    </Box>
                    {formValues.tasks ? (
                      <CreateTaskSet
                        isReadOnly
                        tasks={values.tasks}
                        onChange={(tasks) => setFieldValue('tasks', tasks)}
                      />
                    ) : (
                      <RoundedBox
                        bg="gray.24"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p="32px 25px"
                      >
                        <Text variant="h6">Select a role to view tasks within it</Text>
                      </RoundedBox>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" mt="32px">
                    {isReadOnly ? (
                      <Button size="sm" onClick={handleClose}>
                        Close
                      </Button>
                    ) : (
                      <Button type="submit" size="sm" disabled={!formValues.tasks || !isValid}>
                        Save role
                      </Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ConfirmationAlert
        isOpen={isOpenAlert}
        onClose={onCloseAlert}
        onConfirm={handleDiscard}
        title={`Are you sure you want to close? You will lose all unsaved changes.`}
      />
    </>
  )
}
