import React, { FC } from 'react'
// import { IProject } from '@deepwork/interfaces'
// import { PrimaryCard, RoundedBox } from '@deepwork/ui-components'
// import { formatDateRange } from '@deepwork/utils'
import { IProject } from 'libs/interfaces/src'
import { PrimaryCard, RoundedBox } from 'libs/ui-components/src'
import { formatDateRange } from 'libs/utils/src'
import { Box, Button, Skeleton, Text } from '@chakra-ui/react'

interface Props {
  loading?: boolean
  onCreateProject: () => void
  onProjectClick: (project: IProject) => void
  canCreateProject?: boolean
  emptyProjectsText: string
  projects?: IProject[]
}
export const ProjectsTab: FC<Props> = ({
  loading,
  projects,
  onCreateProject,
  canCreateProject,
  emptyProjectsText,
  onProjectClick,
}) => {
  const handleProjectClick = (project: IProject) => () => {
    onProjectClick(project)
  }

  return !loading ? (
    projects?.length ? (
      <Box display="flex" flexWrap="wrap" maxWidth={'100%'}>
        {projects.map((project, index) => (
          <PrimaryCard
            key={index}
            dateRange={formatDateRange(project.dateRange[0], project.dateRange[1])}
            onCardClick={handleProjectClick(project)}
            organizationName={project.organization.name}
            organizationImage={project.organization.imageUrl}
            projectName={project.name}
            users={project.applicants?.map((item) => item.avatarUrl)}
            tag={project.tag}
            status={project.done ? 'Completed' : ''}
            boxProps={{
              mr: {
                base: 0,
                sm: index % 2 === 0 ? '10px' : '0',
                md: index % 2 === 0 ? '20px' : '0',
              },
              mb: '20px',
            }}
          />
        ))}
      </Box>
    ) : (
      <RoundedBox h={'325px'} display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          {canCreateProject ? (
            <>
              <Text variant="h6">{emptyProjectsText}</Text>
              <Button mt="16px" onClick={onCreateProject}>
                Create a new project
              </Button>
            </>
          ) : (
            <Text variant="h6">{emptyProjectsText}</Text>
          )}
        </Box>
      </RoundedBox>
    )
  ) : (
    <Skeleton isLoaded={!loading} w="100%" h="325px" borderRadius="24px" />
  )
}
