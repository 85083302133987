import { SystemStyleObject } from '@chakra-ui/react'

export const baseStyle: SystemStyleObject = {
  color: 'white',
  mr: 0,
  mb: 4,
}

export const FormLabel = {
  baseStyle,
}
