import React, { FC, useEffect } from 'react'
import { Box, Button, Checkbox, Text, useDisclosure } from '@chakra-ui/react'
import { useProjectFeedbackDispatch, useProjectFeedbackState } from '../../ProjectFeedbackProvider'
// import { ITask, IUser, TaskReview } from '@deepwork/interfaces'
// import {
//   AdminCreateButton,
//   Autocomplete,
//   BadgeType,
//   BadgeTypeToggle,
//   RoundedBox,
//   TaskItem
// } from '@deepwork/ui-components'
import { ITask, IUser, TaskReview } from 'libs/interfaces/src'
import {
  AdminCreateButton,
  Autocomplete,
  BadgeType,
  BadgeTypeToggle,
  RoundedBox,
  TaskItem,
} from 'libs/ui-components/src'
import { debounce } from 'lodash'
import { getUsers } from '../../../../../shared/api/users'

interface Props {
  onBack: () => void
  onNext: () => void
  colleague: Pick<IUser, 'id' | 'username'> | null
  tasks: Pick<ITask, 'id' | 'title' | 'description'>[]
  allRoleTasks: Pick<ITask, 'id' | 'title' | 'description'>[]
}

interface TasksProps {
  name: string
  tasks: Pick<ITask, 'id' | 'title' | 'description'>[]
  selectedTasks: TaskReview[]
  onToggleTask: (task: TaskReview) => void
  onToggleContributionType?: (task: TaskReview) => void
  isShadower?: boolean
}
const TasksComponent: FC<TasksProps> = ({
  name,
  tasks,
  selectedTasks,
  onToggleTask,
  onToggleContributionType,
  isShadower,
}) => {
  const handleToggleBadge = (taskId: string) => (contributionType: number) => {
    onToggleContributionType &&
      onToggleContributionType({
        id: taskId,
        contributionType: contributionType,
      })
  }

  const handleToggleTask = (taskId: string) => () => {
    onToggleTask({
      id: taskId,
      contributionType: isShadower
        ? BadgeType.shadowed
        : selectedTasks.find((item) => item.id === taskId)?.contributionType ?? BadgeType.completed,
    })
  }
  return (
    <Box mb={'43px'}>
      <Box mb="25px">
        <Text variant="h2" color="white" mb="14px">
          Attest {name}’s capabilities
        </Text>
        <Text color="gray.79">
          Please select only the tasks which {name} was able to complete without your support
          exceptionally well. This will be seen on their profile along with your name and help
          others assess their capabilities before a future project starts.
        </Text>
      </Box>
      {tasks.map((task) => (
        <TaskItem
          key={task.id}
          title={task.title}
          description={task.description}
          beforeElement={
            <Box display="flex" justifyContent="center" alignItems="center">
              <Checkbox
                isChecked={selectedTasks.some((item) => item.id === task.id)}
                onChange={handleToggleTask(task.id)}
                mr="17.5px"
              />
              <BadgeTypeToggle
                value={
                  selectedTasks.find((item) => item.id === task.id)?.contributionType ??
                  BadgeType.completed
                }
                onChange={handleToggleBadge(task.id)}
                disabled={isShadower}
              />
            </Box>
          }
          boxProps={{
            mb: '13px',
          }}
        />
      ))}
    </Box>
  )
}

export const CredentialsStep: FC<Props> = ({ colleague, tasks, allRoleTasks, onBack, onNext }) => {
  const { credentials } = useProjectFeedbackState()
  const { onUpdateCredentialsStep } = useProjectFeedbackDispatch()
  const [users, setUsers] = React.useState<{ value: string; label: string }[]>([])
  const { isOpen, onClose, onOpen } = useDisclosure()

  const handleSearchShadowers = (data: string, extra: { action: string }) => {
    if (extra.action !== 'input-change') return
    getUsers({
      params: {
        name: data,
      },
    }).then((data: IUser[]) => {
      setUsers(
        data
          .filter((shadower) => !credentials.shadowers?.some((item) => item.id === shadower.id))
          .map((user) => ({ label: user.username, value: user.id })),
      )
    })
  }

  const handleSelectShadower = (data: { value: string; label: string }) => {
    onUpdateCredentialsStep({
      shadowers: [
        ...(credentials.shadowers ?? []),
        { id: data.value, username: data.label, tasks: [] },
      ],
    })
    onClose()
  }

  const handleToggleContributorTask = (task: TaskReview) => {
    onUpdateCredentialsStep({
      contributor: {
        ...credentials.contributor,
        id: colleague?.id ?? '',
        username: colleague?.username,
        tasks: credentials.contributor.tasks.find((item) => item.id === task.id)
          ? credentials.contributor.tasks.filter((item) => item.id !== task.id)
          : [...credentials.contributor.tasks, task],
      },
    })
  }

  const handleToggleShadowerTask =
    (shadower: { username?: string; id: string }, index: number) => (task: TaskReview) => {
      onUpdateCredentialsStep({
        shadowers: credentials.shadowers?.some((item) => item.id === shadower.id)
          ? credentials.shadowers?.map((item, i) => {
              return i === index
                ? {
                    ...item,
                    tasks: item.tasks.find((item) => item.id === task.id)
                      ? item.tasks.filter((item) => item.id !== task.id)
                      : [...item.tasks, task],
                  }
                : item
            })
          : [
              ...(credentials.shadowers ?? []),
              {
                id: shadower.id,
                username: shadower.username,
                tasks: [task],
              },
            ],
      })
    }

  const handleToggleContributionType = (task: TaskReview) => {
    onUpdateCredentialsStep({
      contributor: {
        id: colleague?.id ?? '',
        username: colleague?.username,
        tasks: credentials.contributor.tasks.find((item) => item.id === task.id)
          ? credentials.contributor.tasks.map((item) =>
              item.id === task.id ? { ...item, contributionType: task.contributionType } : item,
            )
          : [...credentials.contributor.tasks, task],
      },
    })
  }

  useEffect(() => {
    if (colleague && !credentials.contributor.id) {
      onUpdateCredentialsStep({
        contributor: {
          id: colleague.id,
          username: colleague.username,
          tasks: tasks.map((item) => ({ ...item, contributionType: BadgeType.completed })),
        },
      })
    }
  }, [])

  return (
    <Box>
      {credentials.contributor.id && (
        <TasksComponent
          name={colleague?.username ?? ''}
          tasks={tasks}
          selectedTasks={credentials.contributor.tasks}
          onToggleTask={handleToggleContributorTask}
          onToggleContributionType={handleToggleContributionType}
        />
      )}

      {credentials.shadowers?.map((item, index) => (
        <TasksComponent
          key={index}
          name={item.username ?? ''}
          tasks={allRoleTasks}
          selectedTasks={credentials.shadowers?.[index]?.tasks ?? []}
          isShadower
          onToggleTask={handleToggleShadowerTask(item, index)}
        />
      ))}

      {isOpen ? (
        <RoundedBox>
          <Text>Add a shadower</Text>
          <Autocomplete
            options={users}
            onInputChange={debounce(handleSearchShadowers)}
            onChange={handleSelectShadower}
            placeholder={'Deep Work'}
          />
        </RoundedBox>
      ) : (
        <AdminCreateButton onClick={onOpen}>Add a shadower</AdminCreateButton>
      )}

      <Box mt="67px">
        <Button variant="link" mr="40px" onClick={onBack}>
          Back
        </Button>
        <Button onClick={onNext}>Next</Button>
      </Box>
    </Box>
  )
}
