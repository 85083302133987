import { Box, Button, Text } from '@chakra-ui/react'
import { FC } from 'react'
// import { RoundedBox } from '@deepwork/ui-components'
// import { pluralise } from '@deepwork/utils'
import { RoundedBox } from 'libs/ui-components/src'
import { pluralise } from 'libs/utils/src'

type Props = {
  title: string
  count: number[]
  onClick: () => void
}
export const SkillListItem: FC<Props> = ({ title, onClick, count }) => (
  <RoundedBox
    p="28px 25px"
    display="flex"
    flexDirection={{ base: 'column', sm: 'row' }}
    justifyContent="space-between"
    mb="18px"
  >
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flex="1 0 auto"
      mr={{ base: 0, sm: '30px', md: '70px' }}
      mb={{ base: '17px', sm: 0 }}
      width={{ base: '100%', sm: 'auto' }}
    >
      <Box maxW="200px">
        <Text variant="h4" color="white">
          {title}
        </Text>
      </Box>
      <Text variant="h6" color="white" ml={{ base: 0, sm: '30px', md: '70px' }}>
        {count.length === 2
          ? `${count[0]}/${count[1]} capabilities`
          : `${count[0]} ${pluralise('capabilitie', count[0], 'capability')}`}
      </Text>
    </Box>
    <Box display="flex" justifyContent={{ base: 'flex-end', md: 'center' }} alignItems="center">
      <Button variant="link" onClick={onClick}>
        Show progression
      </Button>
    </Box>
  </RoundedBox>
)
