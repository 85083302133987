// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { config } from 'dotenv'
config()

export const environment = {
  production: false,
  BACKEND_URL: process.env.REACT_APP_BACKEND,
}
