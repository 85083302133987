import React from 'react'
import { Box, Button, Text, useDisclosure } from '@chakra-ui/react'
// import { AdminCreateButton, RoleItem } from '@deepwork/ui-components'
// import { IProjectRole, IUser, OrganisationRolesDTO } from '@deepwork/interfaces'
import { AdminCreateButton, RoleItem } from 'libs/ui-components/src'
import { IProjectRole, IUser, OrganisationRolesDTO } from 'libs/interfaces/src'
import { useProjectFlowDispatch, useProjectFlowState } from '../../ProjectFlowContextProvider'
import { useUserContextState } from '../../../../../shared/contexts/user-context-provider'
import { ChooseRoleModal } from '../../../../../components/modals/choose-role-modal'

interface Props {
  presetRoles: OrganisationRolesDTO[]
  isEditProject?: boolean
  onBack?: () => void
  onNext: () => void
  nextButtonText?: string
}
export const RolesStep: React.FC<Props> = ({
  onNext,
  isEditProject, // eslint-disable-line
  onBack,
  presetRoles,
  nextButtonText,
}) => {
  const { roles } = useProjectFlowState()
  const { user } = useUserContextState()
  const { onUpdateRoles } = useProjectFlowDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editRole, setEditRole] = React.useState<IProjectRole & { isReadOnly?: boolean }>()
  const [roleNumber, setRoleNumber] = React.useState(1)
  const [createProjectOwnerRole, setCreateProjectOwnerRole] = React.useState<Pick<
    IUser,
    'id' | 'username' | 'avatarUrl'
  > | null>(null)

  const additionalRoles = React.useMemo(() => {
    return roles.filter((role) => !role.isProjectOwner)
  }, [roles])

  const projectOwnerRole = React.useMemo(() => {
    return roles.find((role) => role.isProjectOwner)
  }, [roles])

  const handleNextClick = () => {
    onNext()
  }

  const handleSubmit = (role: IProjectRole) => {
    const foundRole = roles.find(
      (r) => r.uId === role.uId && r.isProjectOwner === role.isProjectOwner,
    )
    if (foundRole) {
      onUpdateRoles(
        roles.map((r) =>
          r.uId === role.uId && r.isProjectOwner === role.isProjectOwner ? role : r,
        ),
      )
      setRoleNumber(roleNumber + 1)
    } else {
      onUpdateRoles([...roles, role])
    }
    setCreateProjectOwnerRole(null)
    onClose()
  }

  const handleOnClose = () => {
    setEditRole(undefined)
    onClose()
  }

  const handleAddNew = () => {
    setCreateProjectOwnerRole(null)
    onOpen()
  }

  const handleAddProjectOwnerRole = () => {
    setCreateProjectOwnerRole(user)
    onOpen()
  }

  const handleRemove = (id: string) => {
    onUpdateRoles(roles.filter((role) => role.uId !== id))
  }

  const handleEditRole = (roleId: string) => {
    const findIndex = roles.findIndex((r) => r.uId === roleId)

    if (findIndex !== -1) {
      setEditRole({
        ...roles[findIndex],
      })
      setRoleNumber(findIndex + 1)
      onOpen()
    }
  }

  const handleEditProjectOwnerRole = () => {
    if (projectOwnerRole) {
      setCreateProjectOwnerRole(projectOwnerRole.assignee)
      handleEditRole(projectOwnerRole.uId)
    }
  }

  return (
    <>
      <Box>
        <Text variant="h2" color="#fff" mb="25px">
          Project owner
        </Text>
        <Box mb={'43px'}>
          {projectOwnerRole ? (
            <RoleItem
              isReadOnly={projectOwnerRole.isReadOnly}
              roleId={projectOwnerRole.uId}
              title={projectOwnerRole.title}
              assignee={projectOwnerRole.assignee}
              tasksCount={projectOwnerRole.tasks.length}
              onEdit={handleEditProjectOwnerRole}
              onDelete={handleRemove}
            />
          ) : (
            <AdminCreateButton data-testid={'add-role-btn'} onClick={handleAddProjectOwnerRole}>
              Add a project owner role
            </AdminCreateButton>
          )}
        </Box>
        <Text variant="h2" color="#fff" mb="25px">
          Add roles and tasks
        </Text>
        {additionalRoles && additionalRoles.length > 0 && (
          <Box mb={'25px'}>
            {additionalRoles.map((role) => (
              <Box key={role.uId} mb="16px">
                <RoleItem
                  isReadOnly={role.isReadOnly}
                  roleId={role.uId}
                  title={role.title}
                  assignee={role.assignee}
                  tasksCount={role.tasks.length}
                  onEdit={handleEditRole}
                  onDelete={handleRemove}
                />
              </Box>
            ))}
          </Box>
        )}
        <AdminCreateButton data-testid={'add-role-btn'} onClick={handleAddNew}>
          Add a new role
        </AdminCreateButton>
        <Box mt={'49px'}>
          {onBack && (
            <Button variant="link" mr="40px" onClick={onBack}>
              Back
            </Button>
          )}
          <Button isDisabled={roles.length === 0} onClick={handleNextClick} data-testid="next-btn">
            {nextButtonText ?? 'Next'}
          </Button>
        </Box>
      </Box>
      {isOpen && (
        <ChooseRoleModal
          isReadOnly={!!editRole?.isReadOnly}
          isOpen={isOpen}
          role={editRole}
          onClose={handleOnClose}
          presetRoles={presetRoles ?? []}
          projectOwnerAssignee={createProjectOwnerRole}
          roleNumber={roleNumber}
          onSave={handleSubmit}
        />
      )}
    </>
  )
}
