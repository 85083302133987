import React, { FC } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { ProfileBox } from '../profile-box/profile-box'
// import { IUser } from '@deepwork/interfaces'
import { IUser } from 'libs/interfaces/src'

export interface FeedbackItemProps {
  /**
   * User
   * @type IUser
   */
  user: Pick<IUser, 'id' | 'username' | 'avatarUrl'> & { jobTitle?: string }
  /**
   * Main content
   */
  content: string
}
export const FeedbackItem: FC<FeedbackItemProps> = ({ content, user }) => (
  <Box pl="24px">
    <Text color="gray.79" mb="10px">
      {content}
    </Text>
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <ProfileBox
        size="sm"
        name={user.username}
        avatarUrl={user.avatarUrl}
        jobTitle={user.jobTitle}
      />
    </Box>
  </Box>
)
