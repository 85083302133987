import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import axios from '../api/setup'
// import { EditUserDto } from '@deepwork/interfaces'
import { EditUserDto } from 'libs/interfaces/src'
import { useToast } from '@chakra-ui/react'
import { convertToFormData } from '../utils/api'

export const useUserProfileMutation = (
  options?: UseMutationOptions<void, string, Omit<EditUserDto, 'photo'> & { image: any }>,
): UseMutationResult<void, string, Omit<EditUserDto, 'photo'> & { image: any }> => {
  const toast = useToast()
  return useMutation(
    async (data) => {
      await axios.put('/actor', convertToFormData(data))
    },
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}

export const useInterestMutation = (
  options?: UseMutationOptions<void, string, { taskId: string; isChecked: boolean }>,
): UseMutationResult<void, string, { taskId: string; isChecked: boolean }> => {
  const toast = useToast()
  return useMutation(
    async ({ taskId, isChecked }) => {
      await axios.put(`/actor/wishlist/task/${taskId}/interested/${isChecked}`)
    },
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}
