import React, { FC, useEffect, useRef } from 'react'
import { Box, Button, FormControl, FormLabel, Text } from '@chakra-ui/react'
// import {
//   FileUpload,
//   FormikInputControl,
//   FormikTextareaControl,
//   ProfileSelection,
//   RoundedBox
// } from '@deepwork/ui-components'
// import { DetailsValues, IUser } from '@deepwork/interfaces'
import {
  FileUpload,
  FormikInputControl,
  FormikTextareaControl,
  ProfileSelection,
  RoundedBox,
} from 'libs/ui-components/src'
import { DetailsValues, IUser } from 'libs/interfaces/src'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'
import { useNewOrganisationDispatch, useNewOrganisationState } from '../../NewOrganisationContext'
import { useUserContextState } from '../../../../../shared/contexts/user-context-provider'
import { useUsers } from '../../../../../shared/queries/users'
import { validateOrganisationName } from '../../../../../shared/utils/validation-schemas/unique-username'

interface Props {
  onNext: () => void
}

export const DetailsStep: FC<Props> = ({ onNext }) => {
  const { user } = useUserContextState()
  const { details: detailsValues } = useNewOrganisationState()
  const formRef = useRef<FormikProps<DetailsValues>>(null)
  const { onUpdateDetails } = useNewOrganisationDispatch()
  const [availableProfiles, setAvailableProfiles] = React.useState<
    Pick<IUser, 'id' | 'username' | 'avatarUrl'>[]
  >([])
  const { data: users } = useUsers({
    params: {
      eligible: true,
    },
  })

  const handleSubmitTask = (values: DetailsValues) => {
    onUpdateDetails(values)
    onNext()
  }

  useEffect(() => {
    if (user && !formRef.current?.values.admin?.id) {
      formRef.current?.setFieldValue('admin', user)
    }
  }, [user, formRef.current?.values.admin?.id])

  useEffect(() => {
    if (users) {
      setAvailableProfiles(
        users.map((u: IUser) => ({
          id: u.id,
          username: u.username,
          avatarUrl: u.avatarUrl,
        })),
      )
    }
  }, [users])

  return (
    <Box>
      <Text variant="h2" color="white" mb="25px">
        Enter organisation details
      </Text>
      <RoundedBox mb="25px">
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={detailsValues}
          onSubmit={handleSubmitTask}
          validationSchema={Yup.object({
            admin: Yup.object().required('Organisation admin is required'),
            externalPaymentSystemUrl: Yup.string().url(),
            projectCompletionImageUrl: Yup.string().url(),
            description: Yup.string().required('Description is required'),
            websiteUrl: Yup.string().url(),
            community: Yup.string().url(),
            knowledgeBaseUrl: Yup.string().url(),
          }).concat(validateOrganisationName)}
        >
          {({ setFieldValue, errors, values, touched }) => ( // eslint-disable-line
            <Form id="detailsForm" noValidate>
              <Box
                display="flex"
                justifyContent="space-between"
                mb="32px"
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <FormikInputControl
                  label="Organisation name"
                  name="name"
                  placeholder="Deep Work Studio"
                  styleProps={{
                    mr: { base: '0', md: '30px' },
                    mb: { base: '32px', md: 0 },
                  }}
                />
                <ProfileSelection
                  label="Organisation admin"
                  tooltipLabel="Select organisation admin"
                  selectedProfile={values.admin}
                  possibleProfiles={availableProfiles}
                  error={errors.admin as string}
                  showError={!!errors.admin}
                  onChange={(profile) => setFieldValue('admin', profile)}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                mb="32px"
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <FormikInputControl
                  label="External payment system"
                  tooltipLabel="External payment system"
                  name="externalPaymentSystemUrl"
                  placeholder="https://colony.io/"
                  styleProps={{
                    mr: { base: '0', md: '30px' },
                    mb: { base: '32px', md: 0 },
                  }}
                />
                <FormikInputControl
                  label="Project completion image URL"
                  tooltipLabel="Project completion image URL on the cloud"
                  placeholder="https://deepworkphotos.s3.us-east-2.amazonaws..."
                  name="projectCompletionImageUrl"
                />
              </Box>
              <FormikInputControl
                label="Description"
                type="text"
                name="description"
                placeholder="A collective of designers with shared profits creating meaningful projects in web3."
                styleProps={{
                  mb: '32px',
                  display: { base: 'none', md: 'flex' },
                  flexDirection: 'column',
                }}
              />
              <FormikTextareaControl
                label="Description"
                name="description"
                size={'sm'}
                placeholder="A collective of designers with shared profits creating meaningful projects in web3."
                styleProps={{
                  mb: '32px',
                  display: { base: 'flex', md: 'none' },
                  flexDirection: 'column',
                }}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                mb="32px"
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <FormikInputControl
                  label="Website URL"
                  name="websiteUrl"
                  placeholder="https://deepwork.studio"
                  styleProps={{
                    mr: { base: '0', md: '30px' },
                    mb: { base: '32px', md: 0 },
                  }}
                />
                <FormikInputControl
                  label="Community URL"
                  name="community"
                  placeholder="https://discord.gg/hamFDG5f"
                  styleProps={{
                    mr: { base: '0', md: '30px' },
                    mb: { base: '32px', md: 0 },
                  }}
                />
                <FormikInputControl
                  label="Knowledge base URL"
                  name="knowledgeBaseUrl"
                  placeholder="https://docs.deepwork.studio"
                />
              </Box>
              <FormControl>
                <FormLabel>Logo</FormLabel>
                <FileUpload name="logo" onChange={setFieldValue} />
              </FormControl>
            </Form>
          )}
        </Formik>
      </RoundedBox>
      <Button type="submit" form="detailsForm" data-testid="next-btn">
        Next
      </Button>
    </Box>
  )
}
