import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { FC } from 'react'
const keyBy = require('lodash.keyby')
// import { RoleCard } from '@deepwork/ui-components'
// import { IProjectDetailRole, IUser, ProjectApplicant, RoleTypeEnum } from '@deepwork/interfaces'
// import { formatDateRange } from '@deepwork/utils'
import { RoleCard } from 'libs/ui-components/src'
import { IProjectDetailRole, IUser, ProjectApplicant, RoleTypeEnum } from 'libs/interfaces/src'
import { formatDateRange } from 'libs/utils/src'
import { APP_PATHS } from '../../paths'
import { useNavigate } from 'react-router-dom'
import { useUserContextState } from '../../shared/contexts/user-context-provider'

interface ProjectRolesListProps {
  userRoles?: any[]
  applications?: ProjectApplicant[]
  projectOwner?: IProjectDetailRole
  roles: IProjectDetailRole[]
  onOpenTaskSummary: (roleId: string, username: string) => void
  onApplyClick: (role: IProjectDetailRole, type: RoleTypeEnum) => void
  onWithdrawClick: (role: IProjectDetailRole, type: RoleTypeEnum) => void
  onRemoveClick: (userId: string, roleId: string) => void
}

export const ProjectRolesList: FC<ProjectRolesListProps> = ({
  projectOwner,
  userRoles,
  applications,
  roles,
  onApplyClick,
  onOpenTaskSummary,
  onWithdrawClick,
  onRemoveClick,
}) => {
  const navigate = useNavigate()
  const {
    isOpen: isOpenRoleDetails,
    onClose: onCloseRoleDetails,
    onOpen: onOpenRoleDetails,
  } = useDisclosure()
  const [roleDetail, setRoleDetail] = React.useState<IProjectDetailRole>()
  const { user } = useUserContextState()
  const userRolesMap = keyBy(userRoles, 'id')
  const handleOpenRoleDetails = (role: IProjectDetailRole) => () => {
    setRoleDetail(role)
    onOpenRoleDetails()
  }

  const handleUserClick = (userParam: Pick<IUser, 'username' | 'avatarUrl'>) => {
    navigate(APP_PATHS.userProfile.replace(':username', userParam.username))
  }

  const handleOpenTaskSummary = (roleId: string) => (username: string) => {
    onOpenTaskSummary(roleId, username)
  }

  const handleApplyClick = (role: IProjectDetailRole) => (type: RoleTypeEnum) => {
    onApplyClick(role, type)
  }

  const handleWithdrawApplication = (role: IProjectDetailRole) => (type: RoleTypeEnum) => {
    onWithdrawClick(role, type)
  }

  const handleRemoveApplication = (userId: string, roleId: string) => () => {
    onRemoveClick(userId, roleId)
  }

  const userHasAppliedToRole = (role: IProjectDetailRole, type: RoleTypeEnum) =>
    applications?.find(
      (app) => app.roleUId === role?.uId && app.actor.id === user?.id && type === app?.type,
    )

  const checkIfAppliedToRole = (role: IProjectDetailRole, type: RoleTypeEnum) => {
    const foundApplication = userHasAppliedToRole(role, type)
    return (
      foundApplication?.roleUId === role.uId &&
      type === foundApplication?.type &&
      !foundApplication?.approved
    )
  }

  const checkIfUserIsInRole = (roleActor: Pick<IUser, 'id' | 'username' | 'avatarUrl'> | null) => {
    return roleActor?.id === user?.id
  }

  const approvedApplications = applications?.filter((a) => {
    return a.approved
  })
  const approvedApplicantsMap = keyBy(approvedApplications, 'roleId')

  return (
    <>
      {!!projectOwner && (
        <Box>
          <Text mb="26px" color={'white'} variant="h2">
            Project owner
          </Text>
          <RoleCard
            key={projectOwner.uId}
            title={projectOwner.title}
            inReview={
              checkIfAppliedToRole(projectOwner, RoleTypeEnum.Expert) ||
              checkIfAppliedToRole(projectOwner, RoleTypeEnum.Contributor)
            }
            alreadyApplied={
              checkIfUserIsInRole(projectOwner.expert.actor) ||
              checkIfUserIsInRole(projectOwner.contributor.actor)
            }
            onRoleDetailsClick={handleOpenRoleDetails(projectOwner)}
            onApplyClick={handleApplyClick(projectOwner)}
            onUserClick={handleUserClick}
            onTasksClick={handleOpenTaskSummary(projectOwner.uId)}
            onWithdrawClick={handleWithdrawApplication(projectOwner)}
            lead={{
              status:
                !projectOwner.expert.actor &&
                checkIfAppliedToRole(projectOwner, RoleTypeEnum.Expert)
                  ? 'pending'
                  : 'open',
              user:
                userHasAppliedToRole(projectOwner, RoleTypeEnum.Expert)?.actor &&
                checkIfAppliedToRole(projectOwner, RoleTypeEnum.Expert)
                  ? userHasAppliedToRole(projectOwner, RoleTypeEnum.Expert)?.actor ?? null
                  : projectOwner.expert.actor,
              jobTitle: `Lead ${projectOwner.title}`,
              payment: projectOwner.expert.payment,
              dates: formatDateRange(projectOwner.dates[0], projectOwner.dates[1]),
              tasks: projectOwner.expert.tasks,
              timeValue: projectOwner.expert.timeValue,
            }}
            contributor={{
              status:
                !projectOwner.contributor.actor &&
                checkIfAppliedToRole(projectOwner, RoleTypeEnum.Contributor)
                  ? 'pending'
                  : 'open',
              user:
                !projectOwner.contributor.actor &&
                checkIfAppliedToRole(projectOwner, RoleTypeEnum.Contributor)
                  ? userHasAppliedToRole(projectOwner, RoleTypeEnum.Contributor)?.actor ?? null
                  : projectOwner.contributor.actor,
              jobTitle: `Contributor ${projectOwner.title}`,
              payment: projectOwner.contributor.payment,
              dates: formatDateRange(projectOwner.dates[0], projectOwner.dates[1]),
              tasks: projectOwner.contributor.tasks,
              timeValue: projectOwner.contributor.timeValue,
            }}
          />
        </Box>
      )}
      {!!roles.length && (
        <Box mt="67px">
          <Text mb="26px" color={'white'} variant="h2">
            Team
          </Text>
          {roles.map((role) => (
            <Box mb="26px" key={role.uId}>
              <RoleCard
                title={role.title}
                isExpert={userRolesMap[role.id]?.isExpert}
                canApprove={approvedApplicantsMap[role.id]?.canApprove}
                inReview={
                  checkIfAppliedToRole(role, RoleTypeEnum.Expert) ||
                  checkIfAppliedToRole(role, RoleTypeEnum.Contributor)
                }
                alreadyApplied={
                  checkIfUserIsInRole(role.expert.actor) ||
                  checkIfUserIsInRole(role.contributor.actor)
                }
                onRoleDetailsClick={handleOpenRoleDetails(role)}
                onApplyClick={handleApplyClick(role)}
                onUserClick={handleUserClick}
                onTasksClick={handleOpenTaskSummary(role.uId)}
                onWithdrawClick={handleWithdrawApplication(role)}
                onRemoveClick={handleRemoveApplication(
                  approvedApplicantsMap[role.id]?.actor?.id,
                  role.uId,
                )}
                lead={{
                  status:
                    !role.expert.actor && checkIfAppliedToRole(role, RoleTypeEnum.Expert)
                      ? 'pending'
                      : 'open',
                  user:
                    !role.expert.actor && checkIfAppliedToRole(role, RoleTypeEnum.Expert)
                      ? userHasAppliedToRole(role, RoleTypeEnum.Expert)?.actor ?? null
                      : role.expert.actor,
                  jobTitle: `Lead ${role.title}`,
                  payment: role.expert.payment,
                  dates: formatDateRange(role.dates[0], role.dates[1]),
                  tasks: role.expert.tasks,
                  timeValue: role.expert.timeValue,
                }}
                contributor={{
                  status:
                    !role.contributor.actor && checkIfAppliedToRole(role, RoleTypeEnum.Contributor)
                      ? 'pending'
                      : 'open',
                  user:
                    !role.contributor.actor && checkIfAppliedToRole(role, RoleTypeEnum.Contributor)
                      ? userHasAppliedToRole(role, RoleTypeEnum.Contributor)?.actor ?? null
                      : role.contributor.actor,
                  jobTitle: `Contributor ${role.title}`,
                  payment: role.contributor.payment,
                  dates: formatDateRange(role.dates[0], role.dates[1]),
                  tasks: role.contributor.tasks,
                  timeValue: role.contributor.timeValue,
                }}
              />
            </Box>
          ))}
        </Box>
      )}

      {roleDetail && (
        <Modal
          size="sm"
          isOpen={isOpenRoleDetails}
          onClose={onCloseRoleDetails}
          returnFocusOnClose={false}
          autoFocus={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{roleDetail.title}</ModalHeader>
            <ModalCloseButton data-testid="close-modal" />
            <ModalBody>
              <Text color="white" variant="h6">
                {roleDetail.description}
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
