import React, { FC } from 'react'
import { Box, BoxProps, Button, Collapse, IconButton, Text, useDisclosure } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

export interface TaskItemProps {
  /**
   * Size option
   * @default sm
   */
  size?: 'sm' | 'lg'
  title: string
  description?: string
  /**
   * Helper text
   */
  helperText?: string
  beforeElement?: React.ReactNode
  /**
   * Edit click callback, it will show the edit button if it's passed
   */
  onEditClick?: () => void
  /**
   * BoxProps are used for extra styling on the wrapper Box
   */
  boxProps?: BoxProps
}
export const TaskItem: FC<TaskItemProps> = ({
  size = 'sm',
  beforeElement,
  title,
  description,
  helperText,
  onEditClick,
  boxProps,
}) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bg="gray.22"
      borderRadius="12px"
      px="25px"
      py="15px"
      minHeight="57px"
      {...boxProps}
    >
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" flex="1 0 auto">
          <Box
            display="flex"
            alignItems={{ base: 'flex-start', md: 'center' }}
            justifyContent="space-between"
            flexDirection={{ base: 'column', md: 'row' }}
            flex="1 0 auto"
          >
            <Box display="flex" alignItems="center" maxW={{ base: '175px', sm: '100%' }}>
              {beforeElement ? (
                <Box display="flex" mr="15px">
                  {beforeElement}
                </Box>
              ) : null}
              <Text variant="h4" color="white" isTruncated>
                {title}
              </Text>
            </Box>
            {!!helperText && (
              <Text color="fluorescent" variant={size === 'lg' ? 'helper' : 'small'} mr="30px">
                {helperText}
              </Text>
            )}
          </Box>

          {description && (
            <IconButton
              variant="ghost"
              color="primary"
              aria-label={isOpen ? 'open collapse' : 'close collapse'}
              onClick={onToggle}
              icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            />
          )}
        </Box>
        <Collapse in={isOpen} animateOpacity>
          <Text variant="small" color="white" my="16px">
            {description}
          </Text>
          <Box display="flex" justifyContent={onEditClick ? 'space-between' : 'flex-end'}>
            {!!onEditClick && (
              <Button variant="link" mr="12px" onClick={onEditClick}>
                Edit
              </Button>
            )}
            {/*{!!detailsLink && (
              <Button
                as='a'
                variant='link'
                href={detailsLink}
                target='_blank'
                rightIcon={<ExternalLinkIcon />}
              >
                Task Details
              </Button>
            )}*/}
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}
