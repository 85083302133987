import React, { FC } from 'react'
import { Box, BoxProps, Skeleton, Text, Tooltip } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'

interface Props {
  loading?: boolean
  /**
   * Label used to display on top of the box
   */
  label: string
  /**
   * Tooltip label. If the value is missing, then the icon is not rendered
   */
  tooltipLabel?: string
  gap?: BoxProps['margin']
  /**
   * BoxProps are used for extra styling on the wrapper Box
   */
  boxProps?: BoxProps
}
export const DataBox: FC<Props> = ({
  loading = false,
  label,
  tooltipLabel,
  gap,
  children,
  boxProps,
}) => (
  <Box display="flex" flexDirection="column" {...boxProps}>
    {loading ? (
      <Skeleton isLoaded={!loading} h="18px" w="100px" />
    ) : (
      <Box display="flex" alignItems="center">
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="17.71px"
          color="gray.79"
          mb={gap}
          mr={tooltipLabel ? '6px' : 0}
        >
          {label}
        </Text>
        {!!tooltipLabel && (
          <Tooltip label={tooltipLabel}>
            <InfoIcon color="gray.79" />
          </Tooltip>
        )}
      </Box>
    )}
    {loading ? (
      <Skeleton isLoaded={!loading} h="18px" w="100px" />
    ) : typeof children === 'string' || typeof children === 'number' ? (
      <Text fontWeight="400" fontSize="14px" lineHeight="17.71px" color="white">
        {children}
      </Text>
    ) : (
      children
    )}
  </Box>
)
