import { useToast } from '@chakra-ui/react'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import axios from '../api/setup'
// import { LoginUserDto, RegisterUserDto } from '@deepwork/interfaces'
import { LoginUserDto, LoginWithWalletDto, RegisterUserDto } from 'libs/interfaces/src'
import { convertToFormData } from '../utils/api'

export const useVerifyEmailMutation = (
  options?: UseMutationOptions<void, string, string>,
): UseMutationResult<void, string, string> => {
  const toast = useToast()
  return useMutation(
    (verificationToken?: string) => axios.get(`/actor/verify/${verificationToken}`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}

export const useResetPasswordMutation = (
  options?: UseMutationOptions<void, string, string>,
): UseMutationResult<void, string, string> => {
  const toast = useToast()
  return useMutation((email?: string) => axios.get('/actor/forgot', { params: { email } }), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}

export const useChangeResetPasswordMutation = (
  options?: UseMutationOptions<void, string, { token: string; newPassword: string }>,
): UseMutationResult<void, string, { token: string; newPassword: string }> => {
  const toast = useToast()
  return useMutation(
    async ({ token, newPassword }) =>
      axios.post(`/actor/reset/${token}`, { newPassword: newPassword }),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}

export const useChangePasswordMutation = (
  options?: UseMutationOptions<void, string, { oldPassword: string; newPassword: string }>,
): UseMutationResult<void, string, { oldPassword: string; newPassword: string }> => {
  const toast = useToast()
  return useMutation(
    async ({ oldPassword, newPassword }) =>
      axios.put(`/actor/password`, { newPassword, oldPassword }),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}

export const useLoginMutation = (
  options?: UseMutationOptions<{ token: string }, string, LoginUserDto>,
): UseMutationResult<{ token: string }, string, LoginUserDto> => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/actor/login', data), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}

export const useWalletLoginMutation = (
  options?: UseMutationOptions<{ token: string }, string, LoginWithWalletDto>,
): UseMutationResult<{ token: string }, string, LoginWithWalletDto> => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/actor/wallet-login', data), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}

export const useRegistrationMutation = (
  options?: UseMutationOptions<void, string, RegisterUserDto>,
): UseMutationResult<void, string, RegisterUserDto> => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/actor/register', convertToFormData(data)), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}
