import React, { FC } from 'react'
// import { IProfileSkill } from '@deepwork/interfaces'
// import { RoundedBox, SkillListItem } from '@deepwork/ui-components'
import { IProfileSkill } from 'libs/interfaces/src'
import { RoundedBox, SkillListItem } from 'libs/ui-components/src'
import { Box, Text } from '@chakra-ui/react'

interface Props {
  onShowProgression: (skill: IProfileSkill) => void
  skillGroup?: { [organisation: string]: IProfileSkill[] }
  emptyText: string
}
export const SkillsTab: FC<Props> = ({ skillGroup, emptyText, onShowProgression }) => {
  const handleShowProgression = (skill: IProfileSkill) => () => {
    onShowProgression(skill)
  }

  return (
    <Box>
      {skillGroup && !!Object.keys(skillGroup).length ? (
        Object.keys(skillGroup).map((organisation, index) => (
          <Box key={index}>
            <Text variant="h3" color="white" mb="21px">
              {organisation}
            </Text>
            <Box>
              {skillGroup[organisation].map((skill) => (
                <SkillListItem
                  key={skill.id}
                  title={skill.title}
                  count={[skill.tasksDone, skill.tasksTotal]}
                  onClick={handleShowProgression(skill)}
                />
              ))}
            </Box>
          </Box>
        ))
      ) : (
        <RoundedBox h={'325px'} display="flex" alignItems="center" justifyContent="center">
          <Text variant="h6">{emptyText}</Text>
        </RoundedBox>
      )}
    </Box>
  )
}
