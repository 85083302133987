import { Box, Button, FormControl, FormLabel, Input, Text } from '@chakra-ui/react'
import React from 'react'
// import { RoundedBox } from '@deepwork/ui-components'
import { RoundedBox } from 'libs/ui-components/src'
import { useNewOrganisationDispatch, useNewOrganisationState } from '../../NewOrganisationContext'

interface Props {
  onBack: () => void
  onSubmitProject: () => void
}
export const TermsStep: React.FC<Props> = ({ onBack, onSubmitProject }) => {
  const { eula } = useNewOrganisationState()
  const { onUpdateEula } = useNewOrganisationDispatch()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateEula(e.target.value)
  }

  const handleSubmit = () => {
    onSubmitProject()
  }

  return (
    <Box>
      <Text variant="h2" color="white" mb="25px">
        Terms &amp; Conditions
      </Text>
      <RoundedBox mb="43px">
        <FormControl>
          <FormLabel>Link to the terms &amp; conditions document</FormLabel>
          <Input placeholder="Document URL..." name="eula" value={eula} onChange={handleChange} />
        </FormControl>
      </RoundedBox>

      <Button variant="link" mr="40px" onClick={onBack}>
        Back
      </Button>
      <Button isDisabled={!eula} onClick={handleSubmit} data-testid="create-btn">
        Create
      </Button>
    </Box>
  )
}
