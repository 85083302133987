import { Text } from '@chakra-ui/react'
import { FC } from 'react'

export interface BudgetProps {
  /**
   * Currency symbol
   * @default $
   */
  symbol?: string
  /**
   * Currency
   * @default USD
   */
  currency?: string
  /**
   * Budget string, is not formatted in any way | Budget range will use only the first two items
   */
  budget: (string | number) | (string | number)[]
}
export const Budget: FC<BudgetProps> = ({ budget, symbol = '$', currency = 'USD' }) => {
  const { format } = new Intl.NumberFormat('en-US')

  let value = `${symbol}${format(Math.floor(+budget))}`

  if (typeof budget !== 'string' && typeof budget !== 'number') {
    if (budget[1] || budget[1] === 0) {
      value = `${symbol}${format(Math.floor(+budget[0]))}-${symbol}${format(
        Math.floor(+budget[1]),
      )}`
    }
  }

  return (
    <Text as="span" color="white" fontWeight="400" fontSize="14px" lineHeight="17.71px">
      {value}{' '}
      <Text as="span" fontWeight="400" fontSize="14px" lineHeight="17.71px">
        {currency}
      </Text>
    </Text>
  )
}
