export const Tabs = {
  variants: {
    line: {
      tab: {
        _selected: {
          color: 'primary',
          borderColor: 'primary',
        },
        _active: {
          background: 'transparent',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      tabpanel: {
        p: '0',
        mt: '33px',
      },
    },
  },
}
