import React, { useCallback, useReducer } from 'react'
// import { DetailsValues, IRoleValues, IUser, PeopleAndSkillsValues } from '@deepwork/interfaces'
import { DetailsValues, IRoleValues, IUser, PeopleAndSkillsValues } from 'libs/interfaces/src'

type Action =
  | { type: 'set-preset-roles'; payload: IRoleValues[] }
  | { type: 'update-details'; payload: Partial<DetailsValues> }
  | {
      type: 'update-people-and-skills'
      payload: Partial<PeopleAndSkillsValues>
    }
  | { type: 'update-eula'; payload: string }

export const NewOrganisationContext = React.createContext<State>({
  details: {} as DetailsValues,
  peopleAndSkills: {} as PeopleAndSkillsValues,
  eula: '',
})
export const NewOrganisationDispatchContext = React.createContext<{
  onUpdateDetails: (details: Partial<DetailsValues>) => void
  onUpdatePeopleAndSkills: (peopleAndSkills: Partial<PeopleAndSkillsValues>) => void
  onUpdateEula: (terms: string) => void
}>({
  onUpdateDetails: () => undefined,
  onUpdatePeopleAndSkills: () => undefined,
  onUpdateEula: () => undefined,
})
export type State = {
  details: DetailsValues
  peopleAndSkills: PeopleAndSkillsValues
  eula: string
}
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update-details':
      return { ...state, details: { ...state.details, ...action.payload } }
    case 'update-people-and-skills':
      return {
        ...state,
        peopleAndSkills: { ...state.peopleAndSkills, ...action.payload },
      }
    case 'update-eula':
      return { ...state, eula: action.payload }
    default: {
      return state
    }
  }
}

export const NewOrganisationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    details: {
      name: '',
      admin: {} as IUser,
      description: '',
      externalPaymentSystemUrl: '',
      community: '',
      projectCompletionImageUrl: '',
      knowledgeBaseUrl: '',
      websiteUrl: '',
    },
    peopleAndSkills: {
      emailList: [],
      roles: [],
    },
    eula: '',
  })

  const handleDispatch = useCallback((action: Action) => dispatch(action), [])

  const handleUpdateDetails = useCallback(
    (details: Partial<DetailsValues>) => {
      handleDispatch({ type: 'update-details', payload: details })
    },
    [handleDispatch],
  )

  const handleUpdatePeopleAndSkills = useCallback(
    (peopleAndSkills: Partial<PeopleAndSkillsValues>) => {
      handleDispatch({
        type: 'update-people-and-skills',
        payload: peopleAndSkills,
      })
    },
    [handleDispatch],
  )

  const handleUpdateTerms = useCallback(
    (eula: string) => {
      handleDispatch({ type: 'update-eula', payload: eula })
    },
    [handleDispatch],
  )

  return (
    <NewOrganisationContext.Provider value={state}>
      <NewOrganisationDispatchContext.Provider
        value={{
          onUpdateDetails: handleUpdateDetails,
          onUpdatePeopleAndSkills: handleUpdatePeopleAndSkills,
          onUpdateEula: handleUpdateTerms,
        }}
      >
        {children}
      </NewOrganisationDispatchContext.Provider>
    </NewOrganisationContext.Provider>
  )
}

export const useNewOrganisationState = () => {
  const context = React.useContext(NewOrganisationContext)
  if (context === undefined) {
    throw new Error('useNewOrganisationState must be used within a NewOrganisationContext')
  }
  return context
}

export const useNewOrganisationDispatch = () => {
  const context = React.useContext(NewOrganisationDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useNewOrganisationDispatch must be used within a NewOrganisationDispatchContext',
    )
  }
  return context
}
