// import {
//   GetActorRoleDetailsDTO,
//   GetCoworkersDto,
//   GetOrgListDTO,
//   IUser,
//   OrganisationDetailsDTO,
//   OrganisationRolesDTO
// } from '@deepwork/interfaces'
import {
  GetActorRoleDetailsDTO,
  GetCoworkersDto,
  GetOrgListDTO,
  IUser,
  OrganisationDetailsDTO,
  OrganisationRolesDTO,
} from 'libs/interfaces/src'
import { useQuery, UseQueryResult } from 'react-query'
import axios from '../api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'
import { useToast } from '@chakra-ui/react'

export const useOrganisationList = (search?: string): UseQueryResult<GetOrgListDTO[]> => {
  return useQuery(
    [QueriesKeysEnum.organisations, search],
    () => axios.get(`/orgs`, { params: { name: search } }),
    {
      enabled: !search || search.length > 0,
      retry: false,
    },
  )
}

export const useOrganisationDetails = (id: string): UseQueryResult<OrganisationDetailsDTO> => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.organisationDetails, id], () => axios.get(`/org/${id}`), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
  })
}

export const useOrganisationExperts = (id: string): UseQueryResult<GetCoworkersDto[]> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.organisationExperts, id],
    () => axios.get(`/org/${id}/experts`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useOrganisationRoles = (id: string): UseQueryResult<OrganisationRolesDTO[]> => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.organisationRoles, id], () => axios.get(`/org/${id}/roles`), {
    enabled: !!id,
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
  })
}

export const useOrganisationRoleDetails = (id: string): UseQueryResult<GetActorRoleDetailsDTO> => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.organisationRoles, id], () => axios.get(`/org/role/${id}`), {
    enabled: !!id,
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
  })
}

export const useExpertsForOrgRole = (roleId: string): UseQueryResult<IUser[]> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.expertsForOrgRole, roleId],
    () => axios.get(`org/role/${roleId}/experts`),
    {
      enabled: !!roleId,
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useGetOrgManagers = (id: string): UseQueryResult<IUser[]> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.organisationManagers, id],
    () => axios.get(`/org/${id}/managers`),
    {
      enabled: !!id,
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}
