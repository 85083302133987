import { useNavigate } from 'react-router-dom'
import { useProjectsList } from '../../../shared/queries/project'
import {
  useUserContextState,
  useUserContextStateDispatch,
} from '../../../shared/contexts/user-context-provider'
import { APP_PATHS } from '../../../paths'
import { AppContainer } from '../../../components/app-container'
import { Box, Button, Text } from '@chakra-ui/react'
// import { Budget, CircleArrowRight, DataBox, PrimaryCard } from '@deepwork/ui-components'
// import { formatDateRange } from '@deepwork/utils'
import { Budget, CircleArrowRight, DataBox, PrimaryCard } from 'libs/ui-components/src'
import { Home } from '../../home/index'
import { formatDateRange } from 'libs/utils/src'
import { CardListSkeleton } from '../../../components/loading-skeletons'

export const ProjectsList = () => {
  const navigate = useNavigate()
  const { data: projects, isLoading } = useProjectsList()
  const { user } = useUserContextState()
  const { onOpenLoginModal } = useUserContextStateDispatch()

  const handleNavigateNewProject = () => {
    if (user?.username) {
      navigate(APP_PATHS.newProject)
    } else {
      onOpenLoginModal()
    }
  }

  const handleNavigateToProjectDetails = (id: string) => () => {
    if (user?.username) {
      navigate(APP_PATHS.projectDetails.replace(':projectId', id))
    } else {
      onOpenLoginModal()
    }
  }

  return isLoading ? (
    <CardListSkeleton loading={isLoading} />
  ) : (
    <>
      <AppContainer.Content pt="13px">
        <Text variant="h1" color="white">
          Explore all projects
        </Text>
        <Box display="flex" justifyContent="space-between" mt="17px" mb="40px">
          <Box display="flex" flex="1 0 auto">
            {/*<Box mr='30px'>
                Industry: <Filter options={[{ label: 'Show all', value: 'showAll' }]} onSelect={() => undefined} />
              </Box>
              <Box>
                Start: <Filter options={[{ label: 'Anytime', value: 'anytime' }]} onSelect={() => undefined} />
              </Box>*/}
          </Box>
          <Box>
            <Button
              onClick={handleNavigateNewProject}
              variant="link"
              rightIcon={<CircleArrowRight />}
            >
              Create new project
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent={{ base: 'center', md: 'flex-start' }}>
          {projects?.map((item) =>
            user?.username ? (
              <a href={APP_PATHS.projectDetails.replace(':projectId', item.id)}>
                <PrimaryCard
                  key={item.id}
                  projectName={item.name}
                  onCardClick={() => {}}
                  organizationName={item.organization.name}
                  organizationImage={item.organization.imageUrl}
                  dateRange={formatDateRange(item.dateRange[0], item.dateRange[1])}
                  status={item.isApplicationOpen ? 'Team applications open' : 'Ongoing'}
                  tag={item.tag}
                  users={item.applicants?.map((item) => item.avatarUrl)}
                  boxProps={{
                    mb: '40px',
                    mr: { base: '0', sm: '10px' },
                  }}
                >
                  <DataBox label="Total Budget">
                    <Budget budget={item.totalBudget} />
                  </DataBox>
                </PrimaryCard>
              </a>
            ) : (
              <PrimaryCard
                key={item.id}
                projectName={item.name}
                onCardClick={handleNavigateToProjectDetails(item.id)}
                organizationName={item.organization.name}
                organizationImage={item.organization.imageUrl}
                dateRange={formatDateRange(item.dateRange[0], item.dateRange[1])}
                status={item.isApplicationOpen ? 'Team applications open' : 'Ongoing'}
                tag={item.tag}
                users={item.applicants?.map((item) => item.avatarUrl)}
                boxProps={{
                  mb: '40px',
                  mr: { base: '0', sm: '10px' },
                }}
              >
                <DataBox label="Total Budget">
                  <Budget budget={item.totalBudget} />
                </DataBox>
              </PrimaryCard>
            ),
          )}
        </Box>
      </AppContainer.Content>
      <Home />
    </>
  )
}
