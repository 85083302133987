import React from 'react'
import { AppContainer } from '../../components/app-container'
import { Outlet } from 'react-router-dom'

export const Projects = () => {
  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  )
}
