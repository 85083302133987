import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
// import { OverviewSidebar, ProfileBox, SkillBox } from '@deepwork/ui-components'
// import { IOrganisationRole, IProject } from '@deepwork/interfaces'
// import { makeFriendly } from '@deepwork/utils'
import { OverviewSidebar, ProfileBox, SkillBox } from 'libs/ui-components/src'
import { IOrganisationRole, IProject } from 'libs/interfaces/src'
import { makeFriendly } from 'libs/utils/src'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { ProjectsTab } from '../../../components/projects-tab'
import {
  EMPTY_COWORKERS_TAB_TEXT,
  EMPTY_COWORKERS_TAB_TEXT_OWNER,
  EMPTY_PROJECTS_TAB_TEXT,
  EMPTY_PROJECTS_TAB_TEXT_OWNER,
  EMPTY_SKILLS_TAB_TEXT,
  EMPTY_SKILLS_TAB_TEXT_OWNER,
} from '../../../shared/constants/empty-text'
import { CoworkersTab } from '../../../components/coworkers-tab'
import { APP_PATHS } from '../../../paths'
import {
  useOrganisationDetails,
  useOrganisationExperts,
  useOrganisationRoleDetails,
  useOrganisationRoles,
} from '../../../shared/queries/organisation'
import { useProjectsList } from '../../../shared/queries/project'
import { OrganisationSkillsTab } from '../../../components/organisation-skills-tab'
import { TaskSummaryModal } from '../../../components/modals/task-summary-modal'
import { useUserContextState } from '../../../shared/contexts/user-context-provider'
import { useInterestMutation } from '../../../shared/mutations/user-profile'
import { AppContainer } from '../../../components/app-container'

export const OrganisationDetails = () => {
  const params = useParams<{ organisationId: string }>()
  const { user } = useUserContextState()
  const { mutate: onUpdateInterest } = useInterestMutation()
  const { data: organisation, isLoading } = useOrganisationDetails(params.organisationId ?? '')
  const { data: organisationRoles } = useOrganisationRoles(params.organisationId ?? '')
  const { data: organisationExperts } = useOrganisationExperts(params.organisationId ?? '')
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isLoadingError,
  } = useProjectsList({
    orgId: params?.organisationId,
  })
  const {
    isOpen: isOpenTaskSummary,
    onClose: onCloseTaskSummary,
    onOpen: onOpenTaskSummary,
  } = useDisclosure()
  const [selectedRoleId, setSelectedRoleId] = React.useState<string | null>(null)
  const {
    data: roleDetails,
    isLoading: isRoleDetailsLoading,
    refetch: refetchRoleDetails,
  } = useOrganisationRoleDetails(selectedRoleId ?? '')

  const navigate = useNavigate()

  const handleOpenUserProfile = (username: string) => {
    navigate(APP_PATHS.userProfile.replace(':username', username))
  }

  const handleCreateProject = () => {
    navigate(APP_PATHS.newProject)
  }

  const handleOpenProjectDetails = (project: IProject) => {
    navigate(APP_PATHS.projectDetails.replace(':projectId', project.id))
  }

  const handleOpenTaskSummary = (skill: IOrganisationRole) => {
    onOpenTaskSummary()
    setSelectedRoleId(skill.id)
  }

  const handleOpenFeatureTaskSummary = (id: string) => () => {
    onOpenTaskSummary()
    setSelectedRoleId(id)
  }

  const handleEditOrganisation = () => {
    if (params.organisationId) {
      navigate(APP_PATHS.editOrganisation.replace(':organisationId', params.organisationId))
    }
  }

  const handleCloseTaskSummary = () => {
    onCloseTaskSummary()
    setSelectedRoleId(null)
  }

  const handleInterestedClicked = (taskId: string, isChecked: boolean) => {
    onUpdateInterest(
      {
        taskId,
        isChecked,
      },
      {
        onSuccess: () => {
          refetchRoleDetails()
        },
      },
    )
  }

  useEffect(() => {
    if (isLoadingError) {
      navigate(APP_PATHS.organisations)
    }
  }, [isLoadingError, history])

  return (
    <AppContainer.Content>
      <Box pt="50px" display="flex" flexDirection={{ base: 'column', md: 'row' }}>
        <OverviewSidebar
          loading={isLoading}
          title={organisation?.name ?? ''}
          description={organisation?.description}
          avatar={organisation?.imageUrl}
        >
          {organisation?.admin.username === user.username && (
            <Box>
              <Button mb={'50px'} onClick={handleEditOrganisation}>
                Edit
              </Button>
            </Box>
          )}
          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            <Button
              as="a"
              href={organisation?.website}
              target="_blank"
              variant="link"
              rightIcon={<ExternalLinkIcon />}
              mb={'30px'}
            >
              Website
            </Button>
            <Button
              as="a"
              href={organisation?.knowledgeBase}
              target="_blank"
              variant="link"
              rightIcon={<ExternalLinkIcon />}
              mb={'30px'}
            >
              Knowledge base
            </Button>
            <Button
              as="a"
              href={organisation?.community}
              target="_blank"
              variant="link"
              rightIcon={<ExternalLinkIcon />}
              mb={'50px'}
            >
              Community
            </Button>
          </Box>
          {!!organisationRoles?.length && (
            <Box mb={'50px'}>
              <Text variant={'h3'} color={'white'} mb={'18px'}>
                Skills
              </Text>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                {organisationRoles?.slice(0, 5).map((item) => (
                  <SkillBox
                    isExpert
                    key={item.id}
                    icon={organisation?.imageUrl}
                    label={item.title}
                    onClick={handleOpenFeatureTaskSummary(item.id)}
                    boxProps={{
                      __css: {
                        ':nth-of-type(2n + 1)': {
                          mr: { base: '15px', md: '15px' },
                        },
                        ':nth-of-type(2n + 2)': {
                          mr: { base: '15px', md: '0' },
                        },
                      },
                      mb: '18px',
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}
          {organisation?.admin && (
            <Box mt={'50px'}>
              <Text variant={'h3'} color={'white'} mb={'18px'}>
                Admin
              </Text>
              <ProfileBox
                onClick={() => handleOpenUserProfile(organisation.admin.username)}
                name={organisation.admin.username}
                avatarUrl={organisation.admin.avatarUrl}
              />
            </Box>
          )}
          {!!organisation?.managers.length && (
            <Box mt="50px">
              <Text variant={'h3'} color={'white'} mb={'18px'}>
                Managers
              </Text>
              {organisation?.managers.map((manager, index) => (
                <ProfileBox
                  key={manager.id}
                  name={manager.username}
                  avatarUrl={manager.avatarUrl}
                  onClick={handleOpenUserProfile}
                  boxProps={{
                    mb: organisation?.managers.length !== index + 1 ? '12px' : 0,
                  }}
                />
              ))}
            </Box>
          )}
        </OverviewSidebar>
        <Box width="100%" ml={{ base: '0', md: '30px' }} mt={{ base: '30px', md: '0' }}>
          <Tabs>
            <TabList>
              <Tab>Projects {makeFriendly(projects?.length)}</Tab>
              <Tab>Skills {makeFriendly(organisationRoles?.length)}</Tab>
              <Tab>Members {makeFriendly(organisationExperts?.length)}</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <ProjectsTab
                  loading={isProjectsLoading}
                  projects={projects}
                  onCreateProject={handleCreateProject}
                  onProjectClick={handleOpenProjectDetails}
                  canCreateProject={organisation?.canCreateProject}
                  emptyProjectsText={
                    organisation?.canCreateProject
                      ? EMPTY_PROJECTS_TAB_TEXT_OWNER
                      : EMPTY_PROJECTS_TAB_TEXT.replace('{owner}', organisation?.name ?? '')
                  }
                />
              </TabPanel>
              <TabPanel>
                <OrganisationSkillsTab
                  onShowProgression={handleOpenTaskSummary}
                  emptyText={
                    organisation?.canCreateProject
                      ? EMPTY_SKILLS_TAB_TEXT_OWNER
                      : EMPTY_SKILLS_TAB_TEXT.replace('{owner}', organisation?.name ?? '')
                  }
                  skills={organisationRoles}
                />
              </TabPanel>
              <TabPanel>
                <CoworkersTab
                  coworkers={organisationExperts}
                  onCoworkerClick={handleOpenUserProfile}
                  emptyText={
                    organisation?.canCreateProject
                      ? EMPTY_COWORKERS_TAB_TEXT_OWNER
                      : EMPTY_COWORKERS_TAB_TEXT.replace('{owner}', organisation?.name ?? '')
                  }
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      {isOpenTaskSummary && !!roleDetails && !isRoleDetailsLoading && (
        <TaskSummaryModal
          isOpen={isOpenTaskSummary}
          onCloseClicked={handleCloseTaskSummary}
          org={roleDetails.org}
          tasks={roleDetails.tasks}
          title={roleDetails.title}
          onInterestedClicked={handleInterestedClicked}
        />
      )}
    </AppContainer.Content>
  )
}
