// import React, { ChangeEvent } from 'react'
import React from 'react'
import {
  Button,
  // Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FileUpload } from '../../form/file-upload/file-upload'
import { Form, Formik } from 'formik'
import { FormikInputControl } from '../../form/formik-wrappers'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'

interface FormValues {
  username: string
  email: string
  password: string
  website: string
  photo: any
  bio: string
  agreed: boolean
  wallet: string
  discordId: string
}
interface Props {
  isOpen: boolean
  onClose: () => void
  onSignUpClicked: (values: FormValues) => void
  customValidationSchema?: Yup.ObjectSchema<ObjectShape>
}
export const RegisterModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSignUpClicked,
  customValidationSchema,
}) => {
  const handleSubmitForm = (values: FormValues) => {
    onSignUpClicked(values)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New to Deep Skills?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text variant="h6" color="white" mb="28px">
            Create an account for free, start working with various DAOs on amazing projects, and get
            paid and attested for the work you do!
          </Text>
          <Formik
            enableReinitialize
            initialValues={{
              username: '',
              email: '',
              password: '',
              website: '',
              photo: '',
              bio: '',
              wallet: '',
              discordId: '',
              agreed: false,
            }}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={Yup.object()
              .shape({
                username: Yup.string().required('Name is required'),
                email: Yup.string().email('Invalid email').required('Email is required'),
                password: Yup.string()
                  .min(8, 'Password must be at least 8 characters')
                  .required('Password is required'),
                website: Yup.string().url('Invalid URL'),
                bio: Yup.string().max(140, 'Bio must be 140 characters or less'),
              })
              .concat(customValidationSchema || Yup.object())}
            onSubmit={handleSubmitForm}
          >
            {({ values, setFieldValue }) => (
              <Form noValidate>
                <FormikInputControl
                  label="Name"
                  name="username"
                  placeholder="John Doe"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="johndoe@example.com"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Password"
                  name="password"
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  type="password"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Link to work"
                  name="website"
                  placeholder="https://www.behance.net"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Profile bio"
                  placeholder="Visual artist, like working on design."
                  name="bio"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Ethereum Address"
                  placeholder="0x..."
                  name="wallet"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Discord Profile Id"
                  placeholder="andrej#1111"
                  name="discordId"
                  styleProps={{ mb: '21px' }}
                />
                <FormControl id="photo" mb="21px">
                  <FormLabel>Photo</FormLabel>
                  <FileUpload name="photo" onChange={setFieldValue} />
                </FormControl>

                {/*                <FormControl mb="21px">
                  <Text variant="h6" mb="12px">
                    Terms and conditions
                  </Text>
                  <Checkbox
                    name="agreed"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('agreed', event.target.checked)
                    }
                  >
                    <Text data-testid="agreed-label" variant="h6" as="span">
                      I agree to the Deep Skills terms and conditions
                    </Text>
                  </Checkbox>
                </FormControl>
*/}
                <Button
                  disabled={!values.wallet && (!values.email || !values.password)}
                  type="submit"
                >
                  Sign up
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
