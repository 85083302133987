import { IProject } from '../Projects'
import { IRoleTask } from '../Task'
import { OrganisationDetailsDTO } from './Organisation'

export interface GetActorRolesDTO {
  id: string
  title: string
  isExpert: boolean
  organisationLogoUrl: string
  organisationName: string
  tasksDone: number
  tasksTotal: number
}
export interface GetActorRoleDetailsDTO {
  tasks: IRoleTask[]
  id: string
  title: string
  isExpert: boolean
  description: string
  experts: string[]
  org: Pick<OrganisationDetailsDTO, 'id' | 'name' | 'imageUrl'>
}

export interface GetApplicationRoleDetails {
  project: Pick<IProject, 'id' | 'name'>
  role: {
    id: string
    title: string
    description: string
    dates: string
    payment: number[]
    applicationDeadline: string
  }
  tasks: {
    id: string
    title: string
    description: string
    count?: number
    status?: TaskStatesEnum
    interested: boolean
  }[]
}

export const enum TaskStatesEnum {
  complete = 'complete',
  interested = 'interested',
  shadowed = 'shadowed',
}
