import React, { FC } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Box, Button } from '@chakra-ui/react'
import { FormikInputControl, FormikTextareaControl } from '../../form/formik-wrappers'
import { v4 as uuidv4 } from 'uuid'
import { Select } from 'chakra-react-select'

export interface ITaskFormProps {
  id: string
  title: string
  timeValue?: number
  estimatedDurationUnit?: { value: string; label: string }
  description: string
}

interface Props {
  task?: ITaskFormProps
  onSubmit: (values: ITaskFormProps) => void
  onCancel: () => void
  disabled?: boolean
}

const INITIAL_TASK_VALUES = {
  id: '',
  title: '',
  timeValue: 0,
  estimatedDurationUnit: { value: 'hours', label: 'Hours' },
  description: '',
}

export const TaskForm: FC<Props> = ({ task, onSubmit, onCancel, disabled }) => {
  const initialValues = task ? { ...task } : INITIAL_TASK_VALUES
  const handleCancel = () => {
    onCancel()
  }

  const handleSubmit = (values: ITaskFormProps) => {
    const task: ITaskFormProps = {
      ...values,
      id: values.id === '' ? uuidv4() : values.id,
    }
    onSubmit(task)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        title: Yup.string().required('Title is required'),
        timeValue: Yup.number()
          .min(1, 'Must be greater than 0')
          .required('Estimated duration is required'),
        description: Yup.string().required('Description is required'),
      })}
    >
      {({ submitForm, values, setFieldValue }) => (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ base: 'column', md: 'row' }}
            mb="32px"
          >
            <FormikInputControl
              label="Title"
              type="text"
              name="title"
              placeholder="Prepare Miro Board..."
              styleProps={{
                mr: { base: '0', md: '30px' },
                mb: { base: '32px', md: 0 },
                width: { base: '100%', md: 'auto' },
                flex: { base: 'unset', md: '0.5 0 auto' },
              }}
              disabled={disabled}
            />
            <Box display="flex" alignItems="center" flex={{ base: 'unset', md: '0.5 0 auto' }}>
              <FormikInputControl
                label="Estimated duration"
                type="number"
                name="timeValue"
                placeholder="15"
                disabled={disabled}
                InputRightAddon={
                  <Box width={{ base: '100%', md: '35%' }} ml="20px">
                    <Select
                      name="estimatedDurationUnit"
                      value={values.estimatedDurationUnit}
                      onChange={(value) => setFieldValue('estimatedDurationUnit', value)}
                      options={[
                        { value: 'hours', label: 'Hours' },
                        { value: 'minutes', label: 'Minutes' },
                      ]}
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={disabled}
                      components={{
                        SingleValue: ({ innerProps, data }) => (
                          <Box bg={'gray.24'} {...innerProps}>
                            <span>{data.label.split(' ')[0]}</span>
                          </Box>
                        ),
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                    />
                  </Box>
                }
              />
            </Box>
          </Box>
          <FormikInputControl
            label="Description"
            type="text"
            name="description"
            placeholder="Enter task description or task document URL"
            styleProps={{
              mr: '30px',
              display: { base: 'none', md: 'flex' },
              flexDirection: 'column',
            }}
            disabled={disabled}
          />
          <FormikTextareaControl
            label="Description"
            name="description"
            size={'sm'}
            placeholder="Enter task description or task document URL"
            styleProps={{
              mr: '30px',
              display: { base: 'flex', md: 'none' },
              flexDirection: 'column',
            }}
            disabled={disabled}
          />
          <Box display="flex" mt="32px" justifyContent={'space-between'} alignItems={'center'}>
            <Button variant="link" size="sm" color="warning" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={submitForm} disabled={disabled} variant="link" size="sm">
              Save
            </Button>
          </Box>
        </>
      )}
    </Formik>
  )
}
