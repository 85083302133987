import React from 'react'
import { Avatar, AvatarProps, Box, BoxProps, Text, Tooltip } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'

interface Props {
  variant?: 'compact' | 'wide'
  name: string
  jobTitle?: string
  color?: string
  avatarUrl?: string
  tooltipText?: string
  size?: AvatarProps['size']
  onClick?: (username: string) => void
  boxProps?: BoxProps
}
export const ProfileBox: React.FC<Props> = ({
  name,
  avatarUrl,
  tooltipText,
  jobTitle,
  variant = 'wide',
  color = 'primary',
  size = 'sm',
  onClick,
  boxProps,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(name)
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={handleClick}
      cursor={onClick ? 'pointer' : 'default'}
      pr="15px"
      {...boxProps}
    >
      <Avatar size={size} borderRadius="full" src={avatarUrl} mr="16px" />
      <Box
        display="flex"
        flexDirection={variant === 'compact' ? 'column' : 'row'}
        alignItems={variant === 'compact' ? 'flex-start' : 'center'}
      >
        <Box display="flex">
          <Text as="span" color={color} variant={'h6'} aria-label={name} isTruncated maxW="180px">
            {name}
          </Text>
          {!!tooltipText && (
            <Tooltip label={tooltipText} fontSize="sm">
              <InfoIcon color="gray.79" />
            </Tooltip>
          )}
        </Box>
        {!!jobTitle && (
          <Text
            as="span"
            variant="h4"
            color="gray.79"
            fontWeight={400}
            ml={variant === 'compact' ? 0 : '8px'}
            mt={variant === 'compact' ? '7px' : 0}
            aria-label={jobTitle}
            textTransform="capitalize"
            isTruncated
            maxW={{ base: '90%', md: '200px' }}
          >
            {jobTitle}
          </Text>
        )}
      </Box>
    </Box>
  )
}
