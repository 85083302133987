import React, { useEffect } from 'react'
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react'
import * as Yup from 'yup'
import { Form, Formik, FormikValues } from 'formik'
import { useProjectFlowDispatch, useProjectFlowState } from '../../ProjectFlowContextProvider'
// import { Autocomplete, FormikInputControl, FormikTextareaControl, RoundedBox, Tag } from '@deepwork/ui-components'
// import { IOrganisation, ITag, IUser } from '@deepwork/interfaces'
// import { pluralise } from '@deepwork/utils'
import {
  Autocomplete,
  FormikInputControl,
  FormikTextareaControl,
  RoundedBox,
  Tag,
} from 'libs/ui-components/src'
import { IOrganisation, ITag, IUser } from 'libs/interfaces/src'
import { pluralise } from 'libs/utils/src'
import { CreatableSelect } from 'chakra-react-select'
import { useOrgListForManagers, useProjectTagList } from '../../../../../shared/queries/project'
import { useUsers } from '../../../../../shared/queries/users'

interface Props {
  onNext: () => void
}
export const DetailsStep: React.FC<Props> = ({ onNext }) => {
  const { details: detailsValues } = useProjectFlowState()
  const { onUpdateDetails } = useProjectFlowDispatch()
  const [organisationList, setOrganisationList] = React.useState<
    { value: string; label: string }[]
  >([])
  const [mappedTags, setTags] = React.useState<{ value: string; label: string }[]>([])
  const [stakeholders, setStakeholders] = React.useState<{ value: string; label: string }[]>([])
  const { data: organisations } = useOrgListForManagers()
  const { data: tags } = useProjectTagList()
  const { data: users } = useUsers()

  useEffect(() => {
    if (organisations) {
      setOrganisationList(
        organisations.slice(0, 20).map((org: IOrganisation) => ({
          label: org.name,
          value: org.id,
        })),
      )
    }
  }, [organisations])

  useEffect(() => {
    if (tags) {
      setTags(
        tags.slice(0, 20).map((tag: ITag) => ({
          label: `${tag.name} ${tag.count}`,
          value: tag.name,
        })),
      )
    }
  }, [tags])

  useEffect(() => {
    if (users) {
      setStakeholders(
        users.slice(0, 20).map((user: IUser) => ({
          label: user.username,
          value: user.id,
        })),
      )
    }
  }, [users])

  const handleSubmitDetails = (values: FormikValues) => {
    onUpdateDetails(values)
    onNext()
  }

  // @ts-ignore
  return (
    <Box>
      <Text variant="h2" color="white" mb="25px">
        Enter project details
      </Text>
      <Formik
        enableReinitialize
        initialValues={detailsValues}
        onSubmit={handleSubmitDetails}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is required'),
          description: Yup.string().required('Description is required'),
          documentUrl: Yup.string().url('Invalid URL'),
          organisation: Yup.mixed().nullable().required('Organisation is required'),
          tag: Yup.mixed().nullable().required('Tags are required'),
          stakeholder: Yup.mixed().nullable().required('Stakeholder are required'),
        })}
      >
        {({ setFieldValue, errors, values, touched }) => (
          <Form id="detailsForm" noValidate>
            <RoundedBox mb="25px">
              <FormikInputControl
                label="Give your project a name and a description"
                name="name"
                placeholder="Name"
              />
              <FormikTextareaControl
                name="description"
                placeholder="Description"
                styleProps={{
                  mb: '32px',
                }}
              />
              <FormikInputControl
                label="Add an external document with project details"
                name="documentUrl"
                placeholder="Document URL"
                styleProps={{
                  mb: '32px',
                }}
              />
            </RoundedBox>
            <RoundedBox mb="43px" display="flex" flexDirection={{ base: 'column', md: 'row' }}>
              <FormControl
                id="linkToOrg"
                isInvalid={!!errors.organisation && touched.organisation}
                mr={{ base: 0, md: '30px' }}
                mb={{ base: '30px', md: 0 }}
              >
                <FormLabel>Link it to an organisation</FormLabel>
                <Autocomplete
                  data-testid="organisation-selection"
                  options={organisationList}
                  value={values.organisation}
                  onChange={(data: any) => setFieldValue('organisation', data)}
                  placeholder={'Deep Work'}
                />
                <FormErrorMessage>{errors.organisation}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="tag"
                isInvalid={!!errors.tag && touched.tag}
                mr={{ base: 0, md: '30px' }}
                mb={{ base: '30px', md: 0 }}
              >
                <FormLabel>Add a tag for the industry</FormLabel>
                <CreatableSelect
                  data-testid="tag-selection"
                  options={mappedTags}
                  value={values.tag}
                  onChange={(data) => setFieldValue('tag', data)}
                  placeholder={'Design'}
                  blurInputOnSelect
                  components={{
                    DropdownIndicator: null,
                    SingleValue: ({ innerProps, data }) => (
                      <Box {...innerProps}>
                        <span>{data.label.split(' ')[0]}</span>
                      </Box>
                    ),
                    Option: ({ innerProps, data }, index) => { // eslint-disable-line
                      const [tag, count] = data.label.split(' ')
                      return (
                        // @ts-ignore
                        <Box
                          {...innerProps}
                          cursor="pointer"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={'12px 14px 15px 16px'}
                          borderTop={'1px solid #1D1E20'}
                          __css={{ '&:first-of-type': { borderTop: 'none' } }}
                        >
                          <Tag value={tag} selected />
                          <Text>
                            {+count > 500 ? '500+' : count} {pluralise('project', +count)}
                          </Text>
                        </Box>
                      )
                    },
                  }}
                />
                <FormErrorMessage>{errors.tag}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="stakeholder"
                isInvalid={!!errors.stakeholder && touched.stakeholder}
                mr={{ base: 0, md: '30px' }}
                mb={{ base: '30px', md: 0 }}
              >
                <FormLabel>Add a stakeholder</FormLabel>
                <Autocomplete
                  data-testid="stakeholder-selection"
                  options={stakeholders}
                  value={values.stakeholder}
                  onChange={(data: any) => setFieldValue('stakeholder', data)}
                  placeholder={'Deep Work'}
                />
                <FormErrorMessage>{errors.stakeholder}</FormErrorMessage>
              </FormControl>
            </RoundedBox>
          </Form>
        )}
      </Formik>
      <Button type="submit" form="detailsForm" data-testid="next-btn">
        Next
      </Button>
    </Box>
  )
}
