import React from 'react'
import { RoundedBox } from '../../data-display/rounded-box/rounded-box'
import { Text } from '@chakra-ui/react'
// import { ITask } from '@deepwork/interfaces'
// import { pluralise } from '@deepwork/utils'
import { ITask } from 'libs/interfaces/src'
import { pluralise } from 'libs/utils/src'
import { TasksList } from './tasks-list'

export interface TaskSetProps {
  isCreating?: boolean
  isReadOnly?: boolean
  tasks: ITask[]
  onChange: (taskSet: ITask[]) => void
}

export const CreateTaskSet: React.FC<TaskSetProps> = ({
  isReadOnly,
  isCreating,
  onChange,
  tasks = [],
}) => {
  const handleAddTask = (task: ITask) => {
    const foundTask = tasks.find((t) => t.id === task.id)
    if (foundTask) {
      const updatedTasks = tasks.map((item) => (item.id === task.id ? { ...task } : item))
      onChange(updatedTasks)
      return
    }

    const updatedTasks = [...tasks, task]
    onChange(updatedTasks)
  }

  const handleDeleteTask = (id: string) => {
    const updatedTasks = tasks.filter((item) => item.id !== id)
    onChange(updatedTasks)
  }

  return (
    <RoundedBox p="32px 25px" bg="gray.24" data-testid={'create-tasks'}>
      {isCreating || tasks.length ? (
        <>
          <TasksList
            isReadOnly={isReadOnly}
            onAddTask={handleAddTask}
            onDeleteTask={handleDeleteTask}
            tasks={tasks}
          />
          <Text mt="17px" variant="small" color="gray.58">
            {tasks.length} {pluralise('task', tasks.length)}
          </Text>
        </>
      ) : (
        <Text variant="h6" textAlign="center">
          Select a role or create one to view/edit tasks within them
        </Text>
      )}
    </RoundedBox>
  )
}
