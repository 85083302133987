import { Box, Button, Text } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect } from 'react'
// import { Budget, ComplexSlider, DataBox, RoundedBox } from '@deepwork/ui-components'
// import { getPercentage } from '@deepwork/utils'
// import { IRoleDetails } from '@deepwork/interfaces'
import { Budget, ComplexSlider, DataBox, RoundedBox } from 'libs/ui-components/src'
import { getPercentage } from 'libs/utils/src'
import { IRoleDetails } from 'libs/interfaces/src'
import { useParams } from 'react-router-dom'
import {
  useConfirmApplication,
  useConfirmApplicationDispatch,
} from '../../ConfirmApplicationProvider'

interface Props {
  onNext: () => void
  onBack: () => void
  roleDetails: IRoleDetails
}
export const PaymentStep: FC<Props> = ({ onNext, onBack, roleDetails }) => {
  const { paymentStep } = useConfirmApplication()
  const { onUpdatePaymentStep } = useConfirmApplicationDispatch()
  const params = useParams<{ roleId: string; projectId: string; username: string }>()

  const handleChangePaymentAmount = useCallback(
    (value: number[]) => {
      const yourPayment = getPercentage(roleDetails?.budget, value[0])
      const yourBonus = getPercentage(roleDetails?.mentoringBonus, value[1])
      const contributorPayment = (roleDetails?.budget ?? 0) - yourPayment
      onUpdatePaymentStep({
        yourPayment: Math.floor(yourPayment),
        contributorPayment: Math.floor(contributorPayment),
        yourPercentage: value[0],
        contributorPercentage: value[1],
        yourBonus: Math.floor(yourBonus),
      })
    },
    [onUpdatePaymentStep, roleDetails?.budget],
  )

  useEffect(() => {
    if (!paymentStep.contributorPayment && paymentStep.contributorPayment !== 0) {
      handleChangePaymentAmount([50, 50])
    }
  }, [handleChangePaymentAmount, paymentStep.contributorPayment])

  return (
    <>
      <Box mb="25px">
        <Text variant="h2" color="white" mb="14px">
          Split the payment
        </Text>
        <Text color="gray.79">
          Decide how you would like to split the payment for this project. You will not be able to
          change this later.
        </Text>
      </Box>
      <RoundedBox borderRadius="12px 12px 0 0">
        <ComplexSlider
          leftSideLabel="You"
          rightSideLabel={params.username ?? ''}
          valueLabel="%"
          value={[paymentStep.yourPercentage, paymentStep.contributorPercentage]}
          onChange={handleChangePaymentAmount}
        />
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <DataBox label="Your base payment">
              <Budget budget={paymentStep.yourPayment ?? 0} />
            </DataBox>
            {roleDetails && (
              <DataBox label="Recruitment bonus" boxProps={{ ml: '35px' }}>
                <Text as="span" fontWeight="400" fontSize="14px" lineHeight="17.71px">
                  + <Budget budget={paymentStep.yourBonus} />
                </Text>
              </DataBox>
            )}
          </Box>
          <DataBox label={`${params.username}'s payment`}>
            <Budget budget={paymentStep.contributorPayment ?? 0} />
          </DataBox>
        </Box>
      </RoundedBox>
      <RoundedBox bg="gray.24" borderRadius="0 0 12px 12px">
        <DataBox label="Your total payment">
          <Budget budget={(paymentStep.yourPayment ?? 0) + (paymentStep.yourBonus ?? 0)} />
        </DataBox>
      </RoundedBox>
      <Box mt="43px">
        <Button variant="link" mr="40px" onClick={onBack}>
          Back
        </Button>
        <Button onClick={onNext}>Next</Button>
      </Box>
    </>
  )
}
