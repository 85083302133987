import { useQuery, UseQueryResult } from 'react-query'
// import { IRoleDetails } from '@deepwork/interfaces'
import { IRoleDetails } from 'libs/interfaces/src'
import { QueriesKeysEnum } from './queries-keys-enum'
import axios from '../api/setup'
import { useToast } from '@chakra-ui/react'

export const useRoleDetails = (roleId: string | null): UseQueryResult<IRoleDetails> => {
  const toast = useToast()

  return useQuery([QueriesKeysEnum.roleDetails, roleId], () => axios.get(`/actor/role/${roleId}`), {
    enabled: !!roleId,
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
  })
}
export const useAppliedRoleDetails = (
  projectId: string,
  roleId: string,
  username: string,
): UseQueryResult<IRoleDetails> => {
  const toast = useToast()

  return useQuery(
    [QueriesKeysEnum.roleDetails, roleId],
    () => axios.get(`/project/${projectId}/role/${roleId}/actor/${username}/tasks/applied`),
    {
      enabled: !!roleId && !!username,
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}
