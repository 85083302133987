import { AppContainer } from '../../components/app-container'
import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
// import {
//   ChooseNewPassword,
//   EditProfileModal,
//   OverviewSidebar,
//   ProfileBox,
//   ProjectSummary,
//   RoundedBox,
//   SetNewPasswordProps,
//   SkillBox
// } from '@deepwork/ui-components'
// import { IProfileSkill, IProject, IUserProfileFormValues } from '@deepwork/interfaces'
// import { makeFriendly } from '@deepwork/utils'
import {
  ChooseNewPassword,
  EditProfileModal,
  OverviewSidebar,
  ProfileBox,
  ProjectSummary,
  RoundedBox,
  SetNewPasswordProps,
  SkillBox,
} from 'libs/ui-components/src'
import { IProfileSkill, IProject, IUserProfileFormValues } from 'libs/interfaces/src'
import { makeFriendly } from 'libs/utils/src'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useUserContextState,
  useUserContextStateDispatch,
} from '../../shared/contexts/user-context-provider'
import { ProjectsTab } from '../../components/projects-tab'
import { APP_PATHS } from '../../paths'
import { SkillsTab } from '../../components/skills-tab'
import {
  EMPTY_COWORKERS_TAB_TEXT,
  EMPTY_COWORKERS_TAB_TEXT_OWNER,
  EMPTY_PROJECTS_TAB_TEXT,
  EMPTY_PROJECTS_TAB_TEXT_OWNER,
  EMPTY_SKILLS_TAB_TEXT,
  EMPTY_SKILLS_TAB_TEXT_OWNER,
} from '../../shared/constants/empty-text'
import { CoworkersTab } from '../../components/coworkers-tab'
import { validateUsername } from '../../shared/utils/validation-schemas/unique-username'
import { EmailIcon, LinkIcon } from '@chakra-ui/icons'
import {
  useCompleteProjectPreview,
  useUserProfile,
  useUserRoleDetails,
} from '../../shared/queries/useUserProfile'
import { TaskSummaryModal } from '../../components/modals/task-summary-modal'
import { useInterestMutation, useUserProfileMutation } from '../../shared/mutations/user-profile'
import { useChangePasswordMutation } from '../../shared/mutations/user'
import { useQueryClient } from 'react-query'
import { QueriesKeysEnum } from '../../shared/queries/queries-keys-enum'

export const UserProfile = () => {
  const navigate = useNavigate()
  const params = useParams<{ username: string }>()
  const { user } = useUserContextState()
  const { updateUserContext } = useUserContextStateDispatch()
  const queryCache = useQueryClient()
  const { profile, projects, coworkers, roles, isLoading, refetchProfile } = useUserProfile(
    params?.username ?? '',
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: onEditUserProfile } = useUserProfileMutation()
  const { mutate: onUpdateInterest } = useInterestMutation()
  const {
    isOpen: isOpenChangePassword,
    onOpen: onOpenChangePassword,
    onClose: onCloseChangePassword,
  } = useDisclosure()
  const {
    isOpen: isProjectSummaryOpen,
    onOpen: onOpenProjectSummary,
    onClose: onCloseProjectSummary,
  } = useDisclosure()
  const {
    isOpen: isOpenTaskSummary,
    onClose: onCloseTaskSummary,
    onOpen: onOpenTaskSummary,
  } = useDisclosure()
  const [selectedRoleId, setSelectedRoleId] = React.useState<string | null>(null)
  const [selectedProjectId, setSelectedProjectId] = React.useState<string | null>(null)
  const queryClient = useQueryClient()
  const { data: roleDetails, isLoading: isRoleDetailsLoading } = useUserRoleDetails(
    selectedRoleId ?? '',
    profile?.username ?? '',
  )
  const { data: projectDetails, isLoading: isProjectDetailsLoading } = useCompleteProjectPreview(
    profile?.username ?? '',
    selectedProjectId ?? '',
  )
  const { mutate: onChangePassword } = useChangePasswordMutation()
  const isCurrentUser = user?.username === params.username
  const groupedUserRoles: { [organisation: string]: IProfileSkill[] } | undefined = useMemo(
    () =>
      roles?.reduce(
        (acc, role) => ({
          ...acc,
          [role.organisationName]: acc[role.organisationName]
            ? [...acc[role.organisationName], role]
            : [role],
        }),
        {} as { [organisation: string]: IProfileSkill[] },
      ),
    [roles],
  )

  const handleCreateProject = () => {
    navigate(APP_PATHS.newProject)
  }

  const handleOpenProjectDetails = (project: IProject) => {
    if (project.done) {
      setSelectedProjectId(project.id)
      onOpenProjectSummary()
      return
    }
    navigate(APP_PATHS.projectDetails.replace(':projectId', project.id))
  }

  const handleOpenUserProfile = (username: string) => {
    navigate(APP_PATHS.userProfile.replace(':username', username))
  }

  const handleEditProfile = async (values: IUserProfileFormValues) => {
    const { photo, ...rest } = values
    onEditUserProfile(
      { ...rest, image: photo },
      {
        onSuccess: () => {
          onClose()
          updateUserContext({ type: 'update', payload: { user: { ...user, ...rest } } })
          queryCache.invalidateQueries([QueriesKeysEnum.user, rest.username])
          navigate(APP_PATHS.userProfile.replace(':username', rest.username))
          if (profile) {
            refetchProfile()
          }
        },
        onSettled: () => {
          onClose()
        },
      },
    )
  }

  const handleChangePassword = (values: Partial<SetNewPasswordProps>) => {
    if (values.password && values.currentPassword) {
      onChangePassword(
        { oldPassword: values.currentPassword, newPassword: values.password },
        {
          onSettled: () => {
            onCloseChangePassword()
          },
        },
      )
    }
  }

  const handleCreateOrg = () => {
    navigate(APP_PATHS.newOrganisation)
  }

  const handleOpenTaskSummary = (id: string) => () => {
    setSelectedRoleId(id)
    onOpenTaskSummary()
  }

  const handleOpenSkillSummary = (skill: IProfileSkill) => {
    handleOpenTaskSummary(skill.id)()
  }

  const handleCloseTaskSummary = () => {
    onCloseTaskSummary()
    setSelectedRoleId(null)
  }

  const handleInterestedClicked = (taskId: string, isChecked: boolean) => {
    onUpdateInterest(
      { taskId, isChecked },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueriesKeysEnum.userRoleDetails])
        },
      },
    )
  }

  const handleOpenProjectReview = (projectId: string) => (roleId: string, isExpert: boolean) => {
    navigate(
      isExpert
        ? APP_PATHS.projectLeadFeedback.replace(':projectId', projectId).replace(':roleId', roleId)
        : APP_PATHS.projectContributorFeedback
            .replace(':projectId', projectId)
            .replace(':roleId', roleId),
    )
  }

  return (
    <>
      <AppContainer>
        <AppContainer.Content>
          <Box display="flex" flexDirection={{ base: 'column', md: 'row' }}>
            <OverviewSidebar
              loading={isLoading}
              title={profile?.username}
              avatar={profile?.avatarUrl}
              extraChildren={
                profile?.id === user?.id &&
                user?.createOrgEligible && (
                  <RoundedBox mt="10px" p={{ base: '24px 26px' }}>
                    <Button variant="link" onClick={handleCreateOrg}>
                      Create a new organisation
                    </Button>
                  </RoundedBox>
                )
              }
            >
              {profile?.bio && <Text color="white">{profile.bio}</Text>}
              <Box mt={'18px'} mb={'18px'}>
                {profile?.website && (
                  <Button
                    as="a"
                    href={profile.website}
                    target="_blank"
                    leftIcon={<LinkIcon />}
                    variant={'link'}
                    fontSize="14px"
                    lineHeight="17.71px"
                    fontWeight="450"
                    mb="6px"
                  >
                    {profile.website}
                  </Button>
                )}
                {profile?.email && (
                  <Button
                    as="a"
                    href={`mailto:${profile.email}`}
                    leftIcon={<EmailIcon />}
                    variant={'link'}
                    fontSize="14px"
                    lineHeight="17.71px"
                    fontWeight="450"
                  >
                    {profile?.email}
                  </Button>
                )}
              </Box>
              {isCurrentUser && (
                <Box>
                  <Button mb={'50px'} size="sm" onClick={onOpen} data-testid={'edit-btn'}>
                    Edit
                  </Button>
                </Box>
              )}
              {!!roles?.length && (
                <Box mb={'50px'}>
                  <Text as="h3" variant="h3" color={'white'} mb={'18px'}>
                    Featured skills
                  </Text>
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    {roles?.slice(0, 3).map((item) => (
                      <SkillBox
                        key={item.id}
                        isExpert={item.isExpert}
                        data={{ total: item.tasksTotal, done: item.tasksDone }}
                        icon={item.organisationLogoUrl}
                        label={item.title}
                        onClick={handleOpenTaskSummary(item.id)}
                        boxProps={{
                          __css: {
                            ':nth-of-type(2n + 1)': {
                              mr: { base: '30px', md: '30px' },
                            },
                            ':nth-of-type(2n + 2)': {
                              mr: { base: '30px', md: '0' },
                            },
                          },
                          mb: '18px',
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              )}
              {!!coworkers?.length && (
                <Box>
                  <Text as="h3" variant="h3" color={'white'} mb={'18px'}>
                    Coworkers
                  </Text>
                  {coworkers?.slice(0, 3).map((item) => (
                    <ProfileBox
                      key={item.id}
                      avatarUrl={item.avatarUrl}
                      name={item.username}
                      onClick={() => handleOpenUserProfile(item.username)}
                      boxProps={{ mb: '12px' }}
                    />
                  ))}
                </Box>
              )}
            </OverviewSidebar>
            <Box width="100%" ml={{ base: '0', md: '30px' }} mt={{ base: '30px', md: '0' }}>
              <Tabs>
                <TabList>
                  <Tab>Projects {makeFriendly(projects?.length)}</Tab>
                  <Tab>Skills {makeFriendly(roles?.length)}</Tab>
                  <Tab>Coworkers {makeFriendly(coworkers?.length)}</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <ProjectsTab
                      canCreateProject={isCurrentUser}
                      emptyProjectsText={
                        isCurrentUser
                          ? EMPTY_PROJECTS_TAB_TEXT_OWNER
                          : EMPTY_PROJECTS_TAB_TEXT.replace('{owner}', profile?.username ?? '')
                      }
                      onCreateProject={handleCreateProject}
                      onProjectClick={handleOpenProjectDetails}
                      projects={projects}
                    />
                  </TabPanel>
                  <TabPanel>
                    <SkillsTab
                      onShowProgression={handleOpenSkillSummary}
                      emptyText={
                        isCurrentUser
                          ? EMPTY_SKILLS_TAB_TEXT_OWNER
                          : EMPTY_SKILLS_TAB_TEXT.replace('{owner}', profile?.username ?? '')
                      }
                      skillGroup={groupedUserRoles}
                    />
                  </TabPanel>
                  <TabPanel>
                    <CoworkersTab
                      coworkers={coworkers}
                      onCoworkerClick={handleOpenUserProfile}
                      emptyText={
                        isCurrentUser
                          ? EMPTY_COWORKERS_TAB_TEXT_OWNER
                          : EMPTY_COWORKERS_TAB_TEXT.replace('{owner}', profile?.username ?? '')
                      }
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </AppContainer.Content>
      </AppContainer>
      {profile && (
        <EditProfileModal
          isOpen={isOpen}
          onClose={onClose}
          profile={{ ...profile, photo: profile?.avatarUrl ?? '' }}
          onSave={handleEditProfile}
          onChangePassword={onOpenChangePassword}
          customValidationSchema={validateUsername}
        />
      )}
      <ChooseNewPassword
        isOpen={isOpenChangePassword}
        onSubmit={handleChangePassword}
        onClose={onCloseChangePassword}
      />
      {isOpenTaskSummary && !!selectedRoleId && !!roleDetails && !isRoleDetailsLoading && (
        <TaskSummaryModal
          isOpen={isOpenTaskSummary}
          org={roleDetails.org}
          title={roleDetails.title}
          isExpert={roleDetails.isExpert}
          tasks={roleDetails.tasks}
          onCloseClicked={handleCloseTaskSummary}
          onInterestedClicked={user.id === profile?.id ? handleInterestedClicked : undefined}
        />
      )}
      {isProjectSummaryOpen && !!profile && !!projectDetails && !isProjectDetailsLoading && (
        <ProjectSummary
          username={profile.username}
          isOpen={isProjectSummaryOpen}
          onCloseClicked={onCloseProjectSummary}
          onProjectReview={handleOpenProjectReview(projectDetails.id)}
          organisation={projectDetails.organization}
          roles={projectDetails.roles}
          project={{
            id: projectDetails.id,
            name: projectDetails.name,
            description: projectDetails.description,
            dateRange: projectDetails.dateRange,
            tag: projectDetails.tag,
          }}
        />
      )}
    </>
  )
}
