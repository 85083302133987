import React, { FC } from 'react'
import { Select } from 'chakra-react-select'

export const Autocomplete: FC<any> = ({ ...selectProps }) => {
  return (
    <Select
      components={{
        DropdownIndicator: null,
      }}
      {...selectProps}
    />
  )
}
