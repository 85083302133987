import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
// import { IUser } from '@deepwork/interfaces'
import { IUser } from 'libs/interfaces/src'
import { QueriesKeysEnum } from './queries-keys-enum'
import axios from '../api/setup'
import { AxiosRequestConfig } from 'axios'
import { useToast } from '@chakra-ui/react'
import { getUsers } from '../api/users'

export const useUser = (
  options?: UseQueryOptions<unknown, string, IUser, QueriesKeysEnum.user>,
): UseQueryResult<IUser> => {
  const toast = useToast()
  return useQuery(QueriesKeysEnum.user, () => axios.get('/actor'), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    enabled: false,
    ...options,
  })
}

export const useUsers = (
  params?: AxiosRequestConfig,
  options?: UseQueryOptions<unknown, string, IUser[], QueriesKeysEnum.users>,
): UseQueryResult<IUser[]> => {
  const toast = useToast()
  return useQuery(QueriesKeysEnum.users, () => getUsers(params), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}
