import React, { FC } from 'react'
// import { CollaboratorApplication, ProfileBox, RoundedBox } from '@deepwork/ui-components'
// import { ProjectApplicant, RoleTypeEnum } from '@deepwork/interfaces'
import { CollaboratorApplication, ProfileBox, RoundedBox } from 'libs/ui-components/src'
import { ProjectApplicant, RoleTypeEnum } from 'libs/interfaces/src'
import { Box, Text, useDisclosure } from '@chakra-ui/react'
import { APP_PATHS } from '../../../../paths'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmExpertModal } from '../../../../components/modals/confirm-expert-modal'
import { useProjectConfirmApplication } from '../../../../shared/mutations/project'
import { useQueryClient } from 'react-query'
import { QueriesKeysEnum } from '../../../../shared/queries/queries-keys-enum'

interface IApplicantsListProps {
  applicants: ProjectApplicant[]
}
export const ApplicantsList: FC<IApplicantsListProps> = ({ applicants }) => {
  const navigate = useNavigate()
  const queryCache = useQueryClient()
  const params = useParams<{ projectId: string }>()
  const [selectedApplicant, setSelectedApplicant] = React.useState<ProjectApplicant | null>(null)
  const {
    isOpen: isOpenConfirmExpert,
    onClose: onCloseConfirmExpert,
    onOpen: onOpenConfirmExpert,
  } = useDisclosure()
  const { mutate: onConfirmApplication } = useProjectConfirmApplication(
    selectedApplicant?.roleUId ?? '',
    selectedApplicant?.actor.username ?? '',
    {
      onSuccess: () => {
        onCloseConfirmExpert()
      },
      onError: (err) => {
        console.error(err)
      },
      onSettled: () => {
        queryCache.invalidateQueries([QueriesKeysEnum.projectDetails, params.projectId])
      },
    },
  )

  const handleConfirmCollaborator = (applicant: ProjectApplicant) => () => {
    if (applicant.type === RoleTypeEnum.Expert || applicant.type === RoleTypeEnum.Shadow) {
      onOpenConfirmExpert()
      setSelectedApplicant(applicant)
    } else {
      navigate(
        APP_PATHS.confirmCollaborator
          .replace(':projectId', params?.projectId ?? '')
          .replace(':roleId', applicant.roleUId)
          .replace(':username', applicant.actor.username),
      )
    }
  }

  const handleConfirmApplication = (message: string) => {
    onConfirmApplication({ acceptanceLetter: message })
  }

  return applicants.length ? (
    <>
      <Text variant="h2" mb="26px" textTransform="capitalize">
        Applications
      </Text>
      {applicants.map((application, index) => (
        <Box mb="26px" key={index}>
          <CollaboratorApplication
            user={application.actor}
            jobTitle={`${application.roleName} ${application.type === 'expert' ? 'expert' : ''}${
              application.type === 'shadow' ? ' (shadow)' : ''
            }`}
            approved={application.approved}
            canApprove={application.canApprove}
            onConfirm={handleConfirmCollaborator(application)}
          >
            {application.motivationLetter}
          </CollaboratorApplication>
          {application.acceptanceLetter ? (
            <RoundedBox mt="7px" p={{ base: '10px 25px' }}>
              <Text mb="8px">"{application.acceptanceLetter}"</Text>
              <ProfileBox
                variant="wide"
                name={application.expert.username}
                avatarUrl={application.expert.avatarUrl}
                jobTitle={`Lead ${application.roleName}`}
              />
            </RoundedBox>
          ) : null}
        </Box>
      ))}
      {isOpenConfirmExpert && selectedApplicant && (
        <ConfirmExpertModal
          isOpen={isOpenConfirmExpert}
          onCloseClicked={onCloseConfirmExpert}
          applicantName={selectedApplicant.actor.username}
          applicationType={selectedApplicant.type}
          onSubmit={handleConfirmApplication}
        />
      )}
    </>
  ) : (
    <Box mb="26px">
      <Text variant="h2" color="white" textTransform="capitalize">
        Applications
      </Text>
      <Text color="white">You will see applications over here once someone applies</Text>
    </Box>
  )
}
