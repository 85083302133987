import { AppContainer } from '../../../components/app-container'
import React, { ChangeEvent } from 'react'
import { Box, Button, Checkbox, FormControl, FormLabel, Text, Textarea } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'
import { useProjectDetails } from '../../../shared/queries/project'
import { useRoleDetails } from '../../../shared/queries/useRoles'
// import { Budget, CircleArrowRight, CollapsableTaskSet, DataBox, RoundedBox, TaskItem } from '@deepwork/ui-components'
// import { formatDate, formatDateRange, pluralise } from '@deepwork/utils'
// import { ApplyToProjectDTO, RoleTypeEnum } from '@deepwork/interfaces'
import {
  Budget,
  CircleArrowRight,
  CollapsableTaskSet,
  DataBox,
  RoundedBox,
  TaskItem,
} from 'libs/ui-components/src'
import { formatDate, formatDateRange, pluralise } from 'libs/utils/src'
import { ApplyToProjectDTO, RoleTypeEnum } from 'libs/interfaces/src'
import { useProjectSubmitApplication } from '../../../shared/mutations/project'

export const ProjectApplication = () => {
  const params = useParams<{ roleId: string; projectId: string; type: RoleTypeEnum }>()
  const navigate = useNavigate()
  const { data: project } = useProjectDetails(params.projectId ?? '')
  const { data: roleDetails } = useRoleDetails(params.roleId ?? '')
  const { mutate: onSubmitApplication } = useProjectSubmitApplication(roleDetails?.uId ?? '')
  const [selectedTasks, setSelectedTasks] = React.useState<string[]>([])
  const [termsAccepted, setTermsAccepted] = React.useState(false)
  const [shadow, setShadow] = React.useState(false)
  const [coverLetter, setCoverLetter] = React.useState('')

  const handleChangeShadow = (e: ChangeEvent<HTMLInputElement>) => setShadow(e.target.checked)

  const handleChangeTerms = (event: ChangeEvent<HTMLInputElement>) =>
    setTermsAccepted(event.target.checked)

  const handleSubmitApplication = () => {
    const data: ApplyToProjectDTO = {
      tasks: selectedTasks,
      motivationLetter: coverLetter,
      shadow,
      expert: params.type === RoleTypeEnum.Expert,
    }
    onSubmitApplication(data, {
      onSuccess: () => {
        navigate(
          APP_PATHS.applyToProjectSuccess
            .replace(':projectId', params.projectId ?? '')
            .replace(':roleId', params.roleId ?? ''),
        )
      },
    })
  }

  const handleChangeCoverLetter = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCoverLetter(event.target.value)
  }

  const handleToggleTask = (taskId: string) => () => {
    if (selectedTasks.includes(taskId)) {
      setSelectedTasks(selectedTasks.filter((t) => t !== taskId))
    } else {
      setSelectedTasks([...selectedTasks, taskId])
    }
  }

  return (
    <>
      {roleDetails && (
        <>
          <AppContainer.Header>
            <Button
              onClick={() => navigate(-1)}
              variant="link"
              leftIcon={<CircleArrowRight transform={'rotate(180deg)'} />}
            >
              {project?.name}
            </Button>
            <Text mt="13px" variant="h1" color="white">
              Application: {roleDetails.title}
            </Text>
            <Box mt="21px" display="flex" alignItems="center">
              <DataBox label={'Dates'} boxProps={{ mr: '64px' }}>
                {project && formatDateRange(project.dateRange[0], project.dateRange[1])}
              </DataBox>
              <DataBox label={'Application deadline'} boxProps={{ mr: '64px' }}>
                {formatDate(project?.applicationDeadline)}
              </DataBox>
              <DataBox
                label={'Estimated payment'}
                tooltipLabel="The exact payment depends on if and how the work will be split. The lead will decide on the split before the project starts. By delegating 100% of the tasks, the lead receives 100% of the recruitment bonus."
              >
                <Budget budget={project?.totalBudget ?? 0} />
              </DataBox>
            </Box>
          </AppContainer.Header>
          <AppContainer.Content isDetails>
            <Box mb="67px">
              <Text variant="h2" color="white" mb="14px">
                Choose task
              </Text>
              <Text color="gray.79" mb="25px">
                Please choose all tasks you are comfortable with completing. The lead on the project
                will review your application and confirm them afterwards.
              </Text>
              {params.type === RoleTypeEnum.Contributor && (
                <Checkbox isChecked={shadow} onChange={handleChangeShadow} mb="25px">
                  <Text color="gray.79" variant="h6">
                    I just want to shadow on the project
                  </Text>
                </Checkbox>
              )}
              <CollapsableTaskSet
                defaultOpen={params.type !== RoleTypeEnum.Expert}
                label={roleDetails.title}
                helperText={`${roleDetails.tasks.length} ${pluralise(
                  'task',
                  roleDetails.tasks.length,
                )}`}
              >
                <Box bg="gray.22" p="17px 17px 25px 17px" borderRadius="0 0 12px 12px">
                  {roleDetails.tasks.map((item) => (
                    <TaskItem
                      key={item.id}
                      title={item.title}
                      description={item.description}
                      beforeElement={
                        params.type === RoleTypeEnum.Contributor ? (
                          <Checkbox
                            isDisabled={shadow}
                            isChecked={selectedTasks.includes(item.id)}
                            onChange={handleToggleTask(item.id)}
                          />
                        ) : null
                      }
                      boxProps={{ bg: 'gray.24', mb: '13px' }}
                    />
                  ))}
                </Box>
              </CollapsableTaskSet>
            </Box>
            <Box>
              <Text variant="h2" color="white" mb="21px">
                Application
              </Text>
              <RoundedBox mb="21px">
                <FormControl>
                  <FormLabel>
                    <Text variant="h4" color="white" mb="8px">
                      Why should you be chosen ?
                    </Text>
                    <Text color="gray.79" mb="16px">
                      Please share anything specific that the project owner should know about that
                      they won’t see in your profile.
                    </Text>
                    {params.type === RoleTypeEnum.Expert && (
                      <Text color="gray.79" mb="16px">
                        If the project dates don’t suit you, please specify when you will be
                        available.
                      </Text>
                    )}
                    <Textarea
                      placeholder="I would be great because..."
                      value={coverLetter}
                      onChange={handleChangeCoverLetter}
                    />
                  </FormLabel>
                </FormControl>
              </RoundedBox>
              <RoundedBox pb="24px">
                <FormControl>
                  <FormLabel mb={0}>
                    <Text variant="h4" color="white" mb="28px">
                      Terms and conditions
                    </Text>
                    <Checkbox checked={termsAccepted} onChange={handleChangeTerms}>
                      <Text variant="h6" color="white" ml="15px">
                        I agree to the project{' '}
                        <Button variant="link" as="a" href={project?.termsLink} target="_blank">
                          terms &amp; conditions
                        </Button>
                        {params.type === RoleTypeEnum.Expert && (
                          <span>, the proposed rate and understand that I can negotiate it</span>
                        )}
                        .
                      </Text>
                    </Checkbox>
                  </FormLabel>
                </FormControl>
              </RoundedBox>
              <Button
                disabled={
                  !termsAccepted ||
                  !coverLetter ||
                  (!shadow &&
                    params.type === RoleTypeEnum.Contributor &&
                    selectedTasks.length === 0)
                }
                mt="67px"
                onClick={handleSubmitApplication}
              >
                Submit Application
              </Button>
            </Box>
          </AppContainer.Content>
        </>
      )}
    </>
  )
}
