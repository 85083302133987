export const makeFriendly = (num?: number): string | number => {
  if (!num) return ''
  if (num === 0) return ''
  if (num >= 1000000000) return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
  if (num >= 1000000) return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  if (num >= 1000) return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k'
  return num
}

export const convertMinutesToHours = (minutes: number): number => Math.floor(minutes / 60)
