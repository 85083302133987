import { Avatar, Box, Skeleton, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { RoundedBox } from '../../data-display/rounded-box/rounded-box'

export interface OverviewSidebarProps {
  title?: string
  description?: string
  avatar?: string
  loading?: boolean
  extraChildren?: React.ReactNode
}

export const OverviewSidebar: FC<OverviewSidebarProps> = ({
  title,
  avatar,
  description,
  loading,
  children,
  extraChildren,
}) =>
  loading ? (
    <Skeleton width="290px" borderRadius="24px" isLoaded={!loading} />
  ) : (
    <Box mt="-65px">
      <Box display={'flex'} justifyContent={'center'} mb={'-65px'}>
        <Avatar src={avatar} size={'xl'} />
      </Box>
      <RoundedBox pt={{ base: '80px', md: '80px' }} width={{ base: '100%', md: '290px' }}>
        {title && (
          <Text variant="h1" color="gray.96" mb={'9px'}>
            {title}
          </Text>
        )}
        {description && <Text mb={'18px'}>{description}</Text>}
        {children}
      </RoundedBox>
      {extraChildren}
    </Box>
  )
