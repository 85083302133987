import React, { FC, useEffect, useState } from 'react'
import { Avatar, Box, BoxProps, Text } from '@chakra-ui/react'

export interface SkillBoxProps {
  /**
   * The main label text
   */
  label?: string
  /**
   * Icon to display
   */
  icon?: string
  /**
   * Is an expert skill
   */
  isExpert?: boolean
  /**
   * The data object
   * @param total number
   * @param done number
   */
  data?: { done: number; total: number }
  /**
   * Color in any format.
   * @default pink
   */
  color?: string
  /**
   * BoxProps are used for extra stdatayling on the wrapper Box
   */
  boxProps?: BoxProps

  onClick?: () => void
}
export const SkillBox: FC<SkillBoxProps> = ({
  label,
  icon,
  data,
  color = 'pink',
  isExpert,
  onClick,
  boxProps = {},
}) => {
  const [textColor, setTextColor] = useState('white')
  const [percentage, setPercentage] = useState(0)
  const [bg, setBg] = useState<Pick<BoxProps, 'bg' | 'bgGradient'>>({
    bg: 'gray.24',
  })
  const truncatedLabel =
    (label?.split(' ') ?? []).length > 2 ? label?.split(' ').slice(0, 2).join(' ') + '...' : label

  useEffect(() => {
    if (data && !isExpert) {
      const { done, total } = data ?? {}
      const isAllDone = done === total
      const isNoneDone = done === 0
      if (isAllDone) {
        setTextColor(color)
      }
      if (isNoneDone) {
        setTextColor('gray.50')
      }

      setPercentage((100 * done) / total)
    }
    if (isExpert) {
      setPercentage(100)
    }
  }, [color, data, isExpert])

  useEffect(() => {
    setBg(
      !label
        ? { bg: 'gray.24' }
        : {
            bgGradient: `linear(to-t, ${color} ${percentage}%, gray.50 ${percentage}%)`,
          },
    )
  }, [color, label, percentage])

  return (
    <Box
      w="105px"
      h="105px"
      borderRadius="12px"
      p="7px"
      {...bg}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'normal'}
      {...boxProps}
    >
      <Box
        bg="gray.22"
        display="flex"
        h="100%"
        borderRadius="6px"
        p="8px"
        flexDirection="column"
        justifyContent="space-between"
      >
        {!!label && (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar src={icon} w="21px" h="21px" />
              {!!data && !isExpert && (
                <Text color={textColor} variant="x.small">
                  {data.done}/{data.total}
                </Text>
              )}
            </Box>
            <Text color={textColor} variant="x.small">
              {truncatedLabel}
            </Text>
          </>
        )}
      </Box>
    </Box>
  )
}
