import React, { FC } from 'react'
import { Avatar, Box, Button, Text } from '@chakra-ui/react'
import { DataBox } from '../../data-display/data-box/data-box'
import { ScopeOfWorkValue } from '../../data-display/data-box/scope-of-work-value/scope-of-work-value'
import { RoundedBox } from '../../data-display/rounded-box/rounded-box'
// import { IUser, RoleTypeEnum } from '@deepwork/interfaces'
import { IUser, RoleTypeEnum } from 'libs/interfaces/src'
import { ProfileBox } from '../profile-box/profile-box'
import { Budget } from '../../data-display/data-box/budget/budget'

export interface RoleCardProps {
  /**
   * Title of all application cards
   */
  title: string

  isExpert?: boolean
  canApprove?: boolean
  /**
   * Handle the role details button click
   */
  onRoleDetailsClick: () => void
  /**
   * To display the status bar, that the application is in review
   */
  inReview?: boolean
  /**
   * Props for the lead position
   */
  lead: RoleItemProps
  /**
   * Props for the contributor position
   */
  contributor: RoleItemProps
  /**
   * The callback for the Apply as * button
   */
  onApplyClick?: (type: RoleTypeEnum) => void
  /**
   * User already applied to this role
   */
  alreadyApplied?: boolean
  /**
   * The callback for withdrawing an application
   */
  onWithdrawClick?: (type: RoleTypeEnum) => void
  onRemoveClick?: (userId: string, roleId: string) => void
  /**
   * The callback for clicking on the tasks amount
   */
  onTasksClick?: (username: string) => void
  /**
   * The callback for clicking on the user profile
   */
  onUserClick?: (user: Pick<IUser, 'username' | 'avatarUrl'>) => void
}
export const RoleCard: FC<RoleCardProps> = ({
  title,
  isExpert,
  canApprove,
  onRoleDetailsClick,
  inReview,
  lead,
  contributor,
  onWithdrawClick,
  onRemoveClick,
  onTasksClick,
  onApplyClick,
  alreadyApplied,
  onUserClick,
}) => {
  const handleApplyClick = (type: RoleTypeEnum) => () => {
    if (onApplyClick) {
      onApplyClick(type)
    }
  }

  const handleWithdrawClick = (type: RoleTypeEnum) => () => {
    if (onWithdrawClick) {
      onWithdrawClick(type)
    }
  }

  const handleTaskSummaryClick = (username: string) => () => {
    if (onTasksClick) {
      onTasksClick(username)
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" mb="14px">
        <Text color="white" variant="h4">
          {title}
        </Text>
        <Button variant="link" onClick={onRoleDetailsClick}>
          Role details
        </Button>
      </Box>
      {inReview && (
        <RoundedBox p={{ base: '10px 25px' }} bg="gray.22" mb="7px">
          <Text color="gray.79">Your application is in review</Text>
        </RoundedBox>
      )}
      <Box borderRadius="12px" overflow="hidden">
        <Item // eslint-disable-line
          variant={RoleTypeEnum.Expert}
          isExpert={isExpert}
          canApprove={false}
          onApplyClick={handleApplyClick(RoleTypeEnum.Expert)}
          onTasksClick={handleTaskSummaryClick(lead.user?.username ?? '')}
          onWithdrawClick={handleWithdrawClick(RoleTypeEnum.Expert)}
          onUserProfileClick={onUserClick}
          alreadyApplied={alreadyApplied}
          {...lead}
        />
        <Item // eslint-disable-line
          variant={RoleTypeEnum.Contributor}
          isExpert={isExpert}
          canApprove={canApprove}
          onApplyClick={handleApplyClick(RoleTypeEnum.Contributor)}
          onTasksClick={handleTaskSummaryClick(contributor.user?.username ?? '')}
          onWithdrawClick={handleWithdrawClick(RoleTypeEnum.Contributor)}
          onRemoveClick={onRemoveClick}
          onUserProfileClick={onUserClick}
          alreadyApplied={alreadyApplied}
          {...contributor}
        />
      </Box>
    </Box>
  )
}

interface RoleItemProps {
  user: Pick<IUser, 'username' | 'avatarUrl' | 'canApprove'> | null
  status?: 'open' | 'pending' | 'closed'
  dates: string
  jobTitle?: string
  tasks: number
  tasksTooltip?: string
  timeValue: number | number[]
  payment: (string | number) | (string | number)[]
  paymentTooltip?: string
}
interface _ItemProps {
  isExpert?: boolean
  canApprove?: boolean
  alreadyApplied?: boolean
  variant: RoleTypeEnum
  onApplyClick?: () => void
  onWithdrawClick?: () => void
  onTasksClick?: () => void
  onRemoveClick?: any
  onUserProfileClick?: (user: Pick<IUser, 'username' | 'avatarUrl'>) => void
}
const Item: React.FC<RoleItemProps & _ItemProps> = ({
  variant,
  isExpert,
  canApprove,
  user,
  status = 'open',
  alreadyApplied,
  dates,
  jobTitle,
  tasks,
  tasksTooltip,
  timeValue,
  payment,
  paymentTooltip,
  onApplyClick,
  onWithdrawClick,
  onTasksClick,
  onRemoveClick,
  onUserProfileClick,
}) => {
  const handleApplyClick = () => {
    onApplyClick && onApplyClick()
  }
  const handleWithdrawClick = () => {
    onWithdrawClick && onWithdrawClick()
  }
  const handleRemoveClick = () => {
    onRemoveClick && onRemoveClick()
  }
  const handleTasksClick = () => {
    onTasksClick && onTasksClick()
  }
  const handleUserProfileClick = () => {
    if (user) {
      onUserProfileClick && onUserProfileClick(user)
    }
  }

  let userBlock = <></>

  const canWithdraw = user && status === 'pending'
  const canRemove = canApprove && user && status !== 'pending'
  const justView = !canApprove && user && status !== 'pending'
  const canApply = !user

  if (canWithdraw) {
    userBlock = (
      <>
        <Avatar size="lg" src={user.avatarUrl} />
        <Button ml="16px" onClick={handleWithdrawClick}>
          Withdraw application
        </Button>
      </>
    )
  }

  if (canRemove) {
    userBlock = (
      <>
        <Avatar size="lg" src={user.avatarUrl} />
        <Button bg="#eb5757" _hover={{ bg: '#b04747' }} ml="16px" onClick={handleRemoveClick}>
          Remove
        </Button>
      </>
    )
  }

  if (justView) {
    userBlock = (
      <ProfileBox
        size="lg"
        variant="compact"
        onClick={handleUserProfileClick}
        avatarUrl={user.avatarUrl}
        name={user.username}
        jobTitle={jobTitle}
      />
    )
  }

  if (canApply) {
    userBlock = (
      <>
        <Avatar size="lg" />
        <Button
          onClick={handleApplyClick}
          disabled={
            alreadyApplied || status === 'closed' || (!isExpert && variant === RoleTypeEnum.Expert)
          }
          ml="16px"
        >
          {variant === RoleTypeEnum.Expert ? 'Apply for lead role' : 'Apply as contributor'}
        </Button>
      </>
    )
  }

  return (
    <Box
      bg={variant === RoleTypeEnum.Expert ? 'gray.22' : 'gray.24'}
      px="20px"
      py="16px"
      display={{ base: 'flex', md: 'grid' }}
      flexDirection={{ base: 'column' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      justifyItems="flex-start"
      gridTemplateColumns={{
        md: 'max-content 1fr auto',
      }}
      gridTemplateAreas={{
        md: '"avatar-content dates-scopeOfWork estimatedPayment"',
      }}
    >
      <Box
        order={{ base: 100 }}
        gridArea="avatar-content"
        display="flex"
        alignItems="center"
        width={{ base: 'auto', md: '330px' }}
      >
        {userBlock}
      </Box>
      <Box
        gridArea="dates-scopeOfWork"
        display="grid"
        gridColumnGap="20px"
        gridTemplateColumns="1fr 1fr"
        gridTemplateAreas='"dates scopeOfWork"'
        width="100%"
        alignItems="center"
      >
        <DataBox
          label="Dates"
          boxProps={{
            gridArea: 'dates',
            justifySelf: 'stretch',
            width: { base: 'auto', md: '200px' },
          }}
        >
          {dates}
        </DataBox>
        <DataBox
          label="Scope of work"
          tooltipLabel={tasksTooltip}
          boxProps={{
            gridArea: 'scopeOfWork',
            width: { base: 'auto', md: '200px' },
          }}
        >
          <ScopeOfWorkValue
            taskAmount={tasks}
            onTaskClick={handleTasksClick}
            timeValue={timeValue}
          />
        </DataBox>
      </Box>
      <DataBox
        label="Estimated payment"
        tooltipLabel={paymentTooltip}
        boxProps={{
          gridArea: 'estimatedPayment',
          width: { base: 'auto', md: '200px' },
          my: { base: '24px', sm: '0' },
        }}
      >
        <Budget budget={payment} />
      </DataBox>
    </Box>
  )
}
