import React from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FileUpload } from '../../form/file-upload/file-upload'
import { Form, Formik } from 'formik'
import { FormikInputControl } from '../../form/formik-wrappers'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
// import { IUserProfileFormValues } from '@deepwork/interfaces'
import { IUserProfileFormValues } from 'libs/interfaces/src'

interface Props {
  profile?: IUserProfileFormValues
  isOpen: boolean
  onClose: () => void
  onSave: (values: IUserProfileFormValues) => void
  onChangePassword: () => void
  customValidationSchema?: Yup.ObjectSchema<ObjectShape>
}
export const EditProfileModal: React.FC<Props> = ({
  isOpen,
  profile,
  onChangePassword,
  onClose,
  onSave,
  customValidationSchema,
}) => {
  const initialValues: IUserProfileFormValues = {
    username: profile?.username || '',
    email: profile?.email || '',
    website: profile?.website || '',
    wallet: profile?.wallet || '',
    discordId: profile?.discordId || '',
    photo: null,
    bio: profile?.bio || '',
  }
  const handleSubmitForm = (values: IUserProfileFormValues) => {
    onSave(values)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit your account details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={Yup.object()
              .shape({
                email: Yup.string().email('Invalid email').required('Email is required'),
                website: Yup.string().url('Invalid URL'),
                bio: Yup.string().max(140, 'Bio must be 140 characters or less'),
              })
              .concat(customValidationSchema || Yup.object())}
            onSubmit={handleSubmitForm}
          >
            {({ values, setFieldValue }) => ( // eslint-disable-line
              <Form noValidate>
                <FormikInputControl
                  label="Name"
                  name="username"
                  placeholder="John Doe"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="johndoe@example.com"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Link to work"
                  name="website"
                  placeholder="https://www.behance.net"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Profile bio"
                  placeholder="Visual artist, like working on design."
                  name="bio"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Ethereum Address"
                  placeholder="0x..."
                  name="wallet"
                  styleProps={{ mb: '21px' }}
                />
                <FormikInputControl
                  label="Discord Profile Id"
                  placeholder="andrej#1111"
                  name="discordId"
                />
                <Button variant="link" mt="24px" mb="24px" onClick={onChangePassword}>
                  Change Password
                </Button>
                <FormControl id="photo" mb="21px">
                  <FormLabel>Photo</FormLabel>
                  <FileUpload name="photo" preview={profile?.photo} onChange={setFieldValue} />
                </FormControl>
                <Button type="submit">Save</Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
