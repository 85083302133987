import React, { FC, useEffect, useRef } from 'react'
// import { IRoleValues } from '@deepwork/interfaces'
// import { CreateTaskSet, FormikChipInputControl, FormikInputControl } from '@deepwork/ui-components'
import { IRoleValues } from 'libs/interfaces/src'
import { CreateTaskSet, FormikChipInputControl, FormikInputControl } from 'libs/ui-components/src'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { ConfirmationAlert } from '../../alerts/confirmation-alert'
import { v4 as uuidv4 } from 'uuid'

const INITIAL_VALUES: IRoleValues = {
  id: '',
  title: '',
  description: '',
  assignees: [],
  tasks: [],
}
/**
 * Is being used when Creating an organisation.
 */
export interface CreateRoleModalProps {
  isOpen: boolean
  isReadOnly?: boolean
  roleNumber: number
  onClose: () => void
  onSave: (role: IRoleValues) => void
  role?: IRoleValues
}

export const CreateRoleModal: FC<CreateRoleModalProps> = ({
  isReadOnly,
  roleNumber = 1,
  isOpen,
  onClose,
  onSave,
  role,
}) => {
  const formRef = useRef<FormikProps<IRoleValues>>(null)
  const [presetRole, setPresetRole] = React.useState<IRoleValues>(INITIAL_VALUES)
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()

  const handleSubmit = (values: IRoleValues) => {
    onSave({
      ...values,
      id: role ? role.id : uuidv4(),
    })
    onClose()
  }

  useEffect(() => {
    if (role && !presetRole.id) {
      setPresetRole(role)
    }
  }, [presetRole, role])

  const handleDiscard = () => {
    onCloseAlert()
    onClose()
  }

  const handleClose = () => {
    if (formRef.current?.dirty) {
      onOpenAlert()
      return
    }
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        closeOnOverlayClick={false}
        size="4xl"
        returnFocusOnClose={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isReadOnly ? 'View role' : role ? 'Update role' : 'Add/Create a role'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody borderRadius="12px" p="25px 25px 32px 25px">
            <Formik
              innerRef={formRef}
              enableReinitialize
              validateOnMount
              initialValues={presetRole}
              onSubmit={handleSubmit}
              validationSchema={Yup.object({
                title: Yup.string().required('Title is required'),
                description: Yup.string().required('Description is required'),
                assignees: Yup.array().required('Assignees is required'),
                tasks: Yup.array().required('Tasks is required'),
              })}
            >
              {({ setFieldValue, values, isValid, errors }) => ( // eslint-disable-line
                <Form noValidate>
                  <Box>
                    <FormikInputControl
                      label={`Role #${roleNumber}`}
                      name="title"
                      styleProps={{ mb: '32px' }}
                      placeholder="User research"
                      disabled={values.isDisabled}
                    />
                    <FormikInputControl
                      label="Description"
                      name="description"
                      styleProps={{ mb: '32px' }}
                      placeholder="Taking user interviews and making presentations"
                      disabled={values.isDisabled}
                    />
                    <FormikChipInputControl
                      label="Assignees"
                      name="assignees"
                      tooltipLabel="Assignees are separated by a space"
                      placeholder="john.doe@hotmail.com"
                      styleProps={{ mb: '32px' }}
                      disabled={values.isDisabled}
                    />
                    <CreateTaskSet
                      isCreating
                      isReadOnly={values.isDisabled}
                      tasks={values.tasks}
                      onChange={(tasks) => setFieldValue('tasks', tasks)}
                    />
                  </Box>
                  {!isReadOnly && (
                    <Box display="flex" alignItems="center" mt="32px">
                      <Button type="submit" size="sm" disabled={!isValid}>
                        {role ? 'Update role' : 'Save role'}
                      </Button>
                    </Box>
                  )}
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ConfirmationAlert
        isOpen={isOpenAlert}
        onClose={onCloseAlert}
        onConfirm={handleDiscard}
        title={`Are you sure you want to close? You will lose all unsaved changes.`}
      />
    </>
  )
}
