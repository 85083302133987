import { DateTime } from 'luxon'

export const formatDateRange = (startDate: string, endDate: string): string => {
  let startDateString = DateTime.fromSQL(startDate).toFormat('LLL dd')
  if (startDateString === 'Invalid DateTime') {
    startDateString = 'N/A'
  }
  let endDateString = DateTime.fromSQL(endDate).toFormat('LLL dd, yyyy')
  if (endDateString === 'Invalid DateTime') {
    endDateString = 'N/A'
  }
  return `${startDateString} - ${endDateString}`
}

export const formatDate = (date?: string): string =>
  date ? DateTime.fromSQL(date).toFormat('LLL dd, yyyy') : ''
