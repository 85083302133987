import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
// import { theme } from '@deepwork/ui-components'
import { theme } from 'libs/ui-components/src'
import App from './app/App'
import { UserContextProvider } from './app/shared/contexts/user-context-provider'
import { ReactQueryProvider } from './app/shared/contexts/react-query-provider'
import { Fonts } from './app/components/fonts'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReactQueryProvider>
        <ChakraProvider theme={theme}>
          <Fonts />
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </ChakraProvider>
      </ReactQueryProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
