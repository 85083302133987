import React, { FC } from 'react'
import {
  Box,
  BoxProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

export interface EmailWasVerifiedProps {
  isOpen: boolean
  onClose: () => void
}

export const EmailWasVerified: FC<EmailWasVerifiedProps> = ({ isOpen, onClose }) => {
  return (
    <Modal size="sm" isOpen={isOpen} onClose={onClose} returnFocusOnClose={false} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>You email has been verified!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="25px" color="white" variant="h6">
            Thank you so much for signing up. We hope you enjoy using this tool.
          </Text>

          <Text mb="25px" color="white" variant="h6">
            It’s been created with love and would not have been possible without the creative
            collaboration of dedicated individuals, who live across different time zones and
            represent various demographic backgrounds.
          </Text>

          <Text color="white" variant="h6">
            We hope that this software will help you collaborate, learn and create beyond the limits
            of your imagination.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface FeedbackBlockProps {
  title: string
  boxProps?: BoxProps
}
const FeedbackBlock: React.FC<FeedbackBlockProps> = ({ title, children, boxProps }) => ( // eslint-disable-line
  <Box p="18px 25px 21px" borderBottom="1px solid" borderColor="gray.18" {...boxProps}>
    <Text variant="h3" color="white" mb="18px">
      {title}
    </Text>
    {children}
  </Box>
)
