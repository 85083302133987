// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Yup from 'yup'
import { StringSchema } from 'yup'
import { debounce } from 'lodash'

const getDebouncedCallbackTestFunction = function (wait, options) {
  if (!this.schema.debouncedCallbackTestFunctions) {
    this.schema.debouncedCallbackTestFunctions = {}
  }

  if (!Object.hasOwnProperty.call(this.schema.debouncedCallbackTestFunctions, this.path)) {
    this.schema.debouncedCallbackTestFunctions[this.path] = debounce(
      async function (value, callback) {
        return await callback(value)
      },
      wait,
      options,
    )
  }

  return this.schema.debouncedCallbackTestFunctions[this.path]
}
export const addDebouncedCallbackMethod = () => {
  Yup.addMethod<StringSchema>(
    Yup.string,
    'debouncedCallback',
    function (message, callback, wait = 350, options = { leading: true, trailing: true }) {
      return this.test('unique', message, function (value) {
        const debouncedCallbackTest = getDebouncedCallbackTestFunction.call(this, wait, options)

        return debouncedCallbackTest.call(this, value, callback)
      })
    },
  )
}
