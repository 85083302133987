import { useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { APP_PATHS } from '../../../paths'
import { AppContainer } from '../../../components/app-container'
import { Text } from '@chakra-ui/react'
import { PeopleAndSkillsStep } from './steps/PeopleAndSkillsStep'
import { NewOrganisationProvider, useNewOrganisationState } from './NewOrganisationContext'
// import { EditOrganizationDTO } from '@deepwork/interfaces'
import { EditOrganizationDTO } from 'libs/interfaces/src'
import { useEditOrganisationMutation } from '../../../shared/mutations/organisation'
import { useGetOrgManagers, useOrganisationRoles } from '../../../shared/queries/organisation'

const EditOrganisationComponent = () => {
  const params = useParams<{ organisationId: string }>()
  const { peopleAndSkills } = useNewOrganisationState()
  const navigate = useNavigate()
  const { data: presetRoles } = useOrganisationRoles(params.organisationId ?? '')
  const { mutate: onEditOrganisation } = useEditOrganisationMutation(params.organisationId ?? '', {
    onSuccess: () => {
      navigate(
        APP_PATHS.organisationDetails.replace(':organisationId', params.organisationId ?? ''),
      )
    },
  })
  const { data: orgManagers } = useGetOrgManagers(params.organisationId ?? '')
  const handleSubmitOrganisation = async () => {
    const data: EditOrganizationDTO = {
      managers: peopleAndSkills.emailList,
      roles: peopleAndSkills.roles,
    }
    onEditOrganisation(data)
  }

  const orgManagerEmailList = orgManagers?.map((manager) => manager.email)

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white" data-testid="page-title">
          Edit organisation
        </Text>
      </AppContainer.Header>
      <AppContainer.Content isDetails pt={70}>
        <PeopleAndSkillsStep
          isEdit
          presetRoles={presetRoles}
          managers={orgManagerEmailList}
          onNext={handleSubmitOrganisation}
          nextLabel="Save"
        />
      </AppContainer.Content>
    </>
  )
}

export const EditOrganisation = () => {
  const params = useParams<{ organisationId: string }>() // eslint-disable-line

  return (
    <NewOrganisationProvider>
      <EditOrganisationComponent />
    </NewOrganisationProvider>
  )
}
