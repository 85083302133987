import { useQuery, UseQueryResult } from 'react-query'
import axios from '../api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'
// import {
//   GetActorRoleDetailsDTO,
//   GetProjectDTO,
//   IOrganisation,
//   IProject,
//   ITag,
//   ReviewDataOwnerDTO,
//   ReviewDataRoleContributorDTO,
//   ReviewDataRoleExpertDTO
// } from '@deepwork/interfaces'
import {
  GetActorRoleDetailsDTO,
  GetProjectDTO,
  IOrganisation,
  IProject,
  ITag,
  ReviewDataOwnerDTO,
  ReviewDataRoleContributorDTO,
  ReviewDataRoleExpertDTO,
  GetActorRolesDTO,
} from 'libs/interfaces/src'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

interface IProjectsListQuery {
  tag?: string
  start?: string
  orgId?: string
}

export const useProjectsList = (data?: IProjectsListQuery): UseQueryResult<IProject[]> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.projects, data],
    () =>
      axios.get(`/projects`, {
        params: {
          tag: data?.tag,
          start: data?.start,
          orgId: data?.orgId,
        },
      }),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useOrgListForManagers = (): UseQueryResult<IOrganisation[]> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.orgListForManagers],
    () => axios.get(`/project/orgListForManager`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useProjectTagList = (): UseQueryResult<ITag[]> => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.projectTagList], () => axios.get('/project/tag/list'), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
  })
}

export const useProjectDetails = (projectId: string): UseQueryResult<GetProjectDTO> => {
  const toast = useToast()
  const navigate = useNavigate()

  return useQuery(
    [QueriesKeysEnum.projectDetails, projectId],
    () => axios.get(`/project/${projectId}`),
    {
      retry: false,
      onError: (error: string) => {
        navigate('/projects')
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useUserRoles = (username: string): UseQueryResult<GetActorRolesDTO[]> => {
  const toast = useToast()

  return useQuery(
    [QueriesKeysEnum.userRoles, username],
    () => axios.get(`/actor/${username}/roles`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useRoleDetails = (
  projectId: string,
  roleId: string,
  username: string,
): UseQueryResult<GetActorRoleDetailsDTO> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.userRoleDetails, roleId],
    () => axios.get(`/project/${projectId}/role/${roleId}/actor/${username}`),
    {
      cacheTime: 0,
      enabled: !!roleId && !!username,
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useProjectOwnerFeedbackDetails = (
  projectId: string,
): UseQueryResult<ReviewDataOwnerDTO> => {
  const toast = useToast()

  return useQuery(
    [QueriesKeysEnum.projectOwnerFeedbackDetails, projectId],
    () => axios.get(`/project/${projectId}/owner/review`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useProjectLeadFeedbackDetails = (
  roleId: string,
): UseQueryResult<ReviewDataRoleExpertDTO> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.projectLeadFeedbackDetails, roleId],
    () => axios.get(`/project/role/${roleId}/expert/review`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useProjectContributorFeedbackDetails = (
  roleId: string,
): UseQueryResult<ReviewDataRoleContributorDTO> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.projectContributorFeedbackDetails, roleId],
    () => axios.get(`/project/role/${roleId}/contributor/review`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}
