import React from 'react'
import { Box, Container } from '@chakra-ui/react'

export const AppHeader: React.FC = ({ children }) => (
  <Box bg="gray.12">
    <Container maxW="container.lg" mt="39px" pb="45px" px="1rem">
      {children}
    </Container>
  </Box>
)
