export const enum APP_PATHS {
  home = '/',
  userProfile = '/profile/:username',
  confirmEmail = '/confirm-email/:emailVerifiedToken',
  resetPassword = '/reset-password/:emailVerifiedToken',
  organisations = '/organisations',
  newOrganisation = '/organisations/new',
  organisationDetails = '/organisations/:organisationId',
  editOrganisation = '/organisations/:organisationId/edit',
  projects = '/projects',
  newProject = '/projects/new',
  projectDetails = '/projects/:projectId',
  editProject = '/projects/:projectId/edit',
  confirmCollaborator = '/projects/:projectId/:roleId/:username/confirm-collaborator',
  confirmCollaboratorSuccess = '/projects/:projectId/:roleId/:username/confirm-collaborator/success',
  projectOwnerFeedback = '/projects/:projectId/owner/feedback',
  projectLeadFeedback = '/projects/:projectId/role/:roleId/lead/feedback',
  projectContributorFeedback = '/projects/:projectId/role/:roleId/contributor/feedback',
  projectCompleted = '/projects/:projectId/completed',
  applyToProject = '/projects/:projectId/:roleId/:type/apply',
  applyToProjectSuccess = '/projects/:projectId/apply/:roleId/success',
}
