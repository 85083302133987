import React from 'react'
import { OrganizationButton } from '../organization-button/organization-button'
import { Box, Button, Text } from '@chakra-ui/react'
import { Tag } from '../../data-display/tag'
import { ExternalLinkIcon } from '@chakra-ui/icons'
// import { formatDateRange } from '@deepwork/utils'
import { formatDateRange } from 'libs/utils/src'
import { useNavigate } from 'react-router-dom'
import { APP_PATHS } from 'app/paths'

export interface ProjectModalHeaderProps {
  projectId?: string
  organisationName: string
  organisationImageUrl: string
  name: string
  description?: string
  tag?: string
  dateRange?: string[]
  externalDocument?: string
}

export const ProjectModalHeader: React.FC<ProjectModalHeaderProps> = ({
  name,
  projectId,
  description,
  tag,
  dateRange,
  organisationName,
  organisationImageUrl,
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(APP_PATHS.projectDetails.replace(':projectId', projectId ?? ''))
  }

  return (
    <>
      <OrganizationButton label={organisationName} imageUrl={organisationImageUrl} mb="12px" />
      <Box display="flex" mb="12px" alignItems="center">
        <Text variant="h2" color="white" mr="16px">
          {name}
        </Text>
        {!!tag && <Tag value={tag} selected />}
      </Box>
      {!!description && (
        <Text color="gray.96" mb="12px">
          {description}
        </Text>
      )}
      {!!dateRange && (
        <Text variant="h5" color="white" mb="16px">
          {formatDateRange(dateRange[0], dateRange[1])}
        </Text>
      )}
      {projectId ? (
        <Button onClick={handleClick} variant="link" rightIcon={<ExternalLinkIcon />}>
          Project details
        </Button>
      ) : null}
    </>
  )
}

export default ProjectModalHeader
