import { extendTheme } from '@chakra-ui/react'
import { colors } from './colors'
import { Button } from './button'
import { Checkbox } from './checkbox'
import { Radio } from './radio'
import { Tooltip } from './tooltip'
import { Text } from './text'
import { Avatar } from './avatar'
import { Input } from './input'
import { Textarea } from './textarea'
import { Slider } from './slider'
import { Modal } from './modal'
import { CloseButton } from './close-button'
import { FormLabel } from './form-label'
import { Menu } from './menu'
import { FormError } from './form-error'
import { Tabs } from './tabs'
import { Badge } from './badge'

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        bg: 'gray.12',
        color: 'gray.79',
        fontFamily: '"CircularStd", sans-serif',
      },
      '::-webkit-calendar-picker-indicator': {
        filter: 'invert(1)',
      },
      h1: {
        fontSize: '28px',
        fontWeight: '700',
        lineHeight: '35.42px',
      },
      h2: {
        fontSize: '22px',
        fontWeight: '700',
        lineHeight: '27.83px',
      },
      h3: {
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '22.77px',
      },
      h4: {
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '20.24px',
      },
      h5: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20.24px',
      },
      h6: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '20.24px',
      },
    },
  },
  colors,
  sizes: {
    '4xl': '930px',
    container: {
      lg: 'calc(930px + 2rem)',
      xl: 'calc(1300px + 2rem)',
    },
    modal: {},
  },
  components: {
    Button,
    Checkbox,
    Radio,
    Tooltip,
    Text,
    Avatar,
    Input,
    Textarea,
    Slider,
    Modal,
    Menu,
    CloseButton,
    FormLabel,
    FormError,
    Tabs,
    Badge,
  },
})
