import { Outlet, useNavigate } from 'react-router-dom'
import {
  useUserContextState,
  useUserContextStateDispatch,
} from '../../shared/contexts/user-context-provider'
// import { Header } from '@deepwork/ui-components'
import { Header } from 'libs/ui-components/src'
import { APP_PATHS } from '../../paths'
import { Box, Container } from '@chakra-ui/react'

export const Layout = () => {
  const navigate = useNavigate()
  const { user } = useUserContextState()
  const { onOpenLoginModal, onLogout } = useUserContextStateDispatch()

  const handleNavigate = (value: string) => {
    navigate(value)
  }

  return (
    <Box bg="gray.12">
      <Container maxW="container.xl" py="30px">
        <Header
          user={user.id ? user : undefined}
          onHomeClick={() => handleNavigate(APP_PATHS.home)}
          links={
            user.id
              ? [
                  {
                    label: 'Organisations',
                    route: APP_PATHS.organisations,
                  },
                  {
                    label: 'Projects',
                    route: APP_PATHS.projects,
                  },
                ]
              : [
                  {
                    label: 'Organisations',
                    route: APP_PATHS.organisations,
                  },
                ]
          }
          profileLinks={[
            {
              label: 'My profile',
              route: APP_PATHS.userProfile.replace(':username', user?.username ?? ''),
            },
            {
              label: 'Logout',
              onClick: onLogout,
            },
          ]}
          onNavigationLinkClick={handleNavigate}
          onLoginClick={onOpenLoginModal}
        />
      </Container>
      <Outlet />
    </Box>
  )
}
