import { AppContainer } from '../../../components/app-container'
// import { CircleArrowRight, RoundedBox } from '@deepwork/ui-components'
import { CircleArrowRight, RoundedBox } from 'libs/ui-components/src'
import { Box, Button, Image, Text } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'
import { useCompleteProjectPreview } from '../../../shared/queries/useUserProfile'
import { useUserContextState } from '../../../shared/contexts/user-context-provider'

export const ProjectCompleted = () => {
  const params = useParams<{ projectId: string }>()
  const navigate = useNavigate()
  const { user } = useUserContextState()
  const { data: projectDetails } = useCompleteProjectPreview(
    user?.username ?? '',
    params.projectId ?? '',
  )

  const handleGoToProject = () => {
    navigate(APP_PATHS.projectDetails.replace(':projectId', params.projectId ?? ''))
  }

  const handleGoToProfile = () => {
    navigate(APP_PATHS.userProfile.replace(':username', user?.username ?? ''))
  }

  return (
    <AppContainer.Content flexDirection="column-reverse">
      <Box>
        <Text variant="h1" color="white">
          Thank you for your work!
        </Text>
        <RoundedBox mt="21px" mb="21px" p="10px 25px">
          <Text color="gray.79">
            Your feedback has been processed and the project will be added to your profile.
          </Text>
        </RoundedBox>
        <Box
          display="flex"
          alignItems={{ base: 'flex-start', sm: 'center' }}
          justifyContent="space-between"
          flexDirection={{ base: 'column', sm: 'row' }}
        >
          <Button onClick={handleGoToProject}>Back to project</Button>
          <Button
            onClick={handleGoToProfile}
            variant="link"
            rightIcon={<CircleArrowRight />}
            mt={{ base: '25px', sm: 0 }}
          >
            Go to my profile
          </Button>
        </Box>
      </Box>
      {projectDetails && projectDetails.projectCompleteImage && (
        <Box mt={'45px'}>
          <Image src={projectDetails.projectCompleteImage} alt={projectDetails.name} />
        </Box>
      )}
    </AppContainer.Content>
  )
}
