import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props {
  css?: any
}

export const CameraIcon: React.FC<Props & IconProps> = (props) => (
  <Icon viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78137 8L12.3909 4.39052C12.6409 4.14048 12.98 4 13.3337 4H18.667C19.0206 4 19.3598 4.14048 19.6098 4.39052L23.2193 8H26.667C28.1398 8 29.3337 9.19391 29.3337 10.6667V25.3333C29.3337 26.8061 28.1398 28 26.667 28H5.33366C3.8609 28 2.66699 26.8061 2.66699 25.3333V10.6667C2.66699 9.19391 3.8609 8 5.33366 8H8.78137ZM26.667 12C26.667 12.7364 26.07 13.3333 25.3337 13.3333C24.5973 13.3333 24.0003 12.7364 24.0003 12C24.0003 11.2636 24.5973 10.6667 25.3337 10.6667C26.07 10.6667 26.667 11.2636 26.667 12ZM9.33366 17.3333C9.33366 21.0152 12.3184 24 16.0003 24C19.6822 24 22.667 21.0152 22.667 17.3333C22.667 13.6514 19.6822 10.6667 16.0003 10.6667C12.3184 10.6667 9.33366 13.6514 9.33366 17.3333Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 21.3334C18.2091 21.3334 20 19.5425 20 17.3334C20 15.1242 18.2091 13.3334 16 13.3334C13.7909 13.3334 12 15.1242 12 17.3334C12 19.5425 13.7909 21.3334 16 21.3334Z"
      fill="white"
    />
  </Icon>
)
