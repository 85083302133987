import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FormikInputControl } from '../../form/formik-wrappers'
import React, { FC } from 'react'

export interface ChangePasswordFormValues {
  password: string
  confirmPassword: string
}
export interface SetNewPasswordProps {
  currentPassword: string
  password: string
  confirmPassword: string
}

export interface ChooseNewPasswordProps {
  isOpen: boolean
  isForgot?: boolean
  onSubmit: (values: SetNewPasswordProps | ChangePasswordFormValues) => void
  onClose: () => void
}

export const ChooseNewPassword: FC<ChooseNewPasswordProps> = ({
  isOpen,
  isForgot,
  onClose,
  onSubmit,
}) => {
  const initialValues = isForgot
    ? {
        password: '',
        confirmPassword: '',
      }
    : {
        currentPassword: '',
        password: '',
        confirmPassword: '',
      }

  const schema = Yup.object({
    password: Yup.string().required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Repeat password is required'),
  }).concat(
    !isForgot
      ? Yup.object({ currentPassword: Yup.string().required('Current password is required') })
      : Yup.object(),
  )
  const handleSubmit = (values: ChangePasswordFormValues) => {
    onSubmit(values)
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please choose a new password</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="32px">
          <Formik
            enableReinitialize
            noValidate
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({ isValid }) => (
              <Form noValidate>
                {!isForgot && (
                  <FormikInputControl
                    type="password"
                    label="Current Password"
                    name="currentPassword"
                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                    styleProps={{ mb: '28px' }}
                  />
                )}
                <FormikInputControl
                  type="password"
                  label="New Password"
                  name="password"
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  styleProps={{ mb: '28px' }}
                />
                <FormikInputControl
                  type="password"
                  label="Repeat Password"
                  name="confirmPassword"
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  styleProps={{ mb: '28px' }}
                />
                <Button disabled={!isValid} type="submit">
                  Change password
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
