import { Box, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
// import { OrganisationCard } from '@deepwork/ui-components'
import { OrganisationCard } from 'libs/ui-components/src'
import { useNavigate } from 'react-router-dom'
import { useOrganisationList } from '../../../shared/queries/organisation'
import {
  useUserContextState,
  useUserContextStateDispatch,
} from '../../../shared/contexts/user-context-provider'
import { APP_PATHS } from '../../../paths'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { AppContainer } from '../../../components/app-container'
import { CardListSkeleton } from '../../../components/loading-skeletons'

export const OrganisationsList = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const [search, setSearch] = useState('')
  const { data: organisations, isLoading: organisationsLoading } = useOrganisationList(search)
  const { user } = useUserContextState()
  const { onOpenLoginModal } = useUserContextStateDispatch()
  const delayedQuery = useRef(debounce((q) => setSearch(q), 600)).current

  const handleOpenOrgDetails = (id: string) => () => {
    if (user.username) {
      navigate(APP_PATHS.organisationDetails.replace(':organisationId', id))
    } else {
      onOpenLoginModal()
    }
  }

  useEffect(() => {
    delayedQuery(value)
  }, [value])

  const handleSearchOrgs = (value: ChangeEvent<HTMLInputElement>) => { // eslint-disable-line
    setValue(value.target.value)
  }

  const handleClearSearch = () => {
    setValue('')
  }
  return organisationsLoading ? (
    <CardListSkeleton loading={organisationsLoading} />
  ) : (
    <AppContainer.Content>
      <Box mt="13px" mb="17px">
        <Text variant="h1" color="white">
          Find an organisation
        </Text>
      </Box>
      <Box mb="40px" display="flex" alignItems="center">
        <InputGroup>
          <Input placeholder="Search" onChange={handleSearchOrgs} value={value} />
          <InputRightElement
            children={<CloseIcon color="gray.36" cursor="pointer" onClick={handleClearSearch} />}
          />
        </InputGroup>
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent={{ base: 'center', md: 'space-between' }}>
        {organisations?.map((item) => (
          <OrganisationCard
            key={item.id}
            onCardClick={handleOpenOrgDetails(item.id)}
            name={item.name}
            image={item.imageUrl}
            projectCount={item.orgProjectsCount}
            description={item.description}
            expertCount={item.orgExpertsCount}
            boxProps={{
              mb: '40px',
              __css: {
                ':nth-of-type(2n + 1)': {
                  mr: { base: '0', sm: '10px', md: 0 },
                },
              },
            }}
          />
        ))}
      </Box>
    </AppContainer.Content>
  )
}
