import React, { useState } from 'react'
// import { ITask } from '@deepwork/interfaces'
// import { AdminCreateButton, RoundedBox, TaskItem } from '@deepwork/ui-components'
import { ITask } from 'libs/interfaces/src'
import { AdminCreateButton, RoundedBox, TaskItem } from 'libs/ui-components/src'
import { Box, Text } from '@chakra-ui/react'
import { TaskForm } from './task-form'
import { TaskListItem } from './task-list-item'

interface TasksListProps {
  isReadOnly?: boolean
  onAddTask: (task: ITask) => void
  onDeleteTask: (taskId: string) => void
  tasks: ITask[]
}
export const TasksList: React.FC<TasksListProps> = ({
  isReadOnly,
  onAddTask,
  onDeleteTask,
  tasks,
}) => {
  const [openForm, setOpenForm] = useState(false)

  const handleCreateTaskTest = () => {
    setOpenForm(true)
  }

  const handleSubmitTask = (values: ITask) => {
    onAddTask({ ...values })
    setOpenForm(false)
  }

  const handleCancel = () => {
    setOpenForm(false)
  }

  const handleDeleteTask = (id: string) => {
    onDeleteTask(id)
  }

  return (
    <>
      {tasks.length > 0 && (
        <Box m="17px 0 25px">
          {tasks.map((task, index) =>
            isReadOnly ? (
              <TaskItem
                key={index}
                title={task.title}
                description={task.description}
                boxProps={{
                  _notLast: {
                    mb: '13px',
                  },
                }}
              />
            ) : (
              <TaskListItem
                key={index}
                task={task}
                onDelete={handleDeleteTask}
                onSubmit={handleSubmitTask}
                boxProps={{
                  _notLast: {
                    mb: '13px',
                  },
                }}
              />
            ),
          )}
        </Box>
      )}
      {openForm && (
        <RoundedBox bg="gray.22" mb="10px">
          <Text variant="h3" mb="32px">
            {`Task #${tasks.length + 1}`}
          </Text>
          <TaskForm onSubmit={handleSubmitTask} onCancel={handleCancel} />
        </RoundedBox>
      )}
      {!openForm && !isReadOnly && (
        <AdminCreateButton bg="gray.22" onClick={handleCreateTaskTest}>
          Add a new task
        </AdminCreateButton>
      )}
    </>
  )
}
