import { Box, BoxProps, Button, Collapse, Text, useDisclosure } from '@chakra-ui/react'
import React, { FC } from 'react'
// import { ITask } from '@deepwork/interfaces'
import { ITask } from 'libs/interfaces/src'
import { TaskForm } from './task-form'

interface TaskListItemProps {
  task: ITask
  onSubmit: (task: ITask) => void
  onDelete: (id: string) => void
  boxProps?: BoxProps
}
export const TaskListItem: FC<TaskListItemProps> = ({ task, onDelete, boxProps, onSubmit }) => {
  const { isOpen, onToggle } = useDisclosure()

  const handleDelete = () => {
    onDelete(task.id)
  }

  const handleSubmit = (values: ITask) => {
    onToggle()
    onSubmit(values)
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bg="gray.22"
      borderRadius="12px"
      px="25px"
      py="15px"
      minHeight="57px"
      {...boxProps}
    >
      <Box w="100%">
        {!isOpen && (
          <Box
            display="flex"
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ base: 'flex-start', md: 'center' }}
            flex="1 0 auto"
          >
            <Text
              isTruncated
              variant="h4"
              color="white"
              maxWidth={{ base: '80%' }}
              mb={{ base: '16px', md: 0 }}
            >
              {task.title}
            </Text>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={{ base: '100%', md: 'auto' }}
            >
              <Button
                variant="link"
                color="warning"
                onClick={handleDelete}
                mr={{ base: 0, md: '92px' }}
              >
                Delete task
              </Button>
              <Button variant="link" onClick={onToggle}>
                Edit task
              </Button>
            </Box>
          </Box>
        )}

        <Collapse in={isOpen} animateOpacity>
          <Text variant="h3" mb="32px">
            {`Edit task ${task.title}`}
          </Text>
          <TaskForm task={task} onSubmit={handleSubmit} onCancel={onToggle} />
        </Collapse>
      </Box>
    </Box>
  )
}
