import * as Yup from 'yup'
import { addDebouncedCallbackMethod } from '../custom-yup-methods/debouncedCallback'
import axios from '../../api/setup'
// import { usernameExprRegexp } from '@deepwork/utils'
import { usernameExprRegexp } from 'libs/utils/src'

addDebouncedCallbackMethod()

const uniqueNameValidationCallback = async (username: string) => {
  if (!username) {
    return true
  }
  try {
    return !!(await axios.get(`/actor/${username}/check`))
  } catch (error) {
    return true
  }
}

const uniqueOrganisationNameValidationCallback = async (name: string) => {
  if (!name) {
    return true
  }
  try {
    return !(await axios.get(`/org/${name}/check`))
  } catch (error) {
    return true
  }
}

export const validateUsername = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Username must be at least 3 characters')
    .max(50, 'Username must be less than 50 characters')
    .test('is-username', 'Remove bad characters', (val = '') =>
      new RegExp(usernameExprRegexp).test(val),
    )
    // @ts-ignore
    .debouncedCallback('Name is already taken', uniqueNameValidationCallback, 500)
    .required('Username is required'),
})

export const validateOrganisationName = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Organisation name must be at least 3 characters')
    .max(50, 'Organisation name must be less than 50 characters')
    // @ts-ignore
    .debouncedCallback(
      'Organisation name is already taken',
      uniqueOrganisationNameValidationCallback,
      500,
    )
    .required('Organisation name is required'),
})
