// import { ethers } from 'ethers'
import { getProvider, requestAccounts } from './provider'
export const connectMetamask = async () => {
  const provider = await getProvider()
  const accounts = await requestAccounts(provider)

  return accounts
}

export const isMetamaskConnected = async () => {
  try {
    const provider = await getProvider()
    const accounts = await provider.listAccounts()
    return accounts.length > 0
  } catch (e) {
    console.log(e)
    return false
  }
}

export const listenConnectionMetamask = async (cb: any) => {
  const extendedWindow: any = window as any
  extendedWindow.ethereum?.on('accountsChanged', async function (accounts: any) {
    cb(accounts.length > 0)
  })
}
