import React, { FC } from 'react'
import { Box, BoxProps, Text } from '@chakra-ui/react'
import { ProfileBox } from '../profile-box/profile-box'
import { DataBox } from '../../data-display/data-box/data-box'

export interface OrganisationCardProps {
  /**
   * Click handler for the primary card
   */
  onCardClick: () => void
  /**
   * Organization name
   */
  name: string
  /**
   * Organization avatar
   */
  image?: string
  description?: string
  projectCount: number
  expertCount: number
  boxProps?: BoxProps
}

export const OrganisationCard: FC<OrganisationCardProps> = ({
  onCardClick,
  image,
  name,
  description,
  projectCount,
  expertCount,
  boxProps,
}) => (
  <Box
    bg="gray.22"
    borderRadius="12px"
    minH="265px"
    w={{ base: '100%', sm: '48.2%', md: '290px' }}
    onClick={onCardClick}
    cursor={'pointer'}
    display="flex"
    flexDirection="column"
    {...boxProps}
  >
    <Box p="13px 25px 11px" borderBottom="1px solid" borderColor="gray.18">
      <ProfileBox color="gray.96" name={name} avatarUrl={image ?? ''} size="sm" />
    </Box>
    <Box
      flex="1 0 auto"
      p="14px 25px 18px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box flex="1 0 auto">
        <Text color="white" variant="paragraph">
          {description}
        </Text>
      </Box>
      <Box mt="18px" display="flex" justifyContent="space-between" alignItems="center">
        <DataBox label="Projects" boxProps={{ mr: '45px' }}>
          <Text>{projectCount} Active</Text>
        </DataBox>
        <DataBox label="Members">
          <Text>{expertCount}</Text>
        </DataBox>
      </Box>
    </Box>
  </Box>
)
