import React from 'react'
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import { useField } from 'formik'
import { InfoIcon } from '@chakra-ui/icons'

interface FormikTextareaControlProps {
  label?: string
  helperText?: string | string[]
  tooltipLabel?: string
  name: string
  placeholder?: string
  type?: string
  disabled?: boolean
  size?: FormControlProps['size']
  styleProps?: FormControlProps
}

export const FormikTextareaControl: React.FC<FormikTextareaControlProps> = ({
  styleProps,
  size,
  helperText,
  ...props
}) => {
  const [field, meta] = useField(props.name)
  return (
    <FormControl
      isInvalid={!!meta.error && meta.touched}
      isDisabled={props.disabled}
      {...styleProps}
    >
      <FormLabel>
        <Box mb="8px" display="flex" alignItems="center">
          {props.label && (
            <Text variant="h4" color="white" mr="8px">
              {props.label}
            </Text>
          )}
          {!!props.tooltipLabel && (
            <Tooltip label={props.tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          )}
        </Box>
        <Box mt="8px">
          {helperText ? (
            typeof helperText === 'string' ? (
              <Text color="gray.79">{helperText}</Text>
            ) : (
              helperText.map((text, index) => (
                <Text color="gray.79" key={index}>
                  {text}
                </Text>
              ))
            )
          ) : null}
        </Box>
      </FormLabel>
      <Textarea required placeholder={props.placeholder} {...field} size={size} />
      {meta.touched && meta.error && <FormErrorMessage>{meta.error ?? ' '}</FormErrorMessage>}
    </FormControl>
  )
}
