import React, { FC, useMemo, useRef } from 'react'
import { AppContainer } from '../../../components/app-container'
// import { Budget, ConfirmationAlert, DataBox, Notification, ProfileBox } from '@deepwork/ui-components'
// import { IProjectDetailRole, RoleTypeEnum } from '@deepwork/interfaces'
// import { formatDateRange, pluralise } from '@deepwork/utils'
import {
  Budget,
  ConfirmationAlert,
  DataBox,
  Notification,
  ProfileBox,
} from 'libs/ui-components/src'
import { IProjectDetailRole, RoleTypeEnum } from 'libs/interfaces/src'
import { formatDateRange, pluralise } from 'libs/utils/src'
import { Badge, Box, Button, Skeleton, Text, useBreakpoint, useDisclosure } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserContextState } from '../../../shared/contexts/user-context-provider'
import { useProjectDetails, useRoleDetails, useUserRoles } from '../../../shared/queries/project'

import { ProjectRolesList } from '../../../components/project-roles-list'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { APP_PATHS } from '../../../paths'
import { ApplicantsList } from '../new-project/applicants-list'
import { TaskSummaryModal } from '../../../components/modals/task-summary-modal'
import {
  useDeleteProjectMutation,
  useProjectWithdrawApplicationMutation,
  useExportProject,
  useRemoveAssignee,
} from '../../../shared/mutations/project'

import { useInterestMutation } from '../../../shared/mutations/user-profile'

export const ProjectDetails: FC = () => {
  const applicationsRef = useRef<HTMLDivElement>(null)
  const params = useParams<{ projectId: string }>()
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()
  const {
    isOpen: isOpenTaskSummary,
    onClose: onCloseTaskSummary,
    onOpen: onOpenTaskSummary,
  } = useDisclosure()
  const {
    isOpen: isOpenDeleteWarning,
    onClose: onCloseDeleteWarning,
    onOpen: onOpenDeleteWarning,
  } = useDisclosure()
  const {
    isOpen: isOpenWithdrawApplication,
    onClose: onCloseWithdrawApplication,
    onOpen: onOpenWithdrawApplication,
  } = useDisclosure()
  const { user } = useUserContextState()
  const {
    data: project,
    isLoading,
    refetch: handleRefetchProject,
  } = useProjectDetails(params.projectId ?? '')
  const {
    data: userRoles,
    // isLoading,
    // refetch: handleRefetchProject,
  } = useUserRoles(user.username ?? 'na')
  const [selectedRole, setSelectedRole] = React.useState<{
    username: string
    roleId: string
  } | null>(null)
  const { mutate: onWithdrawProjectApplication } = useProjectWithdrawApplicationMutation()
  const { mutate: onUpdateInterest } = useInterestMutation()
  const { mutate: onDeleteProject } = useDeleteProjectMutation()
  const { mutate: onExportProject } = useExportProject()
  const { mutate: onRemoveAssignee } = useRemoveAssignee()
  const {
    data: roleDetails,
    isLoading: isRoleDetailsLoading,
    refetch: refetchRoleDetails,
  } = useRoleDetails(
    params.projectId ?? '',
    selectedRole?.roleId ?? '',
    selectedRole?.username ?? '',
  )
  const [selectedRoleWithdraw, setSelectedRoleWithdraw] = React.useState<
    (IProjectDetailRole & { type: RoleTypeEnum }) | null
  >()
  const projectOwnerRole = useMemo(
    () => project?.roles.find((role) => role.isProjectOwner),
    [project?.roles],
  )
  const nonProjectOwnerRoles = useMemo(
    () => project?.roles.filter((role) => !role.isProjectOwner),
    [project?.roles],
  )
  const userCanConfirmApplicants =
    projectOwnerRole?.expert.actor?.id === user.id ||
    nonProjectOwnerRoles?.some((item) => item.expert.actor?.id === user.id)
  const hasUnapprovedApplicants = useMemo(() => {
    return project?.applicants.filter((role) => !role.approved)
  }, [project?.applicants])

  const handleOpenStakeholderProfile = () => {
    if (project) {
      navigate(APP_PATHS.userProfile.replace(':username', project?.stakeholder.username))
    }
  }

  const handleOpenOrganization = () => {
    if (project) {
      navigate(APP_PATHS.organisationDetails.replace(':organisationId', project?.organization.id))
    }
  }

  const handleOpenTaskSummary = (roleId: string, username?: string) => {
    if (project) {
      onOpenTaskSummary()
      setSelectedRole({ roleId, username: username || user.username })
    }
  }

  const handleCloseTaskSummary = () => {
    onCloseTaskSummary()
    setSelectedRole(null)
  }

  const handleApplyClick = (role: IProjectDetailRole, type: RoleTypeEnum) => {
    if (project && role.uId) {
      navigate(
        APP_PATHS.applyToProject
          .replace(':projectId', project.id)
          .replace(':roleId', role.uId)
          .replace(':type', type),
      )
    }
  }

  const handleEditProject = () => {
    if (project) {
      navigate(APP_PATHS.editProject.replace(':projectId', project.id))
    }
  }

  const handleDeleteProject = () => {
    onDeleteProject(project?.id ?? '', {
      onSuccess: () => {
        navigate(APP_PATHS.projects)
      },
    })
  }

  const handleExportProject = () => {
    onExportProject(project?.id ?? '', {
      onSuccess: (exportedProject: any) => {
        const download = function (data: string, fileName: string) {
          const blob = new Blob([data], { type: 'text/json' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.setAttribute('href', url)
          a.setAttribute('download', `${fileName}.json`)
          a.click()
        }

        download(JSON.stringify(exportedProject), `export_${exportedProject.name}`)
      },
    })
  }

  const handleRemoveAssignee = (userId: string, roleId: string) => {
    onRemoveAssignee(
      {
        userId,
        roleId,
      },
      {
        onSuccess: () => {
          handleRefetchProject()
        },
      },
    )
  }

  const handleCompleteProject = () => {
    if (project) {
      navigate(APP_PATHS.projectOwnerFeedback.replace(':projectId', project.id))
    }
  }

  const handleWithdrawApplication = (role: IProjectDetailRole, type: RoleTypeEnum) => {
    if (project) {
      onOpenWithdrawApplication()
      setSelectedRoleWithdraw({ ...role, type })
    }
  }

  const handleConfirmWithdrawApplication = () => {
    onWithdrawProjectApplication(
      {
        roleId: selectedRoleWithdraw?.uId ?? '',
        type: selectedRoleWithdraw?.type ?? RoleTypeEnum.Expert,
      },
      {
        onSuccess: () => {
          onCloseWithdrawApplication()
          handleRefetchProject()
        },
      },
    )
  }

  const handleScrollToApplications = () => {
    applicationsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleInterestedClicked = (taskId: string, isChecked: boolean) => {
    onUpdateInterest(
      { taskId, isChecked },
      {
        onSuccess: () => {
          refetchRoleDetails()
        },
      },
    )
  }

  return (
    <>
      <AppContainer.Header>
        {project && (
          <>
            {userCanConfirmApplicants && !!hasUnapprovedApplicants?.length && (
              <Box mb="25px">
                <Notification
                  buttonLabel={breakpoint === 'base' ? 'Review' : 'Review Applications'}
                  onReviewClick={handleScrollToApplications}
                >
                  You have {hasUnapprovedApplicants?.length} new{' '}
                  {pluralise('application', project.applicants.length)}!
                </Notification>
              </Box>
            )}
            <Box mb="21px">
              {isLoading ? (
                <Skeleton isLoaded={!isLoading} h="35px" w="100px" />
              ) : (
                <Box display="flex" alignItems="center">
                  <Text variant="h1" color="white">
                    {project.name}
                  </Text>
                  <Badge bg="gray.18" color="#F4F3F9" ml="18px">
                    IN PROGRESS
                  </Badge>
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              alignItems={{ base: 'flex-start', md: 'center' }}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Box display="flex" alignItems="center">
                <DataBox
                  loading={isLoading}
                  label="Dates"
                  gap="8px"
                  boxProps={{ mr: { base: '20px', sm: '64px' }, mb: { base: '25px', md: 0 } }}
                >
                  {formatDateRange(project.dateRange[0], project.dateRange[1])}
                </DataBox>
                <DataBox
                  loading={isLoading}
                  label="Total budget"
                  gap="8px"
                  boxProps={{ mr: { base: '20px', sm: '64px' }, mb: { base: '25px', md: 0 } }}
                >
                  <Budget budget={project.totalBudget} />
                </DataBox>
              </Box>
              <Box display="flex" alignItems="center">
                <DataBox
                  loading={isLoading}
                  label="Organisation"
                  gap="6px"
                  boxProps={{ mr: { base: '20px', sm: '64px' } }}
                >
                  <ProfileBox
                    size="xs"
                    name={project.organization.name ?? ''}
                    avatarUrl={project.organization.imageUrl ?? ''}
                    onClick={handleOpenOrganization}
                  />
                </DataBox>
                <DataBox loading={isLoading} gap="6px" label="Stakeholder">
                  {project.stakeholder && (
                    <ProfileBox
                      size="xs"
                      onClick={handleOpenStakeholderProfile}
                      name={project.stakeholder.username}
                      avatarUrl={project.stakeholder.avatarUrl}
                    />
                  )}
                </DataBox>
              </Box>
            </Box>
            <Box mt="26px">
              {isLoading ? (
                <Skeleton isLoaded={!isLoading} h="35px" w="100px" />
              ) : (
                <Text color="gray.79">{project.description}</Text>
              )}
            </Box>
            <Box
              mt="17px"
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
              justifyContent={{ base: 'flex-start', md: 'space-between' }}
              alignItems={{ base: 'flex-start', md: 'center' }}
            >
              <Box display="flex" flex="1 0 auto">
                {project.externalDocument && (
                  <Button
                    as="a"
                    href={project.externalDocument}
                    variant="link"
                    target="_blank"
                    rightIcon={<ExternalLinkIcon />}
                  >
                    Project details
                  </Button>
                )}
                {user?.createOrgEligible && (
                  <Button
                    variant="link"
                    ml={{ base: '0', md: '64px' }}
                    mt={{ base: '32px', md: 0 }}
                    onClick={handleExportProject}
                  >
                    Export project
                  </Button>
                )}
              </Box>
              {projectOwnerRole?.expert.actor?.id === user?.id && !project.done && (
                <Box
                  display="flex"
                  justifyContent={{ base: 'space-between', md: 'center' }}
                  width={{ base: '100%', md: 'auto' }}
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <>
                    <Button
                      variant="link"
                      color="warning"
                      mt={{ base: '32px', md: 0 }}
                      onClick={onOpenDeleteWarning}
                    >
                      Delete project
                    </Button>
                    <Button
                      variant="link"
                      ml={{ base: '0', md: '64px' }}
                      mt={{ base: '32px', md: 0 }}
                      onClick={handleEditProject}
                    >
                      Edit project
                    </Button>
                  </>
                  <Button
                    variant="link"
                    ml={{ base: '0', md: '64px' }}
                    mt={{ base: '32px', md: 0 }}
                    onClick={handleCompleteProject}
                  >
                    Complete project
                  </Button>
                </Box>
              )}
            </Box>
          </>
        )}
      </AppContainer.Header>
      <AppContainer.Content isDetails>
        {project && (
          <>
            <ProjectRolesList
              userRoles={userRoles}
              applications={project?.applicants}
              projectOwner={projectOwnerRole}
              roles={nonProjectOwnerRoles ?? []}
              onOpenTaskSummary={handleOpenTaskSummary}
              onApplyClick={handleApplyClick}
              onWithdrawClick={handleWithdrawApplication}
              onRemoveClick={handleRemoveAssignee}
            />
            {userCanConfirmApplicants && (
              <Box mt="67px" ref={applicationsRef}>
                <ApplicantsList applicants={project.applicants} />
              </Box>
            )}
          </>
        )}
      </AppContainer.Content>
      {isOpenTaskSummary && !!selectedRole && !!roleDetails && !isRoleDetailsLoading && (
        <TaskSummaryModal
          isOpen={isOpenTaskSummary}
          org={roleDetails.org}
          title={roleDetails.title}
          tasks={roleDetails.tasks}
          isExpert={roleDetails.isExpert}
          onCloseClicked={handleCloseTaskSummary}
          onInterestedClicked={handleInterestedClicked}
        />
      )}
      {isOpenDeleteWarning && (
        <ConfirmationAlert
          isOpen={isOpenDeleteWarning}
          onClose={onCloseDeleteWarning}
          onConfirm={handleDeleteProject}
          title={`Are you sure you want to delete ${project?.name}?`}
        />
      )}
      {isOpenWithdrawApplication && (
        <ConfirmationAlert
          isOpen={isOpenWithdrawApplication}
          onClose={onCloseWithdrawApplication}
          onConfirm={handleConfirmWithdrawApplication}
          title={`Are you sure you want to withdraw your application?`}
        />
      )}
    </>
  )
}
