import React, { FC } from 'react'
// import { IOrganisationRole } from '@deepwork/interfaces'
// import { RoundedBox, SkillListItem } from '@deepwork/ui-components'
import { IOrganisationRole } from 'libs/interfaces/src'
import { RoundedBox, SkillListItem } from 'libs/ui-components/src'
import { Box, Text } from '@chakra-ui/react'

interface Props {
  onShowProgression: (skill: IOrganisationRole) => void
  skills?: IOrganisationRole[]
  emptyText: string
}

export const OrganisationSkillsTab: FC<Props> = ({ skills, emptyText, onShowProgression }) => {
  const handleShowProgression = (skill: IOrganisationRole) => () => {
    onShowProgression(skill)
  }
  return (
    <Box>
      {skills?.length ? (
        <Box>
          {skills.map((skill) => (
            <SkillListItem
              key={skill.id}
              title={skill.title}
              count={[skill.tasksCount]}
              onClick={handleShowProgression(skill)}
            />
          ))}
        </Box>
      ) : (
        <RoundedBox h={'325px'} display="flex" alignItems="center" justifyContent="center">
          <Text variant="h6">{emptyText}</Text>
        </RoundedBox>
      )}
    </Box>
  )
}
