import React, { useCallback, useReducer } from 'react'
import { CredentialsValues, FeedbackValues, PaymentValues, ReviewValues } from './interfaces'

type Action =
  | { type: 'update-review'; payload: Partial<ReviewValues> }
  | { type: 'update-feedback'; payload: Partial<FeedbackValues> }
  | { type: 'update-credentials'; payload: Partial<CredentialsValues> }
  | { type: 'update-payment'; payload: Partial<PaymentValues> }

export const ProjectFeedbackContext = React.createContext<State>({
  review: {} as ReviewValues,
  feedback: {
    experts: [],
  } as FeedbackValues,
  credentials: {} as CredentialsValues,
  payment: {} as PaymentValues,
})
export const ProjectFeedbackDispatchContext = React.createContext<{
  onUpdateReviewStep: (review: Partial<ReviewValues>) => void
  onUpdateFeedbackStep: (feedback: Partial<FeedbackValues>) => void
  onUpdateCredentialsStep: (payment: Partial<CredentialsValues>) => void
  onUpdatePaymentStep: (payment: Partial<PaymentValues>) => void
}>({
  onUpdateReviewStep: () => undefined,
  onUpdateFeedbackStep: () => undefined,
  onUpdateCredentialsStep: () => undefined,
  onUpdatePaymentStep: () => undefined,
})
export type State = {
  review: ReviewValues
  feedback: FeedbackValues
  credentials: CredentialsValues
  payment: PaymentValues
}
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update-review':
      return { ...state, review: { ...state.review, ...action.payload } }
    case 'update-feedback':
      return { ...state, feedback: { ...state.feedback, ...action.payload } }
    case 'update-credentials':
      return {
        ...state,
        credentials: { ...state.credentials, ...action.payload },
      }
    case 'update-payment':
      return { ...state, payment: { ...state.payment, ...action.payload } }
    default: {
      return state
    }
  }
}
export const ProjectFeedbackProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    review: {
      rating: 5,
      experienceWorkingWith: '',
    },
    feedback: {
      experts: [],
    },
    credentials: {
      contributor: {
        id: '',
        tasks: [],
      },
      shadowers: [],
    },
    payment: {
      confirmation: false,
    },
  })

  const handleDispatch = useCallback((action: Action) => dispatch(action), [])

  const handleUpdateReview = useCallback(
    (details: Partial<ReviewValues>) => {
      handleDispatch({ type: 'update-review', payload: { ...details } })
    },
    [handleDispatch],
  )

  const handleUpdateFeedback = useCallback(
    (peopleAndSkills: Partial<FeedbackValues>) => {
      handleDispatch({
        type: 'update-feedback',
        payload: { ...peopleAndSkills },
      })
    },
    [handleDispatch],
  )

  const handleUpdateCredentials = useCallback(
    (credentials: Partial<CredentialsValues>) => {
      handleDispatch({
        type: 'update-credentials',
        payload: { ...credentials },
      })
    },
    [handleDispatch],
  )

  const handleUpdatePayment = useCallback(
    (terms: Partial<PaymentValues>) => {
      handleDispatch({ type: 'update-payment', payload: { ...terms } })
    },
    [handleDispatch],
  )

  return (
    <ProjectFeedbackContext.Provider value={state}>
      <ProjectFeedbackDispatchContext.Provider
        value={{
          onUpdateReviewStep: handleUpdateReview,
          onUpdateFeedbackStep: handleUpdateFeedback,
          onUpdateCredentialsStep: handleUpdateCredentials,
          onUpdatePaymentStep: handleUpdatePayment,
        }}
      >
        {children}
      </ProjectFeedbackDispatchContext.Provider>
    </ProjectFeedbackContext.Provider>
  )
}

export const useProjectFeedbackState = () => {
  const context = React.useContext(ProjectFeedbackContext)
  if (context === undefined) {
    throw new Error('useProjectFeedbackState must be used within a ProjectFeedbackContext')
  }
  return context
}

export const useProjectFeedbackDispatch = () => {
  const context = React.useContext(ProjectFeedbackDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useProjectFeedbackDispatch must be used within a ProjectFeedbackDispatchContext',
    )
  }
  return context
}
