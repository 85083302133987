import React from 'react'
import { Box, Text } from '@chakra-ui/react'
// import { convertMinutesToHours, pluralise } from '@deepwork/utils'
import { convertMinutesToHours, pluralise } from 'libs/utils/src'

interface Props {
  /**
   * Number of tasks
   */
  taskAmount: number
  /**
   * Click handler when taskAmount is clicked
   */
  onTaskClick: () => void
  /**
   * Number of hours | Range of hours will only accept the first two elements of the array
   */
  timeValue: number | number[] | null
}
export const ScopeOfWorkValue: React.FC<Props> = ({ taskAmount, onTaskClick, timeValue }) => {
  const timeValueChecked = timeValue === null ? 0 : timeValue
  const time =
    typeof timeValueChecked === 'number'
      ? [convertMinutesToHours(timeValueChecked)]
      : [convertMinutesToHours(timeValueChecked[0]), convertMinutesToHours(timeValueChecked[1])]
  return (
    <Box display="flex" alignItems="center">
      <Text variant="h6" color="primary" cursor="pointer" mr="8px" onClick={onTaskClick}>
        {taskAmount} task{taskAmount > 1 ? 's' : ''}
      </Text>
      {timeValue && (
        <Box bg="gray.28" px="6px" py="1px" borderRadius="19px">
          <Text variant="x.small" color="white">
            {time.length === 1 ? `~ ${time[0]}` : `${time[0]}-${time[1]}`}{' '}
            <Text as="span" variant="x.small" color="gray.79">
              {pluralise('hr', time.length === 1 ? time[0] : 2)}
            </Text>
          </Text>
        </Box>
      )}
    </Box>
  )
}
