import axios, { AxiosError, AxiosResponse } from 'axios'
import { environment } from '../../../environments/environment'

axios.interceptors.response.use((response: AxiosResponse) => response.data, handleReject) // eslint-disable-line

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('AUTH_TOKEN')
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error),
)
// replace base url in interceptors
axios.interceptors.request.use((config) => {
  config.baseURL = environment.BACKEND_URL
  return config
})

function handleReject(error?: AxiosError) {
  if (error?.response) {
    const { status, data } = error.response
    if (status === 401 || status === 403) {
      localStorage.removeItem('AUTH_TOKEN')
      window.location.href = '/'
    }
    if (status === 404) {
      return Promise.reject(data.error ?? data.message ?? 'Not found')
    }
    if (status === 406) {
      return Promise.reject(data.error)
    }
    return Promise.reject(data.error ?? data.message ?? 'Something went wrong')
  }
  return Promise.reject(error?.message ?? 'Something went wrong')
}

export default axios
