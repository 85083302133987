import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { FC } from 'react'
// import { AdminCreateButton, ChipInput, CreateRoleModal, RoleItem, RoundedBox } from '@deepwork/ui-components'
// import { IRoleValues, OrganisationRolesDTO } from '@deepwork/interfaces'
import {
  AdminCreateButton,
  ChipInput,
  CreateRoleModal,
  RoleItem,
  RoundedBox,
} from 'libs/ui-components/src'
import { IRoleValues, OrganisationRolesDTO } from 'libs/interfaces/src'
import { useNewOrganisationDispatch, useNewOrganisationState } from '../../NewOrganisationContext'

interface Props {
  isEdit?: boolean
  onBack?: () => void
  onNext: () => void
  nextLabel?: string
  presetRoles?: OrganisationRolesDTO[]
  managers?: string[]
}

export const PeopleAndSkillsStep: FC<Props> = ({
  presetRoles,
  managers,
  isEdit,
  onBack,
  onNext,
  nextLabel = 'Next',
}) => {
  const { peopleAndSkills } = useNewOrganisationState()
  const { onUpdatePeopleAndSkills } = useNewOrganisationDispatch()
  const { emailList, roles } = peopleAndSkills
  const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false)
  const [editRole, setEditRole] = React.useState<IRoleValues & { roleNumber: number }>()
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const presetRolesMap =
    presetRoles?.map((role) => ({
      ...role,
      // @ts-ignore
      assignees: [...role.experts],
    })) ?? []

  const handleSetEmailList = (emailList: string[]) => { // eslint-disable-line
    onUpdatePeopleAndSkills({ emailList })
    setInvalidEmail(!emailList.every((email) => email.includes('@')))
  }

  const handleNext = () => {
    onUpdatePeopleAndSkills({ emailList, roles })
    onNext()
  }

  const handleSubmit = (role: IRoleValues) => {
    const foundRole = roles.find((r) => r.id === role.id)
    if (foundRole) {
      onUpdatePeopleAndSkills({
        roles: roles.map((r) => (r.id === role.id ? role : r)),
      })
    } else {
      onUpdatePeopleAndSkills({
        roles: [...roles, role],
      })
    }
    onClose()
  }

  const handleOnClose = () => {
    setEditRole(undefined)
    setIsReadOnly(false)
    onClose()
  }

  const handleAddNew = () => {
    onOpen()
  }

  const handleRemove = (id: string) => {
    onUpdatePeopleAndSkills({
      roles: roles.filter((r) => r.id !== id),
    })
  }

  const handleEditRole = (roleId: string) => {
    const roleIndex = roles.findIndex((r) => r.id === roleId)
    if (roleIndex !== -1) {
      setEditRole({ ...roles[roleIndex], roleNumber: roleIndex })
      onOpen()
    }
  }

  const handlePreviewPresetRole = (roleId: string) => {
    setIsReadOnly(true)
    if (presetRolesMap) {
      const roleIndex = presetRolesMap.findIndex((r) => r.id === roleId)
      if (roleIndex !== -1) {
        setEditRole({ ...presetRolesMap?.[roleIndex], roleNumber: roleIndex, isDisabled: true })
        onOpen()
      }
    }
  }

  return (
    <>
      <Box>
        <Text variant="h2" color="white" mb="25px">
          Add manager(s)
        </Text>
        <RoundedBox mb="43px">
          <FormControl isInvalid={!!invalidEmail}>
            <FormLabel>
              <Text variant="h4" color="white" mb="14px">
                Invite by email
              </Text>
              <Text variant="h6" color="gray.79" mb="16px">
                You can enter multiple emails in the below field separated by commas. Make sure all
                of them have a Deep Skills account.
              </Text>
            </FormLabel>
            <ChipInput
              isInvalid={!!invalidEmail}
              name={'emailList'}
              chips={emailList}
              readOnlyChips={managers}
              onChange={handleSetEmailList}
              placeholder="charlie@deepwork.studio, vikrant@deepwork.studio, balint@deepwork.studio"
            />
            {!!invalidEmail && <FormErrorMessage>Please enter a valid email</FormErrorMessage>}
          </FormControl>
        </RoundedBox>

        <Text variant="h2" color="white" mb="25px">
          Add role(s)
        </Text>
        {presetRolesMap && presetRolesMap.length > 0 && (
          <Box mb={'25px'}>
            {presetRolesMap.map((role) => (
              <RoleItem
                key={role.id}
                roleId={role.id}
                title={role.title}
                assignees={role.assignees}
                tasksCount={role.tasks.length}
                isReadOnly={isEdit}
                onEdit={handlePreviewPresetRole}
                boxProps={{
                  mb: '15px',
                }}
              />
            ))}
          </Box>
        )}
        {roles && roles.length > 0 && (
          <Box mb={'25px'}>
            {roles.map((role) => (
              <RoleItem
                key={role.id}
                roleId={role.id}
                title={role.title}
                assignees={role.assignees}
                tasksCount={role.tasks.length}
                onEdit={handleEditRole}
                onDelete={handleRemove}
                boxProps={{
                  mb: '15px',
                }}
              />
            ))}
          </Box>
        )}
        <AdminCreateButton onClick={handleAddNew}>Add a new role</AdminCreateButton>

        <Box mt={'49px'}>
          {onBack && (
            <Button variant="link" mr="40px" onClick={onBack} data-testid={'back-btn'}>
              Back
            </Button>
          )}
          <Button onClick={handleNext} data-testid={'next-btn'}>
            {nextLabel}
          </Button>
        </Box>
      </Box>

      {isOpen && (
        <CreateRoleModal
          isOpen={isOpen}
          isReadOnly={isReadOnly}
          roleNumber={(editRole ? editRole.roleNumber : roles.length) + 1}
          onClose={handleOnClose}
          onSave={handleSubmit}
          role={editRole}
        />
      )}
    </>
  )
}
