import React, { useEffect } from 'react'
// import { Box, Button, Text, useDisclosure } from '@chakra-ui/react'
// import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
// import { APP_PATHS } from '../../paths'
// import { AppContainer } from '../../components/app-container'
// import { EmailWasVerified } from '@deepwork/ui-components'
import { EmailWasVerified } from 'libs/ui-components/src'
import { useUserContextStateDispatch } from '../../shared/contexts/user-context-provider'
import { useVerifyEmailMutation } from '../../shared/mutations/user'

export const Home = () => {
  // const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { mutate: verifyEmail } = useVerifyEmailMutation()
  const {
    isOpen: isEmailVerifiedOpen,
    onOpen: onEmailVerifiedOpen,
    onClose: onEmailVerifiedClose,
  } = useDisclosure()
  const { onOpenChoosePasswordModal } = useUserContextStateDispatch()
  // const handleOpenOrganisation = () => {
  //   navigate(APP_PATHS.organisations)
  // }

  useEffect(() => {
    const emailToken = searchParams.get('emailVerificationToken')
    if (emailToken) {
      verifyEmail(emailToken, {
        onSuccess: () => {
          onEmailVerifiedOpen()
        },
      })
    }
  }, [onEmailVerifiedOpen, searchParams])

  useEffect(() => {
    if (searchParams.get('resetToken')) {
      onOpenChoosePasswordModal(searchParams.get('resetToken') as string)
    }
  }, [onEmailVerifiedOpen, searchParams])

  return (
    <>
      <EmailWasVerified isOpen={isEmailVerifiedOpen} onClose={onEmailVerifiedClose} />
    </>
  )
}
