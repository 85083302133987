import React, { FC } from 'react'
import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
// import { GetProjectDTO, IOrganisation, IProjectRolePreview } from '@deepwork/interfaces'
import { GetProjectDTO, IOrganisation, IProjectRolePreview } from 'libs/interfaces/src'
import ProjectModalHeader from '../../layout/project-modal-header/project-modal-header'
import { CollapsableTaskSet } from '../../layout/collapsable-task-set/collapsable-task-set'
import { TaskItem } from '../../layout/task-item/task-item'
import { StaticCheckbox } from '../../data-display/statick-checkbox/statick-checkbox'
import { FeedbackItem } from '../../layout/feedback-item/feedback-item'
import { useUserContextState } from 'app/shared/contexts/user-context-provider'

export interface ProjectSummaryProps {
  isOpen: boolean
  username: string
  onCloseClicked: () => void
  onProjectReview: (roleId: string, isExpert: boolean) => void
  organisation: Pick<IOrganisation, 'name' | 'imageUrl'>
  project: Pick<GetProjectDTO, 'id' | 'name' | 'description' | 'dateRange' | 'tag'>
  roles?: IProjectRolePreview[]
}

interface FeedbackBlockProps {
  title: string
  boxProps?: BoxProps
}
const FeedbackBlock: React.FC<FeedbackBlockProps> = ({ title, children, boxProps }) => (
  <Box p="18px 25px 21px" borderBottom="1px solid" borderColor="gray.18" {...boxProps}>
    <Text variant="h3" color="white" mb="18px">
      {title}
    </Text>
    {children}
  </Box>
)

export const ProjectSummary: FC<ProjectSummaryProps> = ({
  username,
  isOpen,
  onCloseClicked,
  onProjectReview,
  organisation,
  project,
  roles,
}) => {
  const { user } = useUserContextState()
  const handleProjectReview = (roleId: string, isExpert: boolean) => () => {
    onProjectReview(roleId, isExpert)
  }

  const displayName = username === user?.username ? 'you' : username

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onCloseClicked}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg="gray.22" pb="30px">
          <ProjectModalHeader
            projectId={project.id}
            organisationName={organisation.name}
            organisationImageUrl={organisation.imageUrl}
            name={project.name}
            description={project.description}
            dateRange={project.dateRange}
            tag={project.tag}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody bg="gray.24" p="0">
          <Box p="18px 25px 21px" borderBottom="1px solid" borderColor="gray.18">
            <Text variant="h3" color="white" mb="18px">
              Completed work
            </Text>
            {roles?.map((role, index) => (
              <Box key={index} mb="15px">
                <CollapsableTaskSet
                  label={role.title}
                  helperText={`${role.assignedTasks}/${role.allTasks} completed`}
                  boxProps={{
                    bg: 'gray.22',
                  }}
                >
                  <Box bg="gray.22" p="17px 17px 25px 17px" borderRadius="0 0 12px 12px">
                    {role.tasks.map((item, index) => (
                      <TaskItem
                        key={index}
                        title={item.title}
                        description={item.description}
                        helperText={''}
                        beforeElement={<StaticCheckbox variant="confirmed" />}
                        boxProps={{ bg: 'gray.24', mb: '13px' }}
                      />
                    ))}
                    {role.reviewed ? (
                      role.reviews.map((review) => (
                        <>
                          {!!review.didWell && (
                            <FeedbackBlock title={`What ${displayName} did well`}>
                              <FeedbackItem
                                key={index}
                                content={`"${review.didWell}"`}
                                user={review.actor}
                              />
                            </FeedbackBlock>
                          )}
                          {!!review.needsImprovement && (
                            <FeedbackBlock title={`What ${displayName} can improve upon`}>
                              <FeedbackItem
                                key={index}
                                content={`"${review.needsImprovement}"`}
                                user={review.actor}
                              />
                            </FeedbackBlock>
                          )}
                          {!!review.personalNote && (
                            <FeedbackBlock title={`Private note`}>
                              <FeedbackItem
                                key={index}
                                content={`"${review.personalNote}"`}
                                user={review.actor}
                              />
                            </FeedbackBlock>
                          )}
                        </>
                      ))
                    ) : (
                      <Box
                        w="100%"
                        h="220px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        px="50px"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          <Text mb="16px" px="100px" variant="h6" textAlign="center">
                            You need to complete your review before you can view the feedback.
                          </Text>
                          {username === user?.username ? (
                            <Button onClick={handleProjectReview(role.id, role.isExpert)}>
                              Review
                            </Button>
                          ) : null}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </CollapsableTaskSet>
              </Box>
            ))}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
