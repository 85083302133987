import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'
// import { generateColorFromString } from '@deepwork/utils'
import { generateColorFromString } from 'libs/utils/src'

interface Props {
  /**
   * Tag value to display
   */
  value: string
  disabled?: boolean
  selected?: boolean
  onClick?: (value: string) => void
  textProps?: TextProps
}

function splitCamelCase(value: string) {
  return value.replace(/([A-Z])/g, ' $1')
}

export const Tag: React.FC<Props> = ({ disabled, selected, value, onClick, textProps }) => {
  const color = generateColorFromString(value)
  const background = disabled ? 'gray.28' : color
  const fontColor = disabled ? 'gray.50' : selected ? 'white' : color

  const handleClick = () => {
    onClick && onClick(value)
  }
  return (
    <Text
      {...textProps}
      variant="xx.small"
      as="span"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      px="12px"
      py="3px"
      height="20px"
      borderRadius="17px"
      color={fontColor}
      border="1px solid"
      textTransform="capitalize"
      borderColor={background}
      bg={selected || disabled ? background : 'transparent'}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      onClick={disabled ? () => undefined : handleClick}
    >
      {splitCamelCase(value)}
    </Text>
  )
}
