import { useNavigate, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { APP_PATHS } from '../../../paths'
import { AppContainer } from '../../../components/app-container'
import { Container, Text } from '@chakra-ui/react'
// import { Stepper } from '@deepwork/ui-components'
// import { ProjectFeedback } from '@deepwork/interfaces'
import { Stepper } from 'libs/ui-components/src'
import { ProjectFeedback } from 'libs/interfaces/src'
import { ReviewStep } from './steps/review-step'
import { FeedbackStep } from './steps/feedback-step'
import { PaymentStep } from './steps/payment-step'
import { CredentialsStep } from './steps/credentials-step'
import { ProjectFeedbackProvider, useProjectFeedbackState } from './ProjectFeedbackProvider'
import { useRoleDetails } from '../../../shared/queries/useRoles'
import { useProjectFeedbackSubmitMutation } from '../../../shared/mutations/useProjectFeedbackMutations'
import { PerformanceTypeEnum } from './enums'
import { useProjectLeadFeedbackDetails } from '../../../shared/queries/project'

const ProjectLeadFeedbackComponent = () => {
  const params = useParams<{ projectId: string; roleId: string }>()
  const navigate = useNavigate()
  const { review, feedback, credentials } = useProjectFeedbackState()
  const { data: reviewDetails, isLoading } = useProjectLeadFeedbackDetails(params.roleId ?? '')
  const { mutate, isLoading: isSubmitting } = useProjectFeedbackSubmitMutation(
    params.projectId ?? '',
    'expert',
  )
  const { data: roleDetails } = useRoleDetails(params.roleId ?? '')
  const [activeStep, setActiveStep] = useState(1)
  const steps = [
    { label: 'Review' },
    { label: 'Feedback' },
    { label: 'Credentials' },
    { label: 'Payment' },
  ]

  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handlePrevStep = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  const handleSubmitProject = () => {
    if (!params.roleId) return
    const expertFeedbackValues = feedback.experts[reviewDetails?.projectOwner ? 1 : 0]
    const data: ProjectFeedback = {
      review: {
        endorsementRating: review.rating,
        projectExperience: review.experienceWorkingWith,
      },
      rolesFeedback: [
        {
          id: params.roleId ?? '',
          workRatio: feedback.experts[0]?.split,
          performanceType: feedback.experts[0]?.performanceType ?? PerformanceTypeEnum.leader,
          ownerReview: reviewDetails?.projectOwner
            ? {
                id: feedback.experts[0]?.id,
                didWell: feedback.experts[0]?.didWell,
                needsImprovement: feedback.experts[0]?.needsImprovement,
                personalNote: feedback.experts[0]?.personalNote,
              }
            : undefined,
          actorReview: feedback.experts
            ? {
                id: expertFeedbackValues?.id,
                didWell: expertFeedbackValues?.didWell,
                needsImprovement: expertFeedbackValues?.needsImprovement,
                personalNote: expertFeedbackValues?.personalNote,
              }
            : undefined,
          tasks: reviewDetails?.contributor?.id
            ? {
                contributor: {
                  id: reviewDetails?.contributor?.id ?? '',
                  tasks: credentials.contributor.tasks,
                },
                shadowers: credentials.shadowers ?? [],
              }
            : undefined,
        },
      ],
    }
    mutate(data, {
      onSuccess: () => {
        navigate(APP_PATHS.projectCompleted.replace(':projectId', params.projectId ?? ''))
      },
      onError: (error) => {
        console.log(error)
      },
    })
  }
  const peopleToReview = reviewDetails?.contributor
    ? [{ ...reviewDetails.contributor, extendForm: true }]
    : []

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          How was your collaboration?
        </Text>
      </AppContainer.Header>
      <Container maxW="container.lg" mb="-16px">
        <Stepper activeStep={activeStep} steps={steps} />
      </Container>
      {!isLoading && reviewDetails && (
        <AppContainer.Content isDetails pt={70}>
          {activeStep === 1 && <ReviewStep onNext={handleNextStep} />}
          {activeStep === 2 && (
            <FeedbackStep
              onNext={handleNextStep}
              onBack={handlePrevStep}
              peopleToReview={
                reviewDetails.projectOwner
                  ? [...peopleToReview, reviewDetails.projectOwner]
                  : [...peopleToReview]
              }
              possiblePerformanceTypes={[
                {
                  label: 'Student',
                  description: 'Can benefit from mentorship and guidance',
                  value: PerformanceTypeEnum.student,
                },
                {
                  label: 'Collaborator',
                  description: 'Can complete chosen tasks with a bit of support',
                  value: PerformanceTypeEnum.collaborator,
                },
                {
                  label: 'Professional',
                  description: 'Can complete most tasks fully autonomously',
                  value: PerformanceTypeEnum.professional,
                },
              ]}
            />
          )}
          {activeStep === 3 && (
            <CredentialsStep
              onNext={handleNextStep}
              onBack={handlePrevStep}
              tasks={reviewDetails?.tasks}
              colleague={reviewDetails.contributor}
              allRoleTasks={roleDetails?.tasks ?? []}
            />
          )}
          {activeStep === 4 && (
            <PaymentStep
              paymentSystemLink={reviewDetails?.externalPayment ?? ''}
              mySalary={reviewDetails.payment}
              onSubmit={handleSubmitProject}
              onBack={handlePrevStep}
              isSubmitting={isSubmitting}
              checkboxLabel="I have requested payment through the process above."
            />
          )}
        </AppContainer.Content>
      )}
    </>
  )
}

export const ProjectLeadFeedback = () => (
  <ProjectFeedbackProvider>
    <ProjectLeadFeedbackComponent />
  </ProjectFeedbackProvider>
)
