import { Text } from '@chakra-ui/react'
import { AppContainer } from '../../../components/app-container'
import React from 'react'
import { RolesStep } from './steps/RolesStep'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'
import { ProjectFlowContextProvider, useProjectFlowState } from './ProjectFlowContextProvider'
import { useProjectDetails } from '../../../shared/queries/project'
import { useEditProjectMutation } from '../../../shared/mutations/project'
import { useOrganisationRoles } from '../../../shared/queries/organisation'

const isEqual = require('lodash.isequal')

const EditProjectComponent = () => {
  const { deadline, roles, details } = useProjectFlowState()
  const params = useParams<{ projectId: string }>()
  const { data: project } = useProjectDetails(params.projectId ?? '')
  const navigate = useNavigate()
  const { mutate: onEditProject } = useEditProjectMutation(params.projectId ?? '', {
    onSuccess: (response) => { // eslint-disable-line
      navigate(APP_PATHS.projectDetails.replace(':projectId', params.projectId ?? ''))
    },
  })
  const { data: presetRoles } = useOrganisationRoles(project?.organization?.id ?? '')

  const handleSubmitProject = () => {
    // TODO: makes more efficient loops and get deletedRoles, updatedRoles, roles in one loop
    const deletedRoles = project?.roles.filter((pr) => {
      return !roles.find((r) => r.uId === pr.uId)
    })
    const updatedRoles = roles.filter((r) => {
      const projectRole = project?.roles.find((pr) => pr.uId === r.uId)
      if (projectRole) {
        const projectRoleFieldsToCheck = {
          // contributor: projectRole.contributor,
          expertId: projectRole.expert?.actor?.id,
          dates: [
            new Date(projectRole.dates[0]).toDateString(),
            new Date(projectRole.dates[1]).toDateString(),
          ],
          payment: projectRole.payment,
          mentoringBonus: projectRole.mentoringBonus,
        }

        const roleFieldsToCheck = {
          expertId: r.assignee?.id,
          dates: [
            new Date(r?.workBeginDate).toDateString(),
            new Date(r?.workEndDate).toDateString(),
          ],
          payment: r.payment,
          mentoringBonus: r.mentoringBonus,
        }

        return !isEqual(projectRoleFieldsToCheck, roleFieldsToCheck)
      }
    })

    const mappedDetails = {
      name: details.name,
      description: details.description,
      tag: details.tag?.value,
      stakeholder: details.stakeholder?.value,
      ...deadline,
      documentUrl: details.documentUrl,
      organisationId: details.organisation?.value,
      deletedRoles,
      updatedRoles,
      roles: roles.filter(
        (role) => !project?.roles.find((projectRole) => projectRole.uId === role.uId),
      ),
    }
    onEditProject(mappedDetails)
  }

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          Edit {project?.name}
        </Text>
      </AppContainer.Header>
      <AppContainer.Content isDetails pt={70}>
        <RolesStep
          isEditProject
          presetRoles={presetRoles ?? []}
          onNext={handleSubmitProject}
          nextButtonText="Save"
        />
      </AppContainer.Content>
    </>
  )
}

export const EditProject = () => {
  const params = useParams<{ projectId: string }>()
  const { data: project } = useProjectDetails(params.projectId ?? '')

  return (
    <ProjectFlowContextProvider existingProject={project}>
      <EditProjectComponent />
    </ProjectFlowContextProvider>
  )
}
