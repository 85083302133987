import { useQuery, UseQueryResult } from 'react-query'
import { QueriesKeysEnum } from './queries-keys-enum'
import axios from '../api/setup'
// import {
//   GetActorRoleDetailsDTO,
//   GetActorRolesDTO,
//   GetCoworkersDto,
//   GetProjectPreviewDTO,
//   GetUserProfileDto,
//   IProject
// } from '@deepwork/interfaces'
import {
  GetActorRoleDetailsDTO,
  GetActorRolesDTO,
  GetCoworkersDto,
  GetProjectPreviewDTO,
  GetUserProfileDto,
  IProject,
} from 'libs/interfaces/src'
import { useToast } from '@chakra-ui/react'

export const useUserProfile = (username: string) => {
  const toast = useToast()

  const {
    data: profile,
    isLoading: isProfileLoading,
    refetch,
  }: UseQueryResult<GetUserProfileDto> = useQuery(
    [QueriesKeysEnum.userProfile, username],
    () => axios.get(`/actor/${username}/profile`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
  const { data: coworkers, isLoading: isCoworkersLoading }: UseQueryResult<GetCoworkersDto[]> =
    useQuery(
      [QueriesKeysEnum.userCoworkers, username],
      () => axios.get(`/actor/${username}/coworkers`),
      {
        onError: (error: string) => {
          toast({ position: 'top-right', status: 'error', title: error })
        },
      },
    )
  const { data: projects, isLoading: isProjectsLoading }: UseQueryResult<IProject[]> = useQuery(
    [QueriesKeysEnum.userProjects, username],
    () => axios.get(`/actor/${username}/projects`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
  const { data: roles, isLoading: isRolesLoading }: UseQueryResult<GetActorRolesDTO[]> = useQuery(
    [QueriesKeysEnum.userRoles, username],
    () => axios.get(`/actor/${username}/roles`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )

  return {
    profile,
    coworkers,
    projects,
    roles,
    refetchProfile: refetch,
    isLoading: isCoworkersLoading || isProfileLoading || isProjectsLoading || isRolesLoading,
  }
}

export const useUserRoleDetails = (
  roleId: string,
  username: string,
): UseQueryResult<GetActorRoleDetailsDTO> => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.userRoleDetails, roleId],
    () => axios.get(`/role/${roleId}/actor/${username}`),
    {
      cacheTime: 0,
      enabled: !!roleId && !!username,
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}

export const useCompleteProjectPreview = (
  username: string,
  projectId: string,
): UseQueryResult<GetProjectPreviewDTO> => {
  const toast = useToast()

  return useQuery(
    [QueriesKeysEnum.completeProjectPreview, projectId],
    () => axios.get(`/actor/${username}/project/${projectId}/preview`),
    {
      enabled: !!username && !!projectId,
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
    },
  )
}
