import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { FC } from 'react'

export interface PleaseVerifyEmailProps {
  isOpen: boolean
  onClose: () => void
}

export const PleaseVerifyEmail: FC<PleaseVerifyEmailProps> = ({ isOpen, onClose }) => {
  return (
    <Modal size="sm" isOpen={isOpen} onClose={onClose} returnFocusOnClose={false} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please verify your email</ModalHeader>
        <ModalCloseButton data-testid="close-modal" />
        <ModalBody>
          <Text color="white" variant="h6">
            We’ve sent you an email to verify your account. Please confirm it in order to start
            using Deep Skills.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PleaseVerifyEmail
