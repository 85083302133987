export enum QueriesKeysEnum {
  organisations = 'organisations',
  organisationDetails = 'organisationDetails',
  organisationExperts = 'organisationExperts',
  organisationRoles = 'organisationRoles',
  expertsForOrgRole = 'expertsForOrgRole',
  organisationManagers = 'organisationManagers',
  organisationRoleExperts = 'organisationRoleExperts',
  editOrganisationRoles = 'editOrganisationRoles',
  orgListForManagers = 'orgListForManagers',
  organisationRoleDetails = 'organisationRoleDetails',
  projects = 'projects',
  projectDetails = 'projectDetails',
  completeProjectPreview = 'completeProjectPreview',
  projectOwnerFeedbackDetails = 'projectOwnerFeedbackDetails',
  projectLeadFeedbackDetails = 'projectLeadFeedbackDetails',
  projectContributorFeedbackDetails = 'projectContributorFeedbackDetails',
  projectTagList = 'projectTagList',
  roleDetails = 'roleDetails',
  userProfile = 'userProfile',
  userCoworkers = 'userCoworkers',
  userProjects = 'userProjects',
  userRoles = 'userRoles',
  userRoleDetails = 'userRoleDetails',
  users = 'users',
  user = 'user',
}
