function getSize(size: string) {
  return {
    container: {
      width: size,
      height: size,
    },
    excessLabel: {
      width: size,
      height: size,
    },
  }
}

const sizes = {
  xs: getSize('25px'),
  sm: getSize('35px'),
  md: getSize('48px'),
  lg: getSize('60px'),
  xl: getSize('130px'),
}

export const Avatar = {
  sizes,
}
