import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import axios from '../api/setup'
// import { CreateOrganisationDTO, EditOrganizationDTO } from '@deepwork/interfaces'
import { CreateOrganisationDTO, EditOrganizationDTO } from 'libs/interfaces/src'
import { convertToFormData } from '../utils/api'
import { useToast } from '@chakra-ui/react'

export const useCreateOrganisationMutation = (
  options?: UseMutationOptions<string, string, { data: CreateOrganisationDTO; logo: any }>,
): UseMutationResult<string, string, { data: CreateOrganisationDTO; logo: any }> => {
  const toast = useToast()
  return useMutation(
    async ({ data, logo }) => {
      return axios.post('/org', convertToFormData({ ...data, image: logo }))
    },
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}

export const useEditOrganisationMutation = (
  organisationId: string,
  options?: UseMutationOptions<string, string, EditOrganizationDTO>,
): UseMutationResult<string, string, EditOrganizationDTO> => {
  const toast = useToast()
  return useMutation(async (data) => axios.put(`/org/${organisationId}`, data), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}
