import { Avatar, Box, BoxProps, Button, Text } from '@chakra-ui/react'
// import { pluralise } from '@deepwork/utils'
// import { IUser } from '@deepwork/interfaces'
import { pluralise } from 'libs/utils/src'
import { IUser } from 'libs/interfaces/src'
import { RoundedBox } from '../rounded-box/rounded-box'
import React, { FC } from 'react'

interface Props {
  isReadOnly?: boolean
  roleId: string
  title: string
  tasksCount: number
  assignees?: string[]
  assignee?: Pick<IUser, 'id' | 'username' | 'avatarUrl'> | null
  onEdit?: (roleId: string) => void
  onDelete?: (roleId: string) => void
  boxProps?: BoxProps
}
export const RoleItem: FC<Props> = ({
  isReadOnly,
  roleId,
  title,
  tasksCount,
  assignees,
  assignee,
  onEdit,
  onDelete,
  boxProps,
}) => {
  const handleDelete = () => {
    onDelete && onDelete(roleId)
  }
  const handleEdit = () => {
    onEdit && onEdit(roleId)
  }
  return (
    <RoundedBox
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      justifyContent={{ base: 'flex-start', md: 'space-between' }}
      p={'28px 25px'}
      {...boxProps}
    >
      <Box
        width="100%"
        display="flex"
        alignItems={{ base: 'flex-start', md: 'center' }}
        justifyContent="space-between"
        mb={{ base: '23px', md: 0 }}
      >
        <Text variant={'h4'} color="white" mr={'50px'} isTruncated maxW="150px">
          {title}
        </Text>
        <Text variant={'h4'} color="white" mr="25px">
          {tasksCount} {pluralise('task', tasksCount)}
        </Text>
        {assignees && assignees.length > 0 && (
          <Text variant={'h4'} color="white">
            {assignees?.length} {pluralise('assignee', assignees.length)}
          </Text>
        )}
        {assignee && <Avatar src={assignee.avatarUrl} size="sm" />}
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
      >
        {handleDelete && !isReadOnly && (
          <Button
            variant="link"
            color="warning"
            data-testid={`delete-role-btn-${roleId}`}
            onClick={handleDelete}
            mr={'92px'}
          >
            Delete role
          </Button>
        )}
        {handleEdit && (
          <Button variant="link" data-testid={`edit-role-btn-${roleId}`} onClick={handleEdit}>
            {isReadOnly ? 'View role' : 'Edit role'}
          </Button>
        )}
      </Box>
    </RoundedBox>
  )
}
