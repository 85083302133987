import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import React, { ChangeEvent, FC } from 'react'
// import { RoundedBox } from '@deepwork/ui-components'
import { RoundedBox } from 'libs/ui-components/src'
import { useProjectFeedbackDispatch, useProjectFeedbackState } from '../../ProjectFeedbackProvider'

interface Props {
  onNext: () => void
}
export const ReviewStep: FC<Props> = ({ onNext }) => {
  const { review } = useProjectFeedbackState()
  const { onUpdateReviewStep } = useProjectFeedbackDispatch()

  const handleChangeSlider = (value: number) => {
    onUpdateReviewStep({ rating: value })
  }

  const handleChangeFeedback = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdateReviewStep({ experienceWorkingWith: event.target.value })
  }

  const handleNext = () => {
    onNext()
  }

  return (
    <Box>
      <Text variant="h2" color="white" mb="14px">
        Please review the work
      </Text>
      <Text variant="h6" mb="25px">
        This will be public and help other applicants understand why they have not been selected.
      </Text>
      <RoundedBox mb="25px">
        <FormControl>
          <FormLabel mb="37px">
            <Text color="white" variant="h4">
              How enjoyable was the project?
            </Text>
            <Text mt="8px" color="gray.79">
              Please give the project an overall rating.
            </Text>
          </FormLabel>
          <Box>
            <Box display="flex" justifyContent="space-between" mb="9px">
              <Box display="flex" flexDirection="column">
                <Text variant="small" color="gray.79" mb="8px">
                  Not enjoyable
                </Text>
                <Text variant="h2" color="white">
                  0
                </Text>
              </Box>
              <Box display="flex" flexDirection="column">
                <Text variant="small" color="gray.79" mb="8px">
                  Exciting
                </Text>
                <Text variant="h2" color="white">
                  10
                </Text>
              </Box>
            </Box>
            <Box>
              <Slider onChange={handleChangeSlider} step={1} min={0} max={10} value={review.rating}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Box>
          </Box>
        </FormControl>
      </RoundedBox>
      <RoundedBox mb="43px">
        <FormControl>
          <FormLabel mb="37px">
            <Text color="white" variant="h4">
              How was your experience working on the project?
            </Text>
            <Box mt="8px">
              <Text color="gray.79">
                What did you learn? What went well? How could you have done better?
              </Text>
              <Text color="gray.79">
                This will be public and added to the project details so others can understand your
                trajectory of development.
              </Text>
            </Box>
          </FormLabel>
          <Textarea
            value={review.experienceWorkingWith}
            placeholder="I could’ve put more efforts on…"
            onChange={handleChangeFeedback}
          />
        </FormControl>
      </RoundedBox>
      <Button onClick={handleNext}>Next</Button>
    </Box>
  )
}
