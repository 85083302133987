import React from 'react'
import { Outlet } from 'react-router-dom'
import { AppContainer } from '../../components/app-container'

export const Organisations = () => {
  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  )
}
