import { useLocation } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/react'
import { AppContainer } from '../../components/app-container'
import React from 'react'
// import { RoundedBox } from '@deepwork/ui-components'
import { RoundedBox } from 'libs/ui-components/src'

export const NotFound = () => {
  const location = useLocation()

  return (
    <AppContainer>
      <AppContainer.Header />
      <AppContainer.Content>
        <Box>
          <Text variant="h1" color="white">
            404. That’s an error.
          </Text>
          <RoundedBox mt="21px" mb="21px" p="10px 25px">
            <Text color="gray.79">
              The requested {location.pathname} was not found on this server. That’s all we know.
            </Text>
          </RoundedBox>
        </Box>
      </AppContainer.Content>
    </AppContainer>
  )
}
