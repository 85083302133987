import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { APP_PATHS } from './paths'
// import { Home } from './pages/home'
import { Layout } from './components/layout'
import { UserProfile } from './pages/user-profile'
import {
  EditOrganisation,
  NewOrganisation,
  OrganisationDetails,
  Organisations,
  OrganisationsList,
} from './pages/organisations'
import {
  ConfirmApplication,
  ConfirmApplicationSuccess,
  EditProject,
  NewProject,
  ProjectApplication,
  ProjectApplicationSuccess,
  ProjectCompleted,
  ProjectContributorFeedback,
  ProjectDetails,
  ProjectLeadFeedback,
  ProjectOwnerFeedback,
  Projects,
  ProjectsList,
} from './pages/projects'
import { NotFound } from './pages/not-found'

function App() {
  return (
    <Routes>
      <Route path={APP_PATHS.home} element={<Layout />}>
        {/* <Route element={<Home />} index /> */}
        <Route index element={<ProjectsList />} />
        <Route path={APP_PATHS.organisations} element={<Organisations />}>
          <Route path={APP_PATHS.organisationDetails} element={<OrganisationDetails />} />
          <Route path={APP_PATHS.newOrganisation} element={<NewOrganisation />} />
          <Route path={APP_PATHS.editOrganisation} element={<EditOrganisation />} />
          <Route index element={<OrganisationsList />} />
        </Route>
        <Route path={APP_PATHS.projects} element={<Projects />}>
          <Route path={APP_PATHS.projectDetails} element={<ProjectDetails />} />
          <Route path={APP_PATHS.newProject} element={<NewProject />} />
          <Route path={APP_PATHS.editProject} element={<EditProject />} />
          <Route path={APP_PATHS.projectCompleted} element={<ProjectCompleted />} />
          <Route path={APP_PATHS.applyToProject} element={<ProjectApplication />} />
          <Route path={APP_PATHS.applyToProjectSuccess} element={<ProjectApplicationSuccess />} />
          <Route path={APP_PATHS.projectLeadFeedback} element={<ProjectLeadFeedback />} />
          <Route
            path={APP_PATHS.projectContributorFeedback}
            element={<ProjectContributorFeedback />}
          />
          <Route path={APP_PATHS.projectOwnerFeedback} element={<ProjectOwnerFeedback />} />
          <Route path={APP_PATHS.confirmCollaborator} element={<ConfirmApplication />} />
          <Route
            path={APP_PATHS.confirmCollaboratorSuccess}
            element={<ConfirmApplicationSuccess />}
          />
          <Route index element={<ProjectsList />} />
        </Route>
        <Route path={APP_PATHS.userProfile} element={<UserProfile />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default App
