import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react'
import React, { FC } from 'react'

interface Props {
  isOpen: boolean
  onCloseClicked: () => void
  onSubmit: (message: string) => void
  applicantName: string
  applicationType: string
}

export const ConfirmExpertModal: FC<Props> = ({
  isOpen,
  onCloseClicked,
  applicantName,
  applicationType,
  onSubmit,
}) => {
  const [message, setMessage] = React.useState('')

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)

  const handleSubmit = () => onSubmit(message)

  const isShadow = applicationType === 'shadow'
  let headerText = 'Confirm Application'
  let labelText = `Why would you like to work with ${applicantName}?`
  if (isShadow) {
    headerText = 'Confirm "Shadow" Application'
    labelText = `Why would you like to add ${applicantName} as shadow?`
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onCloseClicked}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader bg="gray.22" pb="0">
          {headerText}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="32px 25px">
          <FormControl mb="32px">
            <FormLabel>{labelText}</FormLabel>
            <Textarea value={message} onChange={onMessageChange} />
          </FormControl>
          <Button onClick={handleSubmit} disabled={!message} size="sm">
            Add to project
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
