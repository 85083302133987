import { AppContainer } from '../../../components/app-container'
// import { RoundedBox } from '@deepwork/ui-components'
import { RoundedBox } from 'libs/ui-components/src'
import { Box, Button, Text } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'

export const ConfirmApplicationSuccess = () => {
  const params = useParams<{ roleId: string; projectId: string; username: string }>()
  const navigate = useNavigate()

  const handleGoToProject = () => {
    if (params.projectId) {
      navigate(APP_PATHS.projectDetails.replace(':projectId', params.projectId))
    }
  }

  return (
    <>
      <AppContainer.Content>
        <Box>
          <Text variant="h1" color="white">
            Thank you for your confirmation!
          </Text>
          <RoundedBox mt="21px" mb="21px" p="10px 25px">
            <Text color="gray.79">Enjoy your work with {params.username}!</Text>
          </RoundedBox>
          <Button onClick={handleGoToProject}>Back to project</Button>
        </Box>
      </AppContainer.Content>
    </>
  )
}
