import { useNavigate, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { APP_PATHS } from '../../../paths'
import { AppContainer } from '../../../components/app-container'
import { Container, Text } from '@chakra-ui/react'
// import { Stepper } from '@deepwork/ui-components'
// import { ProjectFeedback } from '@deepwork/interfaces'
import { Stepper } from 'libs/ui-components/src'
import { ProjectFeedback } from 'libs/interfaces/src'
import { ReviewStep } from './steps/review-step'
import { FeedbackStep } from './steps/feedback-step'
import { PaymentStep } from './steps/payment-step'
import { useProjectOwnerFeedbackDetails } from '../../../shared/queries/project'
import { ProjectFeedbackProvider, useProjectFeedbackState } from './ProjectFeedbackProvider'
import { useProjectFeedbackSubmitMutation } from '../../../shared/mutations/useProjectFeedbackMutations'

const ProjectOwnerFeedbackComponent = () => {
  const params = useParams<{ projectId: string }>()
  const { review, feedback } = useProjectFeedbackState()
  const { data: reviewDetails, isLoading } = useProjectOwnerFeedbackDetails(params.projectId ?? '')
  const { mutate, isLoading: isSubmitting } = useProjectFeedbackSubmitMutation(
    params.projectId ?? '',
    'owner',
  )
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(1)
  const steps = [{ label: 'Review' }, { label: 'Feedback' }, { label: 'Payment' }]

  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handlePrevStep = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  const handleSubmitProject = () => {
    const data: ProjectFeedback = {
      review: {
        endorsementRating: review.rating,
        projectExperience: review.experienceWorkingWith,
      },
      rolesFeedback: feedback.experts.map((expert) => ({
        id:
          reviewDetails?.experts.find(
            (reviewExpert) => reviewExpert.actor.username === expert.username,
          )?.uId ?? '',
        actorReview: {
          id: expert.id,
          didWell: expert.didWell,
          needsImprovement: expert.needsImprovement,
          personalNote: expert.personalNote,
        },
      })),
    }
    mutate(data, {
      onSuccess: () => {
        navigate(APP_PATHS.projectCompleted.replace(':projectId', params.projectId ?? ''))
      },
      onError: (error) => {
        console.log(error)
      },
    })
  }

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          How was your collaboration?
        </Text>
      </AppContainer.Header>
      <Container maxW="container.lg" mb="-16px">
        <Stepper activeStep={activeStep} steps={steps} />
      </Container>
      {!isLoading && (
        <AppContainer.Content isDetails pt={70}>
          {activeStep === 1 && <ReviewStep onNext={handleNextStep} />}
          {activeStep === 2 && (
            <FeedbackStep
              onNext={handleNextStep}
              onBack={handlePrevStep}
              peopleToReview={reviewDetails?.experts.map((item) => item.actor) ?? []}
            />
          )}
          {activeStep === 3 && (
            <PaymentStep
              paymentSystemLink={reviewDetails?.externalPayment ?? ''}
              salaries={reviewDetails?.salaries ?? []}
              mySalary={reviewDetails?.mySalary ?? 0}
              onBack={handlePrevStep}
              onSubmit={handleSubmitProject}
              isSubmitting={isSubmitting}
              checkboxLabel={"I have taken care of the team's payment"}
            />
          )}
        </AppContainer.Content>
      )}
    </>
  )
}

export const ProjectOwnerFeedback = () => (
  <ProjectFeedbackProvider>
    <ProjectOwnerFeedbackComponent />
  </ProjectFeedbackProvider>
)
