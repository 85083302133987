import { Container, Text } from '@chakra-ui/react'
import { AppContainer } from '../../../components/app-container'
import React, { useState } from 'react'
// import { Stepper } from '@deepwork/ui-components'
// import { CreateProjectDTO } from '@deepwork/interfaces'
import { Stepper } from 'libs/ui-components/src'
import { CreateProjectDTO } from 'libs/interfaces/src'
import { DetailsStep } from './steps/DetailsStep'
import { RolesStep } from './steps/RolesStep'
import { DeadlineStep } from './steps/DeadlineStep'
import { useNavigate } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'
import { ProjectFlowContextProvider, useProjectFlowState } from './ProjectFlowContextProvider'
import { useCreateProjectMutation } from '../../../shared/mutations/project'
import { useOrganisationRoles } from '../../../shared/queries/organisation'

const NewProjectComponent = () => {
  const { deadline, roles, details } = useProjectFlowState()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(1)
  const steps = [{ label: 'Details' }, { label: 'Roles' }, { label: 'Terms' }]
  const { data: presetRoles } = useOrganisationRoles(details?.organisation?.value ?? '')
  const { mutate: onCreateProject } = useCreateProjectMutation({
    onSuccess: (response) => {
      navigate(APP_PATHS.projectDetails.replace(':projectId', response))
    },
  })

  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handlePrevStep = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  const handleSubmitProject = () => {
    const mappedDetails: CreateProjectDTO = {
      title: details.name,
      description: details.description,
      stakeholder: details.stakeholder?.value,
      applicationDeadline: deadline.applicationDeadline,
      documentUrl: details.documentUrl,
      tag: details.tag?.value ?? '',
      organisationId: details.organisation?.value ?? '',
      roles,
    }
    onCreateProject(mappedDetails)
  }

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          Create a new project
        </Text>
      </AppContainer.Header>
      <Container maxW="container.lg" mb="-16px">
        <Stepper activeStep={activeStep} steps={steps} />
      </Container>
      <AppContainer.Content isDetails pt={70}>
        {activeStep === 1 && <DetailsStep onNext={handleNextStep} />}
        {activeStep === 2 && (
          <RolesStep
            presetRoles={presetRoles ?? []}
            onNext={handleNextStep}
            onBack={handlePrevStep}
          />
        )}
        {activeStep === 3 && (
          <DeadlineStep onSubmitProject={handleSubmitProject} onBack={handlePrevStep} />
        )}
      </AppContainer.Content>
    </>
  )
}

export const NewProject = () => (
  <ProjectFlowContextProvider>
    <NewProjectComponent />
  </ProjectFlowContextProvider>
)
