import React from 'react'
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useField } from 'formik'
import { InfoIcon } from '@chakra-ui/icons'
import { ChipInput, ChipInputProps } from '../chip-input/chip-input'

interface FormikInputControlProps {
  label: string
  tooltipLabel?: string
  disabled?: boolean
  name: string
  placeholder?: string
  type?: string
  styleProps?: FormControlProps
  chipProps?: Partial<ChipInputProps>
}

export const FormikChipInputControl: React.FC<FormikInputControlProps> = ({
  styleProps,
  chipProps,
  ...props
}) => {
  const [, meta, helpers] = useField(props.name)
  return (
    <FormControl
      isInvalid={!!meta.error && meta.touched}
      isDisabled={props.disabled}
      {...styleProps}
    >
      <FormLabel>
        <Box mb="8px" display="flex" alignItems="center">
          <Text variant="h4" color="white" mr="8px" fontWeight={400}>
            {props.label}
          </Text>
          {!!props.tooltipLabel && (
            <Tooltip label={props.tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          )}
        </Box>
      </FormLabel>
      <ChipInput
        name={props.name}
        disabled={props.disabled}
        chips={meta.value}
        placeholder={props.placeholder}
        onChange={(chips) => helpers.setValue(chips)}
        {...chipProps}
      />
      {meta.touched && meta.error && <FormErrorMessage>{meta.error ?? ' '}</FormErrorMessage>}
    </FormControl>
  )
}
