import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import axios from '../api/setup'
// import { ApplyToProjectDTO, CreateProjectDTO, RoleTypeEnum } from '@deepwork/interfaces'
import { ApplyToProjectDTO, CreateProjectDTO, RoleTypeEnum } from 'libs/interfaces/src'
import { useToast } from '@chakra-ui/react'

export const useCreateProjectMutation = (
  options?: UseMutationOptions<string, string, CreateProjectDTO>,
): UseMutationResult<string, string, CreateProjectDTO> => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/project', data), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}

export const useEditProjectMutation = (
  projectId: string,
  options?: UseMutationOptions<
    string,
    string,
    Pick<CreateProjectDTO, 'roles' | 'applicationDeadline'>
  >,
): UseMutationResult<string, string, Pick<CreateProjectDTO, 'roles' | 'applicationDeadline'>> => {
  const toast = useToast()
  return useMutation(async (data) => axios.put(`/project/${projectId}`, data), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}

export const useDeleteProjectMutation = (
  options?: UseMutationOptions<void, string, string>,
): UseMutationResult<void, string, string> => {
  const toast = useToast()
  return useMutation(async (projectId: string) => axios.delete(`/project/${projectId}`), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}

export const useExportProject = (
  options?: UseMutationOptions<void, string, string>,
): UseMutationResult<void, string, string> => {
  const toast = useToast()
  return useMutation(async (projectId: string) => axios.get(`/project/${projectId}/export`), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}

export const useRemoveAssignee = (
  options?: UseMutationOptions<void, string, { userId: string; roleId: string }>,
): UseMutationResult<void, string, { userId: string; roleId: string }> => {
  const toast = useToast()
  return useMutation(
    async ({ userId, roleId }) => axios.get(`/actor/${userId}/role/${roleId}/remove`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}

export const useProjectSubmitApplication = (
  roleId: string,
  options?: UseMutationOptions<void, string, ApplyToProjectDTO>,
): UseMutationResult<void, string, ApplyToProjectDTO> => {
  const toast = useToast()
  return useMutation(async (data) => axios.post(`/actor/role/${roleId}/apply`, data), {
    onError: (error: string) => {
      toast({ position: 'top-right', status: 'error', title: error })
    },
    ...options,
  })
}

export const useProjectConfirmApplication = (
  roleId: string,
  username: string,
  options?: UseMutationOptions<
    void,
    string,
    { acceptanceLetter: string; tasks?: string[]; payment?: number }
  >,
): UseMutationResult<
  void,
  string,
  { acceptanceLetter: string; tasks?: string[]; payment?: number }
> => {
  const toast = useToast()
  return useMutation(
    async (data) => axios.post(`/actor/${username}/role/${roleId}/approve`, data),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}

export const useProjectWithdrawApplicationMutation = (
  options?: UseMutationOptions<void, string, { roleId: string; type: RoleTypeEnum }>,
): UseMutationResult<void, string, { roleId: string; type: RoleTypeEnum }> => {
  const toast = useToast()
  return useMutation(
    async ({ roleId, type }) => axios.post(`/actor/role/${roleId}/withdraw/${type}`),
    {
      onError: (error: string) => {
        toast({ position: 'top-right', status: 'error', title: error })
      },
      ...options,
    },
  )
}
