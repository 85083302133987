import React, { useCallback, useReducer } from 'react'
import { ConfirmationStepValues, PaymentStepValues, TasksStepValues } from './interfaces'

type Action =
  | { type: 'update-tasks-steps'; payload: TasksStepValues }
  | { type: 'update-payment-step'; payload: PaymentStepValues }
  | { type: 'update-confirmation-step'; payload: ConfirmationStepValues }

export const ConfirmApplicationContext = React.createContext<State>({
  tasksStep: {} as TasksStepValues,
  paymentStep: {} as PaymentStepValues,
  confirmationStep: {} as ConfirmationStepValues,
})
export const ConfirmApplicationDispatchContext = React.createContext<{
  onUpdateTasksStep: (review: TasksStepValues) => void
  onUpdatePaymentStep: (feedback: PaymentStepValues) => void
  onUpdateConfirmationStep: (payment: ConfirmationStepValues) => void
}>({
  onUpdateTasksStep: () => undefined,
  onUpdatePaymentStep: () => undefined,
  onUpdateConfirmationStep: () => undefined,
})
export type State = {
  tasksStep: TasksStepValues
  paymentStep: PaymentStepValues
  confirmationStep: ConfirmationStepValues
}
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update-tasks-steps':
      return { ...state, tasksStep: { ...action.payload } }
    case 'update-payment-step':
      return { ...state, paymentStep: { ...action.payload } }
    case 'update-confirmation-step':
      return { ...state, confirmationStep: { ...action.payload } }
    default: {
      return state
    }
  }
}
export const ConfirmApplicationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    tasksStep: {
      tasks: [],
    },
    paymentStep: {
      contributorPercentage: 50,
      yourPercentage: 50,
      yourPayment: null,
      contributorPayment: null,
      yourBonus: 0,
    },
    confirmationStep: {
      whyDidYouChooseApplicant: '',
    },
  })

  const handleDispatch = useCallback((action: Action) => dispatch(action), [])

  const handleUpdateTasksStep = useCallback(
    (tasks: TasksStepValues) => {
      handleDispatch({ type: 'update-tasks-steps', payload: { ...tasks } })
    },
    [handleDispatch],
  )

  const handleUpdatePaymentStep = useCallback(
    (peopleAndSkills: PaymentStepValues) => {
      handleDispatch({
        type: 'update-payment-step',
        payload: { ...peopleAndSkills },
      })
    },
    [handleDispatch],
  )

  const handleUpdateConfirmationStep = useCallback(
    (terms: ConfirmationStepValues) => {
      handleDispatch({
        type: 'update-confirmation-step',
        payload: { ...terms },
      })
    },
    [handleDispatch],
  )

  return (
    <ConfirmApplicationContext.Provider value={state}>
      <ConfirmApplicationDispatchContext.Provider
        value={{
          onUpdateTasksStep: handleUpdateTasksStep,
          onUpdatePaymentStep: handleUpdatePaymentStep,
          onUpdateConfirmationStep: handleUpdateConfirmationStep,
        }}
      >
        {children}
      </ConfirmApplicationDispatchContext.Provider>
    </ConfirmApplicationContext.Provider>
  )
}

export const useConfirmApplication = () => {
  const context = React.useContext(ConfirmApplicationContext)
  if (context === undefined) {
    throw new Error('useConfirmApplication must be used within a ConfirmApplicationContext')
  }
  return context
}

export const useConfirmApplicationDispatch = () => {
  const context = React.useContext(ConfirmApplicationDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useConfirmApplicationDispatch must be used within a ConfirmApplicationDispatchContext',
    )
  }
  return context
}
