import React, { FC } from 'react'
import { Box, BoxProps, Button } from '@chakra-ui/react'
import { ProfileBox } from '../profile-box/profile-box'
// import { IUser } from '@deepwork/interfaces'
import { IUser } from 'libs/interfaces/src'

export interface CollaboratorApplicationProps {
  /**
   * IUser
   */
  user: Pick<IUser, 'id' | 'username' | 'avatarUrl'>
  /**
   * Job title
   */
  jobTitle: string
  /**
   * Confirm button
   */
  onConfirm: () => void
  canApprove?: boolean
  approved?: boolean
  boxProps?: BoxProps
}

export const CollaboratorApplication: FC<CollaboratorApplicationProps> = ({
  user,
  jobTitle,
  onConfirm,
  canApprove,
  approved,
  boxProps,
  children,
}) => {
  const handleUserClick = () => {
    window.open('/profile/' + user.username, '_blank')
  }

  return (
    <Box {...boxProps}>
      <Box
        px="20px"
        py="16px"
        bg="gray.22"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="12px 12px 0 0"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center">
          <ProfileBox
            variant="compact"
            name={user.username}
            jobTitle={jobTitle}
            avatarUrl={user.avatarUrl}
            onClick={handleUserClick}
          />
        </Box>
        <Button onClick={onConfirm} disabled={!canApprove || approved} my={{ base: '10px' }}>
          Confirm
          <Box as="span" ml={1} display={{ base: 'none', md: 'inline-flex' }}>
            {user.username}
          </Box>
        </Button>
      </Box>
      <Box px="20px" py="16px" bg="gray.24" borderRadius="0 0 12px 12px" color="gray.79">
        {children}
      </Box>
    </Box>
  )
}
