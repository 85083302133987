import React, { useCallback, useEffect, useReducer } from 'react'
// import { DeadlineValues, GetProjectDTO, IProjectDetailsValue, IProjectRole } from '@deepwork/interfaces'
import {
  DeadlineValues,
  GetProjectDTO,
  IProjectDetailsValue,
  IProjectRole,
} from 'libs/interfaces/src'
import { DateTime } from 'luxon'

export const ProjectFlowContext = React.createContext<State>({
  details: {} as IProjectDetailsValue,
  roles: [],
  deadline: {} as DeadlineValues,
})
export const ProjectFlowDispatchContext = React.createContext<{
  onUpdateDetails: (details: Partial<IProjectDetailsValue>) => void
  onUpdateRoles: (roles: (IProjectRole & { isReadOnly?: boolean })[]) => void
  onUpdateTerms: (terms: Partial<DeadlineValues>) => void
}>({
  onUpdateDetails: () => undefined,
  onUpdateRoles: () => undefined,
  onUpdateTerms: () => undefined,
})
export interface State {
  details: IProjectDetailsValue
  roles: (IProjectRole & { isReadOnly?: boolean })[]
  deadline: DeadlineValues
}
type Action =
  | { type: 'initial-load'; payload: State }
  | { type: 'update-details'; payload: Partial<IProjectDetailsValue> }
  | {
      type: 'update-roles'
      payload: (IProjectRole & { isReadOnly?: boolean })[]
    }
  | { type: 'update-deadline'; payload: Partial<DeadlineValues> }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'initial-load':
      return action.payload
    case 'update-details':
      return { ...state, details: { ...state.details, ...action.payload } }
    case 'update-roles':
      return {
        ...state,
        roles: [...action.payload],
      }
    case 'update-deadline':
      return { ...state, deadline: { ...state.deadline, ...action.payload } }
    default: {
      return state
    }
  }
}
export const ProjectFlowContextProvider: React.FC<{
  existingProject?: GetProjectDTO
}> = ({ existingProject, children }) => {
  const [state, dispatch] = useReducer(reducer, {
    details: {
      name: '',
      description: '',
      documentUrl: '',
      organisation: null,
      tag: null,
      stakeholder: null,
    },
    roles: [],
    deadline: {
      applicationDeadline: '',
    },
  })

  const handleDispatch = useCallback((action: Action) => dispatch(action), [])

  const handleUpdateDetails = useCallback(
    (details: Partial<IProjectDetailsValue>) => {
      if (state.details.organisation?.value !== details.organisation?.value) {
        handleDispatch({
          type: 'update-roles',
          payload: [],
        })
      }
      handleDispatch({ type: 'update-details', payload: details })
    },
    [handleDispatch],
  )

  const handleUpdateRoles = useCallback(
    (roles: IProjectRole[]) => {
      handleDispatch({
        type: 'update-roles',
        payload: roles,
      })
    },
    [handleDispatch],
  )

  const handleUpdateTerms = useCallback(
    (terms: Partial<DeadlineValues>) => {
      handleDispatch({ type: 'update-deadline', payload: terms })
    },
    [handleDispatch],
  )

  useEffect(() => {
    if (existingProject) {
      dispatch({
        type: 'initial-load',
        payload: {
          details: {
            name: existingProject.name,
            description: existingProject.description,
            documentUrl: existingProject.externalDocument,
            organisation: {
              value: existingProject.organization.id,
              label: existingProject.organization.name,
            },
            tag: {
              value: existingProject.tag ?? '',
              label: existingProject.tag ?? '',
            },
            stakeholder: {
              value: existingProject.stakeholder.id,
              label: existingProject.stakeholder.username,
            },
          },
          roles: existingProject.roles.map((item) => ({
            uId: item.uId,
            id: item.id,
            title: item.title,
            description: item.description,
            assignee: item.expert.actor,
            isProjectOwner: item.isProjectOwner,
            workBeginDate: DateTime.fromSQL(item.dates[0]).toFormat('yyyy-MM-dd'),
            workEndDate: DateTime.fromSQL(item.dates[1]).toFormat('yyyy-MM-dd'),
            tasks: item.tasks,
            payment: item.payment,
            mentoringBonus: item.mentoringBonus,
            isReadOnly: false,
          })),
          deadline: {
            applicationDeadline: DateTime.fromSQL(existingProject.dateRange[1]).toFormat(
              'yyyy-MM-dd',
            ),
          },
        },
      })
    }
  }, [existingProject])

  return (
    <ProjectFlowContext.Provider value={state}>
      <ProjectFlowDispatchContext.Provider
        value={{
          onUpdateDetails: handleUpdateDetails,
          onUpdateRoles: handleUpdateRoles,
          onUpdateTerms: handleUpdateTerms,
        }}
      >
        {children}
      </ProjectFlowDispatchContext.Provider>
    </ProjectFlowContext.Provider>
  )
}

export const useProjectFlowState = () => {
  const context = React.useContext(ProjectFlowContext)
  if (context === undefined) {
    throw new Error('useNewProjectState must be used within a ProjectFlowContext')
  }
  return context
}

export const useProjectFlowDispatch = () => {
  const context = React.useContext(ProjectFlowDispatchContext)
  if (context === undefined) {
    throw new Error('useNewProjectDispatch must be used within a NewProjectDispatchContext')
  }
  return context
}
