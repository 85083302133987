import React, { ChangeEvent } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Tooltip,
} from '@chakra-ui/react'
// import { RoundedBox } from '@deepwork/ui-components'
import { RoundedBox } from 'libs/ui-components/src'
import { useProjectFlowDispatch, useProjectFlowState } from '../../ProjectFlowContextProvider'
import { InfoIcon } from '@chakra-ui/icons'

interface Props {
  onBack: () => void
  onSubmitProject: () => void
}
export const DeadlineStep: React.FC<Props> = ({ onBack, onSubmitProject }) => {
  const { deadline } = useProjectFlowState()
  const { onUpdateTerms } = useProjectFlowDispatch()
  const [error, setError] = React.useState<string | null>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onUpdateTerms({ applicationDeadline: event.target.value })
  }

  const handleSubmit = () => {
    if (!deadline.applicationDeadline) {
      setError('Deadline is required')
    }
    onSubmitProject()
  }

  return (
    <Box>
      <Text variant="h2" color="white" mb="25px">
        Deadline
      </Text>
      <RoundedBox mb="25px">
        <FormControl isInvalid={!!error}>
          <FormLabel>
            <Box mb="8px" display="flex" alignItems="center">
              <Text variant="h4" color="white" mr="8px">
                Team application deadline
              </Text>
              <Tooltip
                label={
                  'Specify the deadline of the project until when Leads and Contributors are able to apply to the project. This has to be before the project start date.'
                }
              >
                <InfoIcon color="gray.79" />
              </Tooltip>
            </Box>
          </FormLabel>
          <Input
            type={'date'}
            name="applicationDeadline"
            value={deadline.applicationDeadline}
            placeholder="DD-MM-YYYY"
            onChange={handleChange}
          />
          {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
      </RoundedBox>
      <Button variant="link" mr="40px" onClick={onBack}>
        Back
      </Button>
      <Button onClick={handleSubmit}>Submit project</Button>
    </Box>
  )
}
