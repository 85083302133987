import { FC } from 'react'
// import { GetCoworkersDto } from '@deepwork/interfaces'
// import { ProfileBox, RoundedBox } from '@deepwork/ui-components'
// import { pluralise } from '@deepwork/utils'
import { GetCoworkersDto } from 'libs/interfaces/src'
import { ProfileBox, RoundedBox } from 'libs/ui-components/src'
import { pluralise } from 'libs/utils/src'
import { Box, Text } from '@chakra-ui/react'

export interface Props {
  coworkers?: GetCoworkersDto[]
  onCoworkerClick: (username: string) => void
  emptyText: string
}
export const CoworkersTab: FC<Props> = ({ onCoworkerClick, coworkers, emptyText }) => {
  const handleCoworkerClick = (username: string) => () => {
    onCoworkerClick(username)
  }

  return (
    <Box>
      {coworkers?.length ? (
        coworkers.map((coworker) => (
          <RoundedBox
            key={coworker.id}
            data-testid={`coworker-box-${coworker.id}`}
            onClick={handleCoworkerClick(coworker.username)}
            cursor="pointer"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={'21px 31px 20px 16px'}
            mb="16px"
          >
            <ProfileBox name={coworker.username} avatarUrl={coworker.avatarUrl} />
            <Text>
              Worked on {coworker.projectsCount} {pluralise('project', coworker.projectsCount)}
            </Text>
          </RoundedBox>
        ))
      ) : (
        <RoundedBox
          data-testid="emptyText"
          h={'325px'}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {emptyText}
        </RoundedBox>
      )}
    </Box>
  )
}
