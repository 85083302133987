import { useNavigate, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { AppContainer } from '../../../components/app-container'
import { Container, Text } from '@chakra-ui/react'
// import { Stepper } from '@deepwork/ui-components'
// import { ProjectFeedback } from '@deepwork/interfaces'
import { Stepper } from 'libs/ui-components/src'
import { ProjectFeedback } from 'libs/interfaces/src'
import { ReviewStep } from './steps/review-step'
import { FeedbackStep } from './steps/feedback-step'
import { PaymentStep } from './steps/payment-step'
import { useProjectContributorFeedbackDetails } from '../../../shared/queries/project'
import { ProjectFeedbackProvider, useProjectFeedbackState } from './ProjectFeedbackProvider'
import { PerformanceTypeEnum } from './enums'
import { useProjectFeedbackSubmitMutation } from '../../../shared/mutations/useProjectFeedbackMutations'
import { APP_PATHS } from '../../../paths'

const ProjectContributorFeedbackComponent = () => {
  const params = useParams<{ projectId: string; roleId: string }>()
  const { review, feedback } = useProjectFeedbackState()
  const { data: reviewDetails, isLoading: isDetailsLoading } = useProjectContributorFeedbackDetails(
    params.roleId ?? '',
  )
  const { mutate, isLoading: isSubmitting } = useProjectFeedbackSubmitMutation(
    params.projectId ?? '',
    'contributor',
  )
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(1)
  const steps = [{ label: 'Review' }, { label: 'Feedback' }, { label: 'Payment' }]
  const peopleToReview = reviewDetails ? [{ ...reviewDetails.expert, extendForm: true }] : []

  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handlePrevStep = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  const handleSubmitProject = () => {
    const data: ProjectFeedback = {
      review: {
        endorsementRating: review.rating,
        projectExperience: review.experienceWorkingWith,
      },
      rolesFeedback: [
        {
          id: params.roleId ?? '',
          workRatio: feedback.experts[0]?.split,
          performanceType: feedback.experts[0]?.performanceType ?? PerformanceTypeEnum.leader,
          actorReview: {
            id: feedback.experts[0]?.id,
            didWell: feedback.experts[0]?.didWell,
            needsImprovement: feedback.experts[0]?.needsImprovement,
            personalNote: feedback.experts[0]?.personalNote,
          },
        },
      ],
    }
    mutate(data, {
      onSuccess: () => {
        navigate(APP_PATHS.projectCompleted.replace(':projectId', params.projectId ?? ''))
      },
      onError: (error) => {
        console.log(error)
      },
    })
  }

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white">
          How was your collaboration?
        </Text>
      </AppContainer.Header>
      <Container maxW="container.lg" mb="-16px">
        <Stepper activeStep={activeStep} steps={steps} />
      </Container>
      {!isDetailsLoading && reviewDetails && (
        <AppContainer.Content isDetails pt={70}>
          {activeStep === 1 && <ReviewStep onNext={handleNextStep} />}
          {activeStep === 2 && (
            <FeedbackStep
              onNext={handleNextStep}
              onBack={handlePrevStep}
              peopleToReview={peopleToReview}
              possiblePerformanceTypes={[
                {
                  label: 'Leader',
                  description: 'Can delegate some of the tasks.',
                  value: PerformanceTypeEnum.leader,
                },
                {
                  label: 'Mentor',
                  description: 'Can provide educational guidance.',
                  value: PerformanceTypeEnum.mentor,
                },
                {
                  label: 'Hero',
                  description: 'Encourages self-learning but assists when needed.',
                  value: PerformanceTypeEnum.hero,
                },
              ]}
            />
          )}
          {activeStep === 3 && (
            <PaymentStep
              paymentSystemLink={reviewDetails?.externalPayment ?? ''}
              mySalary={reviewDetails.payment}
              onSubmit={handleSubmitProject}
              onBack={handlePrevStep}
              isSubmitting={isSubmitting}
              checkboxLabel="I have requested payment through the process above."
            />
          )}
        </AppContainer.Content>
      )}
    </>
  )
}

export const ProjectContributorFeedback = () => (
  <ProjectFeedbackProvider>
    <ProjectContributorFeedbackComponent />
  </ProjectFeedbackProvider>
)
