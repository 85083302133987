import React, { useState } from 'react'
import { ConfirmApplicationProvider, useConfirmApplication } from './ConfirmApplicationProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { useProjectDetails } from '../../../shared/queries/project'
import { useAppliedRoleDetails } from '../../../shared/queries/useRoles'
import { APP_PATHS } from '../../../paths'
import { AppContainer } from '../../../components/app-container'
import { Button, Container, Text } from '@chakra-ui/react'
// import { CircleArrowRight, Stepper } from '@deepwork/ui-components'
import { CircleArrowRight, Stepper } from 'libs/ui-components/src'
import { TasksStep } from './steps/tasks-step'
import { PaymentStep } from './steps/payment-step'
import { ConfirmationStep } from './steps/confirmation-step'
import { useProjectConfirmApplication } from '../../../shared/mutations/project'

const ConfirmApplicationComponent = () => {
  const { paymentStep, confirmationStep, tasksStep } = useConfirmApplication()
  const navigate = useNavigate()
  const params = useParams<{ roleId: string; projectId: string; username: string }>()
  const { data: project } = useProjectDetails(params.projectId ?? '')
  const { data: roleDetails } = useAppliedRoleDetails(
    params.projectId ?? '',
    params.roleId ?? '',
    params.username ?? '',
  )
  const [activeStep, setActiveStep] = useState(1)
  const steps = [{ label: 'Tasks' }, { label: 'Payment' }, { label: 'Confirmation' }]
  const { mutate: onConfirmApplication } = useProjectConfirmApplication(
    roleDetails?.uId ?? '',
    params.username ?? '',
    {
      onSuccess: () => {
        navigate(
          APP_PATHS.confirmCollaboratorSuccess
            .replace(':projectId', params.projectId ?? '')
            .replace(':roleId', params.roleId ?? '')
            .replace(':username', params.username ?? ''),
        )
      },
      onError: (err) => {
        console.error(err)
      },
    },
  )

  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handlePrevStep = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  const handleSubmitProject = () => {
    const data = {
      acceptanceLetter: confirmationStep.whyDidYouChooseApplicant,
      tasks: tasksStep.tasks,
      payment: paymentStep.contributorPayment ?? 0,
    }

    onConfirmApplication(data)
  }

  const handleGoBackToProjectDetails = () => {
    if (params.projectId) {
      navigate(APP_PATHS.projectDetails.replace(':projectId', params.projectId))
    }
  }

  return (
    <>
      <AppContainer.Header>
        <Button
          onClick={handleGoBackToProjectDetails}
          variant="link"
          leftIcon={<CircleArrowRight transform={'rotate(180deg)'} />}
          mb={'13px'}
        >
          {project?.name}
        </Button>
        <Text variant="h1" color="white">
          Confirm {params?.username}
        </Text>
      </AppContainer.Header>
      <Container maxW="container.lg" mb="-16px">
        <Stepper activeStep={activeStep} steps={steps} />
      </Container>
      <AppContainer.Content isDetails pt={70}>
        {activeStep === 1 && <TasksStep onNext={handleNextStep} tasks={roleDetails?.tasks ?? []} />}
        {activeStep === 2 && roleDetails && (
          <PaymentStep onNext={handleNextStep} onBack={handlePrevStep} roleDetails={roleDetails} />
        )}
        {activeStep === 3 && (
          <ConfirmationStep onSubmit={handleSubmitProject} onBack={handlePrevStep} />
        )}
      </AppContainer.Content>
    </>
  )
}

export const ConfirmApplication = () => (
  <ConfirmApplicationProvider>
    <ConfirmApplicationComponent />
  </ConfirmApplicationProvider>
)
