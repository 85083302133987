import { Box, Container, Skeleton } from '@chakra-ui/react'
import { FC } from 'react'

interface Props {
  loading: boolean
}
export const CardListSkeleton: FC<Props> = ({ loading }) => {
  return loading ? (
    <Container maxW="container.lg">
      <Box
        display="flex"
        flexWrap="wrap"
        __css={{ '> div:nth-of-type(3n + 3)': { marginRight: '0' } }}
      >
        {[...Array(15)].map((_, i) => (
          <Skeleton
            key={i}
            isLoaded={!loading}
            borderRadius="24px"
            width={'290px'}
            height={'300px'}
            mb="40px"
            mr="30px"
          />
        ))}
      </Box>
    </Container>
  ) : null
}
