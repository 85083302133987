import { Box, Input, Tag, TagCloseButton, Text } from '@chakra-ui/react'
import { ChangeEvent, FC, useEffect, useState } from 'react'

export interface ChipInputProps {
  isInvalid?: boolean
  name: string
  disableChipCreation?: boolean
  chips: string[]
  readOnlyChips?: string[]
  onChange?: (values: string[]) => void
  onFocus?: () => void
  onBlur?: () => void
  onInputChange?: (value: string) => void
  disabled?: boolean
  placeholder?: string
  renderOption?: (value: any) => JSX.Element
}

export const ChipInput: FC<ChipInputProps> = ({
  isInvalid,
  name,
  disableChipCreation,
  chips = [],
  readOnlyChips = [],
  placeholder,
  disabled,
  renderOption,
  onFocus,
  onBlur,
  onInputChange,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [chipValues, setChipValues] = useState(chips)

  const handleChange = (value: string) => {
    if (!disableChipCreation) {
      const updated = [...chipValues, value.split(' ')[0]]
      setChipValues(updated)
      onChange && onChange(updated)
    }
  }

  const handleRemove = (itemIndex: number) => () => {
    const updated = [...chipValues]
    updated.splice(itemIndex, 1)
    setChipValues(updated)
    onChange && onChange(updated)
  }

  const handleSetInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const indexOfSpace = value.indexOf(' ')
    if (indexOfSpace > -1) {
      setInputValue('')
      onInputChange && onInputChange('')
      handleChange(value)
    } else {
      setInputValue(value)
      onInputChange && onInputChange(value)
    }
  }

  const handleBlur = () => {
    if (inputValue) {
      handleChange(inputValue)
      setInputValue('')
    }
    onBlur && onBlur()
  }

  useEffect(() => {
    if (chips) {
      setChipValues(chips)
    }
  }, [chips])

  return (
    <Box
      data-testid="chip-input"
      borderRadius="8px"
      display="flex"
      alignItems={{ base: 'flex-start', md: 'center' }}
      border="1px solid"
      borderColor={isInvalid ? 'warning' : 'primary'}
      flexDirection={{ base: 'column', md: 'row' }}
      flexWrap={{ base: 'nowrap', md: 'wrap' }}
      minH="45px"
      opacity={disabled ? 0.4 : 1}
    >
      {readOnlyChips.map((value, index) => (
        <Tag
          key={index}
          size="md"
          borderRadius="full"
          variant="solid"
          p="4px 13px 4px 4px"
          bg="gray.18"
          ml={{ base: '18px', md: '25px' }}
          mt="8px"
          data-testid={`chip-input-chip-${index}`}
        >
          {renderOption ? (
            renderOption(value)
          ) : (
            <Text ml="12px" variant="small" color="white">
              {value}
            </Text>
          )}
        </Tag>
      ))}
      {chipValues.map((value, index) => (
        <Tag
          key={index}
          size="md"
          borderRadius="full"
          variant="solid"
          p="4px 13px 4px 4px"
          bg="gray.18"
          ml={{ base: '18px', md: index === 0 ? '25px' : 0 }}
          mt={{ base: '8px', md: '0' }}
          mr={index === chipValues.length - 1 ? 0 : '16px'}
          data-testid={`chip-input-chip-${index}`}
        >
          {renderOption ? (
            renderOption(value)
          ) : (
            <Text ml="12px" variant="small" color="white">
              {value}
            </Text>
          )}
          <TagCloseButton
            data-testid={`chip-input-chip-${index}-remove`}
            isDisabled={disabled}
            onClick={handleRemove(index)}
            color="white"
          />
        </Tag>
      ))}
      <Input
        name={name}
        width={{ base: '100%', md: 'auto' }}
        minW={'140px'}
        height="auto"
        flex={1}
        value={inputValue}
        onChange={handleSetInputValue}
        placeholder={placeholder}
        disabled={disabled}
        border={0}
        onFocus={onFocus}
        onBlur={handleBlur}
        _invalid={{
          boxShadow: 'none',
          borderColor: 'transparent',
        }}
        _hover={{
          boxShadow: 'none',
        }}
        _active={{
          boxShadow: 'none',
        }}
        _focus={{
          boxShadow: 'none',
        }}
      />
    </Box>
  )
}
