export const convertToFormData = (data: any): FormData => {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    if (key === 'image') {
      formData.append(key, data[key])
    } else {
      formData.append(
        key,
        typeof data[key] === 'boolean' || typeof data[key] === 'object'
          ? JSON.stringify(data[key])
          : data[key],
      )
    }
  })
  return formData
}
