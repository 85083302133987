import React from 'react'
import { Box, BoxProps, Container } from '@chakra-ui/react'

export interface ContentProps {
  isDetails?: boolean
}
export const AppContent: React.FC<ContentProps & BoxProps> = ({ isDetails, children, ...rest }) => (
  <Box bg={isDetails ? 'gray.18' : 'inherit'} flex="1 0 auto" py="50px" {...rest}>
    <Container maxW="container.lg" display="flex" flexDirection="column">
      {children}
    </Container>
  </Box>
)
