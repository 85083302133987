export const Menu = {
  baseStyle: {
    list: {
      bg: 'gray.22',
      p: '0',
      color: 'white',
      border: 'none',
      overflow: 'hidden',
    },
    item: {
      bg: 'gray.24',
      mb: '1px',
      p: '10px 25px',
      _active: {
        bg: 'gray.22',
      },
      _focus: {
        bg: 'gray.22',
      },
    },
  },
}
