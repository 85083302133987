import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { APP_PATHS } from '../../../paths'
import { AppContainer } from '../../../components/app-container'
import { Container, Text } from '@chakra-ui/react'
// import { Stepper } from '@deepwork/ui-components'
// import { CreateOrganisationDTO, ITask } from '@deepwork/interfaces'
import { Stepper } from 'libs/ui-components/src'
import { CreateOrganisationDTO, ITask } from 'libs/interfaces/src'
import { DetailsStep } from './steps/DetailsStep'
import { TermsStep } from './steps/TermsStep'
import { PeopleAndSkillsStep } from './steps/PeopleAndSkillsStep'
import { NewOrganisationProvider, useNewOrganisationState } from './NewOrganisationContext'
import { useCreateOrganisationMutation } from '../../../shared/mutations/organisation'

const NewOrganisationComponent = () => {
  const navigate = useNavigate()
  const { details, peopleAndSkills, eula } = useNewOrganisationState()
  const { mutate: onCreateNewOrganisation } = useCreateOrganisationMutation({
    onSuccess: (orgId) => {
      navigate(APP_PATHS.organisationDetails.replace(':organisationId', orgId))
    },
  })
  const [activeStep, setActiveStep] = useState(1)
  const steps = [{ label: 'Details' }, { label: 'People and Skills' }, { label: 'Terms' }]

  const handleNextStep = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handlePrevStep = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  const handleSubmitOrganisation = async () => {
    const data: CreateOrganisationDTO = {
      name: details.name,
      description: details.description,
      admin: details.admin.id,
      paymentSystem: details.externalPaymentSystemUrl,
      knowledgeBase: details.knowledgeBaseUrl,
      completionImage: details.projectCompletionImageUrl,
      website: details.websiteUrl,
      community: details.community,
      managers: peopleAndSkills.emailList,
      roles: peopleAndSkills.roles.map((role) => ({
        ...role,
        tasks: role.tasks.map(
          ({
            estimatedDurationUnit,
            ...task
          }: ITask & { estimatedDurationUnit?: { value: string; label: string } }) => ({
            ...task,
            timeValue:
              estimatedDurationUnit?.value === 'hours'
                ? Math.floor((task.timeValue ?? 0) * 60)
                : task.timeValue,
          }),
        ),
      })),
      eula,
    }
    onCreateNewOrganisation({ data, logo: details.logo })
  }

  return (
    <>
      <AppContainer.Header>
        <Text variant="h1" color="white" data-testid="page-title">
          Create a new organisation
        </Text>
      </AppContainer.Header>
      <Container maxW="container.lg" mb="-16px">
        <Stepper activeStep={activeStep} steps={steps} />
      </Container>
      <AppContainer.Content isDetails pt={70}>
        {activeStep === 1 && <DetailsStep onNext={handleNextStep} />}
        {activeStep === 2 && (
          <PeopleAndSkillsStep onNext={handleNextStep} onBack={handlePrevStep} />
        )}
        {activeStep === 3 && (
          <TermsStep onSubmitProject={handleSubmitOrganisation} onBack={handlePrevStep} />
        )}
      </AppContainer.Content>
    </>
  )
}

export const NewOrganisation = () => (
  <NewOrganisationProvider>
    <NewOrganisationComponent />
  </NewOrganisationProvider>
)
