import { Box, Button, FormControl, FormLabel, Text, Textarea } from '@chakra-ui/react'
import React, { FC } from 'react'
// import { RoundedBox } from '@deepwork/ui-components'
import { RoundedBox } from 'libs/ui-components/src'
import {
  useConfirmApplication,
  useConfirmApplicationDispatch,
} from '../../ConfirmApplicationProvider'
import { useParams } from 'react-router-dom'

interface Props {
  onBack: () => void
  onSubmit: () => void
}
export const ConfirmationStep: FC<Props> = ({ onBack, onSubmit }) => {
  const params = useParams<{ roleId: string; projectId: string; username: string }>()
  const { confirmationStep } = useConfirmApplication()
  const { onUpdateConfirmationStep } = useConfirmApplicationDispatch()

  const handleChangeReview = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onUpdateConfirmationStep({ whyDidYouChooseApplicant: e.target.value })
  }

  return (
    <>
      <FormControl>
        <FormLabel mb="37px">
          <Text variant="h2" color="white" mb="14px">
            Why did you choose to work with {params?.username}?
          </Text>
          <Text color="gray.79">
            This will be public and help other applicants understand why they have not been
            selected.
          </Text>
        </FormLabel>
        <RoundedBox>
          <Textarea
            placeholder="I have worked with them before…"
            value={confirmationStep.whyDidYouChooseApplicant}
            onChange={handleChangeReview}
          />
        </RoundedBox>
      </FormControl>
      <Box mt="43px">
        <Button variant="link" mr="40px" onClick={onBack}>
          Back
        </Button>
        <Button onClick={onSubmit}>Submit</Button>
      </Box>
    </>
  )
}
