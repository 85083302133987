import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { AppHeader } from './AppHeader'
import { AppContent, ContentProps } from './AppContent'

interface AppContainerComposition {
  Header: React.FC
  Content: React.FC<ContentProps & BoxProps>
}
export const AppContainer: React.FC & AppContainerComposition = ({ children }) => (
  <Box display="flex" flexDirection="column" minH="100vh">
    {children}
  </Box>
)

AppContainer.Header = AppHeader
AppContainer.Content = AppContent
