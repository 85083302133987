import { ethers } from 'ethers'

// const GAS_PRICE = '0x09184e72a000'
// const GAS = '0x2710'

export const getProvider = () => {
  const extendedWindow: any = window
  const provider = new ethers.providers.Web3Provider(extendedWindow.ethereum)
  return provider
}

export const requestAccounts = async (provider: any) => {
  return await provider.send('eth_requestAccounts', [])
}

export const sendTransaction = async (to: string, value: string) => {
  // const to = '0x2f318C334780961FB129D2a6c30D0763d9a5C970'
  // const value = '0x29a2241af62c0000'
  // const value = '0.01'
  // const accounts = await provider.listAccounts()

  const provider = await getProvider()
  const signer = provider.getSigner()

  let txHash
  try {
    txHash = await signer.sendTransaction({
      to,
      value: ethers.utils.parseEther(value),
    })
  } catch (error) {
    console.error(error)
  }

  return txHash
}

export const signMessage = async () => {
  const provider = await getProvider()
  const signer = provider.getSigner()
  const expiresAt = Date.now() + 1 * 60 * 1000
  const payload = 'Log in to DeepSkills'
  const dataSignObject = { expiresAt, payload }
  const dataSign = JSON.stringify(dataSignObject)
  const signature = await signer.signMessage(dataSign)
  return {
    signature,
    digest: dataSign,
  }
}

// export const takeMessageFromLocalStorageOrSign = async () => {
//   let signedMessage = localStorage.getItem('signedMessage')
//   signedMessage = JSON.parse(signedMessage)
//   if (!signedMessage) {
//     signedMessage = await signMessage()
//   }
//   return signedMessage
// }
