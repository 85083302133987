import { Box, Button, Checkbox, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
// import { TaskItem } from '@deepwork/ui-components'
// import { IRoleDetailsTask } from '@deepwork/interfaces'
import { TaskItem } from 'libs/ui-components/src'
import { IRoleDetailsTask } from 'libs/interfaces/src'
import {
  useConfirmApplication,
  useConfirmApplicationDispatch,
} from '../../ConfirmApplicationProvider'
import { useParams } from 'react-router-dom'

interface Props {
  onNext: () => void
  tasks: IRoleDetailsTask[]
}
export const TasksStep: FC<Props> = ({ onNext, tasks }) => {
  const { tasksStep } = useConfirmApplication()
  const { onUpdateTasksStep } = useConfirmApplicationDispatch()
  const params = useParams<{ roleId: string; projectId: string; username: string }>()

  const handleUpdateSelectedTasks =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onUpdateTasksStep({
          tasks: [...tasksStep.tasks, id],
        })
      } else {
        onUpdateTasksStep({
          tasks: tasksStep.tasks.filter((t) => t !== id),
        })
      }
    }

  const isChecked = (id: string) => {
    return tasksStep.tasks.includes(id)
  }
  return (
    <>
      <Box mt="40px">
        <Text variant="h2" color="white" mb="14px">
          Select their tasks
        </Text>
        <Text color="gray.79">
          Choose the tasks you feel comfortable with delegating to {params.username}.
        </Text>
      </Box>
      <Box mt="25px">
        {tasks.map((task) => (
          <TaskItem
            key={task.id}
            title={task.title}
            description={task.description}
            helperText={task.interested ? 'Interested' : ''}
            beforeElement={
              <Checkbox
                isChecked={isChecked(task.id)}
                onChange={handleUpdateSelectedTasks(task.id)}
              />
            }
            boxProps={{ mb: '13px' }}
          />
        ))}
      </Box>
      <Box mt="43px">
        <Button onClick={onNext} disabled={tasksStep.tasks.length === 0}>
          Next
        </Button>
      </Box>
    </>
  )
}
