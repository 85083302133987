import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React, { FC } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title?: string
  description?: string
  cancelLabel?: string
  confirmLabel?: string
}
export const ConfirmationAlert: FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  cancelLabel = 'Cancel',
  confirmLabel = 'Yes',
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size={'xs'}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          {title && (
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
          )}

          {description && <AlertDialogBody>{description}</AlertDialogBody>}

          <AlertDialogFooter justifyContent="space-between">
            <Button variant={'link'} ref={cancelRef} onClick={onClose}>
              {cancelLabel}
            </Button>
            <Button onClick={onConfirm} ml={3}>
              {confirmLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
