import React, { ChangeEvent } from 'react'
import {
  Box,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
// import { ProjectModalHeader, StaticCheckbox, TaskItem } from '@deepwork/ui-components'
// import { IOrganisation, IRoleTask } from '@deepwork/interfaces'
// import { pluralise } from '@deepwork/utils'
import { ProjectModalHeader, StaticCheckbox, TaskItem } from 'libs/ui-components/src'
import { IOrganisation, IRoleTask } from 'libs/interfaces/src'
import { pluralise } from 'libs/utils/src'

export interface TaskSummaryProps {
  isOpen: boolean
  onCloseClicked: () => void
  onInterestedClicked?: (taskId: string, isChecked: boolean) => void
  isExpert?: boolean
  org: Pick<IOrganisation, 'id' | 'imageUrl' | 'name'>
  title: string
  description?: string
  tasks: IRoleTask[]
}

export const TaskSummaryModal: React.FC<TaskSummaryProps> = ({
  isOpen,
  onCloseClicked,
  onInterestedClicked,
  isExpert = false,
  title,
  description,
  org,
  tasks,
}) => {
  const handleToggleInterested = (taskId: string) => (event: ChangeEvent<HTMLInputElement>) => {
    onInterestedClicked && onInterestedClicked(taskId, event.target.checked)
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onCloseClicked}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader bg="gray.22" pb="30px">
          <ProjectModalHeader
            organisationName={org.name}
            organisationImageUrl={org.imageUrl}
            name={title}
            description={description ?? 'Check tasks to show others what you’d like to learn next.'}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody bg="gray.24" p="0">
          <Box p="18px 25px 21px" borderBottom="1px solid" borderColor="gray.18">
            {tasks?.map((item) => (
              <TaskItem
                key={item.id}
                beforeElement={
                  item.status === 'complete' || isExpert ? (
                    <StaticCheckbox variant="confirmed" />
                  ) : (
                    <Checkbox
                      onChange={handleToggleInterested(item.id)}
                      isDisabled={isExpert}
                      isChecked={isExpert ? true : item.status === 'interested'}
                    />
                  )
                }
                title={item.title}
                description={item.description}
                helperText={
                  item.status === 'complete' && isExpert
                    ? `Completed ${item.count} ${pluralise('time', item.count)}`
                    : item.status === 'interested'
                    ? 'Interested'
                    : ''
                }
                boxProps={{ mb: '13px' }}
              />
            ))}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
