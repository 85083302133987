import React from 'react'
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useField } from 'formik'
import { InfoIcon } from '@chakra-ui/icons'

interface FormikInputControlProps {
  label?: string
  tooltipLabel?: string
  disabled?: boolean
  name: string
  placeholder?: string
  type?: string
  InputLeftAddon?: React.ReactNode | React.ReactElement | any
  InputRightAddon?: React.ReactNode | React.ReactElement | any
  InputLeftElement?: React.ReactNode | React.ReactElement | any
  InputRightElement?: React.ReactNode | React.ReactElement | any
  styleProps?: FormControlProps
}

export const FormikInputControl: React.FC<FormikInputControlProps> = ({
  styleProps,
  InputLeftAddon,
  InputRightAddon,
  InputLeftElement,
  InputRightElement,
  ...props
}) => {
  const [field, meta] = useField(props.name)
  const isGroup = InputLeftAddon || InputRightAddon || InputLeftElement || InputRightElement

  return (
    <FormControl
      id={props.name}
      data-testid={`${field.name}-input`}
      isDisabled={props.disabled}
      isInvalid={!!meta.error && meta.touched}
      {...styleProps}
    >
      <FormLabel>
        <Box mb="8px" display="flex" alignItems="center">
          {props.label && (
            <Text variant="h6" color="white" mr="8px">
              {props.label}
            </Text>
          )}
          {!!props.tooltipLabel && (
            <Tooltip label={props.tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          )}
        </Box>
      </FormLabel>
      {isGroup ? (
        <InputGroup alignItems="center">
          {InputLeftAddon && InputLeftAddon}
          {InputLeftElement && InputLeftElement}
          <Input type={props.type} required placeholder={props.placeholder} {...field} />
          {InputRightElement && InputRightElement}
          {InputRightAddon && InputRightAddon}
        </InputGroup>
      ) : (
        <Input type={props.type} required placeholder={props.placeholder} {...field} />
      )}
      {meta.touched && meta.error && <FormErrorMessage>{meta.error ?? ' '}</FormErrorMessage>}
    </FormControl>
  )
}
