export function generateColorFromString(value: string): string {
  const colors = [
    '#E8368F',
    '#a81f34',
    '#e58602',
    '#ed8b61',
    '#e06111',
    '#96350c',
    '#838c07',
    '#4ba00e',
    '#0a7f2b',
    '#838c07',
    '#47ccb5',
    '#17c0d3',
    '#1850c9',
    '#0c598c',
    '#0b1f70',
    '#6d039b',
    '#ba62e0',
    '#543ae8',
    '#4c16cc',
    '#3a0399',
  ]

  let hash = 0
  if (value.length === 0) return ''
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  hash = ((hash % colors.length) + colors.length) % colors.length
  return colors[hash]
}
