import React, { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react'
// import { IUser } from '@deepwork/interfaces'
import { IUser } from 'libs/interfaces/src'
import { InfoIcon } from '@chakra-ui/icons'
import { ProfileBox } from '../../layout/profile-box/profile-box'
import { Autocomplete } from '../autocomplete/autocomplete'

export interface ProfileSelectionProps {
  label: string
  tooltipLabel?: string
  error?: string
  showError?: boolean
  disabled?: boolean
  placeholder?: string
  selectedProfile?: Pick<IUser, 'id' | 'username' | 'avatarUrl'> | string | null
  possibleProfiles: Pick<IUser, 'id' | 'username' | 'avatarUrl'>[]
  onChange: (value?: Pick<IUser, 'id' | 'username' | 'avatarUrl'>) => void
}

export const ProfileSelection: FC<ProfileSelectionProps> = ({
  label,
  tooltipLabel,
  error,
  showError,
  placeholder,
  disabled,
  selectedProfile,
  onChange,
  possibleProfiles,
}) => {
  const [selected, setSelected] = useState<Pick<IUser, 'id' | 'username' | 'avatarUrl'> | null>(
    null,
  )

  const mappedProfiles = possibleProfiles.map((profile) => ({
    label: profile.username,
    value: profile.id,
  }))

  const handleSelect = (data: any) => {
    const selectedProfile = possibleProfiles.find((profile) => profile.id === data.value)
    if (selectedProfile) {
      setSelected(selectedProfile)
      onChange(selectedProfile)
    }
  }

  const handleChange = () => {
    setSelected(null)
    onChange()
  }

  useEffect(() => {
    if (typeof selectedProfile === 'string') {
      const foundProfile = possibleProfiles.find((profile) => profile.username === selectedProfile)
      if (foundProfile) {
        setSelected(foundProfile)
      }
    } else {
      setSelected(selectedProfile ?? null)
    }
    if (typeof selectedProfile !== 'string' && selectedProfile?.id && !selected) {
      setSelected(selectedProfile)
    }
  }, [selectedProfile, selected, possibleProfiles])

  useEffect(() => {
    if (selectedProfile === undefined) {
      setSelected(null)
    }
  }, [selectedProfile])

  return (
    <FormControl isInvalid={showError} isDisabled={disabled}>
      <FormLabel>
        <Box display="flex" alignItems="center">
          <Text variant="h4" color="gray.79" mr="8px">
            {label}
          </Text>
          {!!tooltipLabel && (
            <Tooltip label={tooltipLabel}>
              <InfoIcon color="gray.79" />
            </Tooltip>
          )}
        </Box>
      </FormLabel>
      {selected ? (
        <Box display="flex" justifyContent="space-between">
          <ProfileBox size="sm" avatarUrl={selected.avatarUrl} name={selected?.username} />
          {!disabled && (
            <Button variant="link" onClick={handleChange}>
              Change
            </Button>
          )}
        </Box>
      ) : (
        <Autocomplete
          data-testid="profile-selection"
          options={mappedProfiles}
          onChange={handleSelect}
          placeholder={placeholder ?? 'Andrej Berlin'}
          isDisabled={disabled}
        />
      )}
      {showError && <FormErrorMessage>{error ?? ' '}</FormErrorMessage>}
    </FormControl>
  )
}
