import { AppContainer } from '../../../components/app-container'
// import { RoundedBox } from '@deepwork/ui-components'
import { RoundedBox } from 'libs/ui-components/src'
import { Box, Button, Text } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'

export const ProjectApplicationSuccess = () => {
  const params = useParams<{ projectId: string }>()
  const navigate = useNavigate()

  const handleGoToProject = () => {
    navigate(APP_PATHS.projectDetails.replace(':projectId', params.projectId ?? ''))
  }

  return (
    <AppContainer.Content>
      <Box>
        <Text variant="h1" color="white">
          Thank you for your application!
        </Text>
        <RoundedBox mt="21px" mb="21px" p="10px 25px">
          <Text color="gray.79">
            Your application has been submitted successfully and will be reviewed shortly. You will
            receive an email once you have been confirmed.
          </Text>
        </RoundedBox>
        <Button onClick={handleGoToProject}>Back to project</Button>
      </Box>
    </AppContainer.Content>
  )
}
