import React, { useCallback, useEffect, useReducer, useState } from 'react'
// import {
//   ChangePasswordFormValues,
//   ChooseNewPassword,
//   ForgotPassword,
//   LoginModal,
//   PleaseVerifyEmail,
//   RegisterModal
// } from '@deepwork/ui-components'
// import { IUser, LoginUserDto, RegisterUserDto } from '@deepwork/interfaces'
import {
  ChangePasswordFormValues,
  ChooseNewPassword,
  ForgotPassword,
  LoginModal,
  PleaseVerifyEmail,
  RegisterModal,
} from 'libs/ui-components/src'
import { IUser, LoginUserDto, LoginWithWalletDto, RegisterUserDto } from 'libs/interfaces/src'
import { useDisclosure } from '@chakra-ui/react'
import { validateUsername } from '../../utils/validation-schemas/unique-username'

import { connectMetamask } from '../../../services/metamask/index'
import { signMessage } from '../../../services/metamask/provider'

import {
  useChangeResetPasswordMutation,
  useLoginMutation,
  useWalletLoginMutation,
  useRegistrationMutation,
  useResetPasswordMutation,
} from '../../mutations/user'
import { useUser } from '../../queries/users'

type Action = { type: 'update'; payload: State } | { type: 'logout'; payload: State }
type State = {
  user: IUser
}
const UserStateContext = React.createContext<{ user: IUser }>({
  user: {} as IUser,
})
const UserDispatchContext = React.createContext<{
  updateUserContext: (action: Action) => void
  onLogout: () => void
  onOpenLoginModal: () => void
  onOpenChoosePasswordModal: (resetToken: string) => void
}>({
  updateUserContext: () => void 0,
  onLogout: () => new Promise(() => void 0),
  onOpenLoginModal: () => void 0,
  onOpenChoosePasswordModal: () => void 0,
})

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload }
    case 'logout':
      return action.payload

    default: {
      return state
    }
  }
}

export const UserContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { user: {} as IUser })
  const [resetToken, setResetToken] = useState<string | null>(null)
  const { isOpen: isLoginModalOpen, onOpen: onOpenLogin, onClose: onCloseLogin } = useDisclosure()
  const {
    isOpen: isOpenRegisterOpen,
    onOpen: onOpenRegister,
    onClose: onCloseRegister,
  } = useDisclosure()
  const {
    isOpen: isForgotPasswordOpen,
    onOpen: onOpenForgotPassword,
    onClose: onCloseForgotPassword,
  } = useDisclosure()
  const {
    isOpen: isChoosePasswordOpen,
    onOpen: onOpenChoosePassword,
    onClose: onCloseChoosePassword,
  } = useDisclosure()
  const {
    isOpen: isPleaseVerifyEmailOpen,
    onOpen: onOpenPleaseVerifyEmail,
    onClose: onClosePleaseVerifyEmail,
  } = useDisclosure()
  const { mutate: onResetPassword } = useResetPasswordMutation()
  const { mutate: onLogin } = useLoginMutation()
  const { mutate: onWalletLogin } = useWalletLoginMutation()
  const { mutate: onRegistration } = useRegistrationMutation()
  const { mutate: onChangePassword } = useChangeResetPasswordMutation()
  const { data: user, refetch: onGetMe } = useUser()
  const handleDispatch = useCallback((action: Action) => dispatch(action), [])

  const handleLogout = useCallback(() => {
    localStorage.removeItem('AUTH_TOKEN')
    handleDispatch({ type: 'logout', payload: { user: {} as IUser } })
  }, [handleDispatch])

  const handleOpenLoginModal = useCallback(() => {
    onOpenLogin()
  }, [onOpenLogin])

  const handleOpenForgotPassword = useCallback(() => {
    onCloseLogin()
    onOpenForgotPassword()
  }, [onCloseLogin, onOpenForgotPassword])

  const handleLogIn = async (values: LoginUserDto) => {
    onLogin(values, {
      onSuccess: async ({ token }) => {
        localStorage.setItem('AUTH_TOKEN', token)
        await onGetMe()
        onCloseLogin()
      },
    })
  }

  const handleWalletLogIn = async () => {
    let message
    try {
      await connectMetamask()
      message = await signMessage()
    } catch (e) {
      console.log(e)
    }

    onWalletLogin(message as LoginWithWalletDto, {
      onSuccess: async ({ token }) => {
        localStorage.setItem('AUTH_TOKEN', token)
        await onGetMe()
        onCloseLogin()
      },
    })
  }

  useEffect(() => {
    if (user) {
      handleDispatch({
        type: 'update',
        payload: {
          user,
        },
      })
    }
  }, [user])

  const handleRegisterClick = () => {
    onCloseLogin()
    onOpenRegister()
  }

  const handleSignUp = async (values: RegisterUserDto & { photo?: File }) => {
    const { photo, ...rest } = values

    onRegistration(
      { ...rest, image: photo },
      {
        onSettled: () => {
          onCloseRegister()
          onOpenPleaseVerifyEmail()
        },
      },
    )
  }

  const handleForgotPassword = async (email: string) => {
    onResetPassword(email, {
      onSettled: () => {
        onCloseForgotPassword()
      },
    })
  }

  const handleChoosePassword = async (values: Partial<ChangePasswordFormValues>) => {
    if (resetToken && values.password) {
      onChangePassword(
        { newPassword: values.password, token: resetToken },
        {
          onSettled: () => {
            onCloseChoosePassword()
          },
        },
      )
    }
  }

  const handleOpenChoosePassword = useCallback(
    (resetToken: string) => {
      setResetToken(resetToken)
      onOpenChoosePassword()
    },
    [onOpenChoosePassword],
  )

  const handleGoToLogin = useCallback(() => {
    onCloseForgotPassword()
    onOpenLogin()
  }, [onCloseChoosePassword, onOpenLogin])

  useEffect(() => {
    const token = localStorage.getItem('AUTH_TOKEN')
    if (token) {
      onGetMe()
    }
  }, [])

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider
        value={{
          updateUserContext: handleDispatch,
          onLogout: handleLogout,
          onOpenLoginModal: handleOpenLoginModal,
          onOpenChoosePasswordModal: handleOpenChoosePassword,
        }}
      >
        {children}
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={onCloseLogin}
          onLogin={handleLogIn}
          onWalletLogin={handleWalletLogIn}
          onRegister={handleRegisterClick}
          onForgotPassword={handleOpenForgotPassword}
        />
        <RegisterModal
          isOpen={isOpenRegisterOpen}
          onClose={onCloseRegister}
          onSignUpClicked={handleSignUp}
          customValidationSchema={validateUsername}
        />
        <ForgotPassword
          isOpen={isForgotPasswordOpen}
          onClose={onCloseForgotPassword}
          onGoBack={handleGoToLogin}
          onSubmitEmail={handleForgotPassword}
        />
        <PleaseVerifyEmail isOpen={isPleaseVerifyEmailOpen} onClose={onClosePleaseVerifyEmail} />
        <ChooseNewPassword
          isOpen={isChoosePasswordOpen}
          isForgot={!!resetToken}
          onSubmit={handleChoosePassword}
          onClose={onCloseChoosePassword}
        />
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

export const useUserContextState = () => {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserContextProvider')
  }
  return context
}

export const useUserContextStateDispatch = () => {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserContextProvider')
  }
  return context
}
