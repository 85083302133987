import React, { useEffect, useState } from 'react'
import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderProps,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react'

interface Props {
  leftSideLabel: string
  rightSideLabel: string
  valueLabel?: string
  onChange: (value: number[]) => void
  value?: number[]
  sliderProps?: SliderProps
}
export const ComplexSlider: React.FC<Props> = ({
  leftSideLabel,
  rightSideLabel,
  value,
  valueLabel,
  sliderProps,
  onChange,
}) => {
  const [values, setValues] = useState([50, 50])

  const handleSliderChange = (value: number) => {
    const newValue = [value, (sliderProps?.max ?? 100) - value]
    setValues(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    if (value) {
      setValues(value)
    }
  }, [value])

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb="9px">
        <Box display="flex" flexDirection="column">
          <Text variant="small" color="gray.79" mb="8px">
            {leftSideLabel}
          </Text>
          <Text variant="h2" color="white">
            {values[0]}
            {valueLabel}
          </Text>
        </Box>
        <Box display="flex" flexDirection="column">
          <Text variant="small" color="gray.79" mb="8px">
            {rightSideLabel}
          </Text>
          <Text variant="h2" color="white">
            {values[1]}
            {valueLabel}
          </Text>
        </Box>
      </Box>
      <Box>
        <Slider
          onChange={handleSliderChange}
          step={5}
          min={0}
          max={100}
          value={values[0]}
          {...sliderProps}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Box>
    </Box>
  )
}
