import { Box, Button, Checkbox, Text } from '@chakra-ui/react'
import React, { ChangeEvent, FC } from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
// import { Budget, DataBox, RoundedBox } from '@deepwork/ui-components'
// import { IReviewCandidate } from '@deepwork/interfaces'
// import { pluralise } from '@deepwork/utils'
import { Budget, DataBox, RoundedBox } from 'libs/ui-components/src'
import { IReviewCandidate } from 'libs/interfaces/src'
import { pluralise } from 'libs/utils/src'
import { useProjectFeedbackDispatch, useProjectFeedbackState } from '../../ProjectFeedbackProvider'
import { sendTransaction } from '../../../../../services/metamask/provider'

interface Props {
  onSubmit: () => void
  onBack: () => void
  mySalary: number
  salaries?: IReviewCandidate[]
  isSubmitting?: boolean
  paymentSystemLink: string
  checkboxLabel: string
}
export const PaymentStep: FC<Props> = ({
  onBack,
  onSubmit,
  mySalary,
  salaries,
  isSubmitting,
  paymentSystemLink,
  checkboxLabel,
}) => {
  const { payment } = useProjectFeedbackState()
  const { onUpdatePaymentStep } = useProjectFeedbackDispatch()

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    onUpdatePaymentStep({ confirmation: event.target.checked })
  }

  const handlePayToParticipant = async (event: any) => { // eslint-disable-line
    const { wallet, payment } = event.target.dataset
    const txHash = await sendTransaction(wallet, payment)
    console.log('txHash!!', txHash)
  }

  return (
    <Box>
      <Box mb="25px">
        <Text variant="h2" color="white" mb="14px">
          Complete payment
        </Text>
      </Box>
      <RoundedBox mb="25px">
        <Box display="flex" alignItems="center">
          <DataBox label="Your payment" boxProps={{ mr: '32px' }}>
            <Budget budget={mySalary} currency="" />

            {/*            <Button
              mt="16px"
              // data-wallet={item.wallet}
              data-payment={mySalary}
              onClick={handlePayToParticipant}
              rightIcon={<ExternalLinkIcon />}
            >
              {'Pay to yourself'}
            </Button>
*/}
          </DataBox>
          {salaries?.map((item) => (
            <DataBox
              label={`${pluralise(`${item.username}'`, item.username.length)} payment`}
              boxProps={{ mr: '32px' }}
            >
              <Budget budget={item.payment} currency="" />

              {/*              <Button
                mt="16px"
                data-wallet={item.wallet}
                data-payment={item.payment}
                onClick={handlePayToParticipant}
                rightIcon={<ExternalLinkIcon />}
              >
                {`Pay to ${item.username}`}
              </Button>
*/}
            </DataBox>
          ))}
        </Box>
        <RoundedBox
          p="39px 0"
          bg="gray.24"
          mt="16px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text mb="16px" variant="h6">
            Opens in a new tab on the organisations payment system.
          </Text>
          <Button as="a" href={paymentSystemLink} target="_blank" rightIcon={<ExternalLinkIcon />}>
            Go to payment portal
          </Button>
        </RoundedBox>
      </RoundedBox>

      <RoundedBox>
        <Text color="white" variant="h4" mb="16px">
          Confirmation
        </Text>
        <Checkbox isChecked={payment.confirmation} onChange={handleChangeCheckbox}>
          <Text color="gray.79" variant="h6">
            {checkboxLabel}
          </Text>
        </Checkbox>
      </RoundedBox>

      <Box mt="43px">
        <Button variant="link" mr="40px" onClick={onBack}>
          Back
        </Button>
        <Button onClick={onSubmit} disabled={!payment.confirmation || isSubmitting}>
          Confirm
        </Button>
      </Box>
    </Box>
  )
}
